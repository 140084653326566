import { useEffect, useState } from 'react';

import { FilePresent, FileOpen, Download } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { DateField, Labeled, useDataProvider, useNotify } from 'react-admin';
import { Col } from 'react-grid-system';
import { InvestigationDocumentType, DownloadInvestigationDocumentResponse } from 'src/models';

import { isImageFile } from '../../../../utils/isImageFile';
import StatusField from '../../../Fields/StatusField';

export interface DocumentDetailsProps {
  documentId: string;
  accountId: number;
  documentName: string;
  state: string;
  fileName?: string;
  originalFileName?: string;
  takenOn?: string;
  uploadDateTime?: string;
  uploadUserName?: string;
  description?: string;
  investigation:
    | {
        id: number;
        type: 'investigation';
      }
    | { accountHolderId: number; type: 'application' };
  onView: (params: { url: string; type: InvestigationDocumentType }) => void;
}

export const DocumentDetails = ({
  documentName,
  fileName,
  originalFileName,
  description,
  state,
  documentId,
  accountId,
  onView,
  investigation,
  ...restDocumentDetails
}: DocumentDetailsProps) => {
  const [file, setFile] = useState<{ url: string; type: InvestigationDocumentType } | null>(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);
  const [isViewLoading, setIsViewLoading] = useState<boolean>(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const findDocumentType = (): InvestigationDocumentType => (isImageFile(originalFileName) ? 'image' : 'pdf');

  const openSaveFileDialog = (url: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = originalFileName ?? documentName; // Specify the desired filename
    downloadLink.style.display = 'none';

    // Append the link to the document and trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up by removing the link
    document.body.removeChild(downloadLink);
  };

  const downloadFile = async () => {
    return dataProvider.getOne<DownloadInvestigationDocumentResponse>(
      investigation.type === 'investigation' ? 'investigation-document' : 'application-document',
      {
        id: documentId,
        meta:
          investigation.type === 'investigation'
            ? { accountId, investigationId: investigation.id, type: findDocumentType() }
            : { accountId, accountHolderId: investigation.accountHolderId, type: findDocumentType() },
      },
    );
  };

  const onDownload = async () => {
    try {
      setIsDownloadLoading(true);

      if (!file) {
        const response = await downloadFile();
        setFile(response.data.file);
        openSaveFileDialog(response.data.file.url);

        return;
      }
      openSaveFileDialog(file.url);
    } catch (error) {
      notify(`Error downloading file. Received ${JSON.stringify(error)}`, {
        type: 'error',
      });
    } finally {
      setIsDownloadLoading(false);
    }
  };

  const _onView = async () => {
    try {
      setIsViewLoading(true);

      if (!file) {
        const response = await downloadFile();
        setFile(response.data.file);
        onView(response.data.file);

        return;
      }
      onView(file);
    } catch (error) {
      notify(`Error opening file. Received ${JSON.stringify(error)}`, {
        type: 'error',
      });
    } finally {
      setIsViewLoading(false);
    }
  };

  useEffect(() => {
    if (file) {
      URL.revokeObjectURL(file.url);
    }
  }, []);

  return (
    <>
      <Col md={1} className={'alignAllCenter'}>
        <FilePresent />
      </Col>

      <Col md={2} className={'alignVerticalCenter'}>
        <div>
          <span>{documentName}</span>
          <br />
          <span className='description'>{fileName}</span>
          <br />
          <span className='description'>({originalFileName})</span>
          <br />
        </div>
      </Col>

      <Col md={2} className={'alignVerticalCenter'}>
        {description && (
          <div>
            <span className='fieldLabel'>Description</span>
            <br />
            <span>{description}</span>
          </div>
        )}
      </Col>

      <Col md={1} className={'alignVerticalCenter'}>
        <Labeled label={'Taken on'}>
          <DateField record={restDocumentDetails} source='takenOn' />
        </Labeled>
      </Col>

      <Col md={1} className={'alignVerticalCenter'}>
        <Labeled label={'Uploaded at'}>
          <DateField record={restDocumentDetails} source='uploadedDateTime' />
        </Labeled>
      </Col>

      <Col md={2} className='alignVerticalCenter'>
        <StatusField status={state} success={'Submitted'} warning={'PendingReview'} fail={'Rejected'} />
      </Col>

      <Col md={2} className={'alignVerticalCenter'}>
        <IconButton onClick={onDownload} disabled={isDownloadLoading}>
          {isDownloadLoading ? <CircularProgress color='inherit' size='1em' /> : <Download />}
        </IconButton>
        <IconButton onClick={_onView} disabled={isViewLoading}>
          {isViewLoading ? <CircularProgress color='inherit' size='1em' /> : <FileOpen />}
        </IconButton>
      </Col>
    </>
  );
};
