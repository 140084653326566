import {
  TradingBlockApplicationCipCategoryDto,
  TradingBlockApplicationDto,
  TradingBlockDocumentNameDto,
  TradingBlockInvestigationCipCategoryDto,
  TradingBlockInvestigationDto,
  TradingBlockInvestigationStateDto,
} from 'src/dtos';

export type InvestigationDocumentType = 'image' | 'pdf';

export interface DownloadInvestigationDocumentResponse {
  id: string;
  file: {
    url: string;
    type: InvestigationDocumentType;
  };
}

export enum InvestigationStatusLabel {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Rejected = 'REJECTED',
}

export enum InvestigationStatusValue {
  Pending = 'Pending',
  Active = 'Active',
  Rejected = 'Rejected',
}

export class InvestigationStatus {
  _value: InvestigationStatusValue;
  _label: InvestigationStatusLabel;
  _tradingBlock: TradingBlockInvestigationStateDto;

  constructor(state: TradingBlockInvestigationStateDto) {
    this._value = this.findValue(state);
    this._label = this.findLabel();
    this._tradingBlock = state;
  }

  private findValue(state: TradingBlockInvestigationStateDto): InvestigationStatusValue {
    switch (state) {
      case TradingBlockInvestigationStateDto.Complete:
        return InvestigationStatusValue.Active;
      case TradingBlockInvestigationStateDto.Rejected:
        return InvestigationStatusValue.Rejected;
      default:
        return InvestigationStatusValue.Pending;
    }
  }

  private findLabel(): InvestigationStatusLabel {
    switch (this._value) {
      case InvestigationStatusValue.Active:
        return InvestigationStatusLabel.Active;
      case InvestigationStatusValue.Rejected:
        return InvestigationStatusLabel.Rejected;
      default:
        return InvestigationStatusLabel.Pending;
    }
  }

  get label(): InvestigationStatusLabel {
    return this._label;
  }

  get value(): InvestigationStatusValue {
    return this._value;
  }

  get isActive(): boolean {
    return this._value === InvestigationStatusValue.Active;
  }

  get isRejected(): boolean {
    return this._value === InvestigationStatusValue.Rejected;
  }

  get isPending(): boolean {
    return this._value === InvestigationStatusValue.Pending;
  }

  get tradingBlock(): TradingBlockInvestigationStateDto {
    return this._tradingBlock;
  }
}

export enum InvestigationDocumentLabel {
  DriversLicense = "Driver's License",
  StateIdCard = 'State ID Card',
  Passport = 'Passport ID',
  MilitaryId = 'Military ID',
  SsnCard = 'SSN Card',
  SsaLetter = 'SSA Letter',
  IrsItinLetter = 'IRS ITIN Letter',
  OtherGovernmentId = 'Other Government ID',
  CddDocument = 'CDD Document',
  AllPassingCipResults = 'All Passing CIP Results',
  IrsW9Form = 'IRS W9 Form',
  SignatureImage = 'Signature Image',
  IdDocument = 'ID Document',
  AffiliateApproval = 'Affiliate Approval',
  Document = 'Document',
  Other = 'Other',
  GovermentId = 'Government ID',
}

export class InvestigationDocumentName {
  _value: TradingBlockDocumentNameDto;
  _label: InvestigationDocumentLabel;

  constructor(state: TradingBlockDocumentNameDto) {
    this._value = state;
    this._label = this.findLabel();
  }

  private findLabel(): InvestigationDocumentLabel {
    switch (this._value) {
      case TradingBlockDocumentNameDto.DriversLicense:
        return InvestigationDocumentLabel.DriversLicense;
      case TradingBlockDocumentNameDto.StateIdCard:
        return InvestigationDocumentLabel.StateIdCard;
      case TradingBlockDocumentNameDto.Passport:
        return InvestigationDocumentLabel.Passport;
      case TradingBlockDocumentNameDto.MilitaryId:
        return InvestigationDocumentLabel.MilitaryId;
      case TradingBlockDocumentNameDto.SsnCard:
        return InvestigationDocumentLabel.SsnCard;
      case TradingBlockDocumentNameDto.SsaLetter:
        return InvestigationDocumentLabel.SsaLetter;
      case TradingBlockDocumentNameDto.IrsItinLetter:
        return InvestigationDocumentLabel.IrsItinLetter;
      case TradingBlockDocumentNameDto.OtherGovernmentId:
        return InvestigationDocumentLabel.OtherGovernmentId;
      case TradingBlockDocumentNameDto.CddDocument:
        return InvestigationDocumentLabel.CddDocument;
      case TradingBlockDocumentNameDto.AllPassingCipResults:
        return InvestigationDocumentLabel.AllPassingCipResults;
      case TradingBlockDocumentNameDto.IrsW9Form:
        return InvestigationDocumentLabel.IrsW9Form;
      case TradingBlockDocumentNameDto.SignatureImage:
        return InvestigationDocumentLabel.SignatureImage;
      case TradingBlockDocumentNameDto.IdDocument:
        return InvestigationDocumentLabel.IdDocument;
      case TradingBlockDocumentNameDto.AffiliateApproval:
        return InvestigationDocumentLabel.AffiliateApproval;
      case TradingBlockDocumentNameDto.Document:
        return InvestigationDocumentLabel.Document;
      default:
        return InvestigationDocumentLabel.Other;
    }
  }

  get value(): TradingBlockDocumentNameDto {
    return this._value;
  }

  get label(): InvestigationDocumentLabel {
    return this._label;
  }
}

export interface TradingBlockApplicationCipCategory
  extends Omit<TradingBlockApplicationCipCategoryDto, 'requestedDocuments'> {
  requestedDocuments?: InvestigationDocumentName[];
}

export interface TradingBlockApplication extends Omit<TradingBlockApplicationDto, 'appCategories'> {
  appCategories: Array<TradingBlockApplicationCipCategory>;
}

export enum InvestigationCategoryName {
  NameOrDateOfBirth = 'NameOrDateOfBirth',
  TaxId = 'TaxId',
  Address = 'Address',
}

export interface TradingBlockInvestigationCipCategory
  extends Omit<TradingBlockInvestigationCipCategoryDto, 'requestedDocuments'> {
  requestedDocuments?: InvestigationDocumentName[];
}

export interface TradingBlockInvestigation extends Omit<TradingBlockInvestigationDto, 'cipCategories'> {
  cipCategories: Array<TradingBlockInvestigationCipCategory>;
}

export interface GetInvestigationListResponse {
  id: string;
  applications?: TradingBlockApplication[];
  investigations?: TradingBlockInvestigation[];
  status: InvestigationStatus;
}
