import React, { useState } from 'react';

import { MoreVert } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { Button, Labeled, useGetList, useRecordContext, TextField } from 'react-admin';
import { Row, Col } from 'react-grid-system';
import { TradingBlockInvestigationUploadedDocumentDto, TradingBlockApplicationUploadedDocumentDto } from 'src/dtos';
import { StatusTag } from 'src/lib';
import { Account, GetInvestigationListResponse, InvestigationDocumentType } from 'src/models';
import { getInvestigationStatusTagPalette } from 'src/utils';

import Styles from '../../../constants/styles';
import { FileViewerModal } from '../../../lib/FileViewerModal/FileViewerModal';
import { CipCategoryList } from './components/CipCategoryList';
import ContinueApplication from './components/continueApplication';
import DecideActions from './components/decideActions';
import DocumentActionsDropdown from './components/DocumentActionsDropdown';
import { DocumentDetails } from './components/DocumentDetails';

export const InvestigationShow = () => {
  const { accountId, type, primaryAccountHolder } = useRecordContext<Account>();
  const [viewDocument, setViewDocument] = useState<{ url: string; type: InvestigationDocumentType } | null>(null);
  const [curDocument, setCurDocument] = useState<
    (TradingBlockInvestigationUploadedDocumentDto | TradingBlockApplicationUploadedDocumentDto) & {
      investigationType: 'application' | 'investigation';
    }
  >();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data, isLoading, error } = useGetList<GetInvestigationListResponse>('account-investigations', {
    filter: { accountId },
  });

  const isPrimary = ({ firstName, lastName }: { firstName?: string; lastName?: string }) => {
    return primaryAccountHolder?.firstName === firstName && primaryAccountHolder?.lastName === lastName;
  };

  const handleClickListBtn = (
    event: React.MouseEvent<HTMLElement>,
    document: (TradingBlockInvestigationUploadedDocumentDto | TradingBlockApplicationUploadedDocumentDto) & {
      investigationType: 'application' | 'investigation';
    },
  ) => {
    setAnchorEl(event.currentTarget);
    setCurDocument(document);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFileViewerModalClose = () => {
    setViewDocument(null);
  };

  const onApplicationDocumentView = (document: { url: string; type: InvestigationDocumentType }) => {
    setViewDocument(document);
  };

  const onInvestigationDocumentView = (document: { url: string; type: InvestigationDocumentType }) => {
    setViewDocument(document);
  };

  if (isLoading)
    return (
      <div className='alignAllCenter'>
        <CircularProgress />
      </div>
    );

  if (error) return <div>An error occurred. Received {JSON.stringify(error)} </div>;

  if (!accountId) return <div> No account id was found</div>;

  return (
    <div className='showContent'>
      <Row>
        <Col sm={6}>
          <h2 className='title'>Investigation</h2>
        </Col>
      </Row>
      {data?.[0]?.status && (
        <Row>
          <Col md={6}>
            <Labeled label='Account Status'>
              <StatusTag value={data[0].status.value} style={getInvestigationStatusTagPalette(data[0].status.label)} />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled>
              <TextField record={data[0]} source='status.tradingBlock' label='Trading Block Status' />
            </Labeled>
          </Col>
        </Row>
      )}
      <>
        {data?.map((investigationData: GetInvestigationListResponse) => {
          return investigationData.investigations?.map(investigation => (
            <div key={'investigation' + investigation.investigationId}>
              <div className='investigationBox'>
                <Row>
                  <Col md={6}>
                    <h3 className='subHeader1'>
                      {type.isJoint
                        ? isPrimary({ firstName: investigation.firstName, lastName: investigation.lastName })
                          ? `Primary Account Holder (${investigation.firstName} ${investigation.lastName})`
                          : `Joint Account Holder (${investigation.firstName} ${investigation.lastName})`
                        : `${investigation.firstName} ${investigation.lastName}`}
                    </h3>
                  </Col>
                  <Col md={6}>{!investigationData.status.isActive && <DecideActions accountId={accountId} />}</Col>
                </Row>
                <h3 className='subHeader2'>Admin Note</h3>
                <Col md={6} style={{ paddingTop: 0 }}>
                  <div style={{ padding: 0 }}>
                    <ul style={{ padding: 0, margin: 0, listStylePosition: 'inside' }}>
                      <li>{investigation.adminNote ?? 'NA'}</li>
                    </ul>
                  </div>
                </Col>
                <h3 className='subHeader2'>Admin Notes</h3>
                <Col md={6} style={{ paddingTop: 0 }}>
                  <div style={{ padding: 0 }}>
                    <ul style={{ padding: 0, margin: 0, listStylePosition: 'inside' }}>
                      {investigation.adminNotes?.map(anAdminNote => {
                        return (
                          <li key={anAdminNote.id}>
                            {anAdminNote.text} (created on: {anAdminNote.createdDate}, created by:{' '}
                            {anAdminNote.createdBy})
                          </li>
                        );
                      }) ?? <li>NA</li>}
                    </ul>
                  </div>
                </Col>

                {investigation.cipCategories && (
                  <>
                    <h3 className='subHeader2'> CIP Categories </h3>
                    <CipCategoryList categories={investigation.cipCategories} />
                  </>
                )}
                {investigation.uploadedDocuments && (
                  <>
                    <h3 className='subHeader2'> Documents </h3>
                    {investigation.uploadedDocuments.map(document => (
                      //NOTE: we have used this way here because MaterialUi wont accept having popovers inside of children
                      <Row key={document.documentName} style={{ margin: 20 }} className='hoverItem'>
                        <DocumentDetails
                          {...document}
                          accountId={accountId}
                          investigation={{ type: 'investigation', id: investigation.investigationId }}
                          onView={onInvestigationDocumentView}
                        />
                        <Button
                          aria-haspopup='listbox'
                          aria-controls='lock-menu'
                          onClick={e => {
                            handleClickListBtn(e, { ...document, investigationType: 'investigation' });
                          }}
                          style={Styles.investigation.moreButton}>
                          <MoreVert fontSize='small' />
                        </Button>
                      </Row>
                    ))}
                  </>
                )}
              </div>
            </div>
          ));
        })}
        <br />
        {data?.map((investigationData: GetInvestigationListResponse) => {
          return investigationData.applications?.map(application => (
            <div key={'application' + application.accountHolderId}>
              <div className='investigationBox'>
                <Row>
                  <Col md={6}>
                    <h3>
                      {type.isJoint
                        ? isPrimary({ firstName: application.firstName, lastName: application.lastName })
                          ? `Primary Account Holder (${application.firstName} ${application.lastName})`
                          : `Joint Account Holder (${application.firstName} ${application.lastName})`
                        : `${application.firstName} ${application.lastName}`}
                    </h3>
                  </Col>
                  <Col md={6}>
                    <ContinueApplication accountId={accountId} />
                  </Col>
                </Row>

                {application.appCategories && (
                  <>
                    <h3 className='subHeader2'>APP Categories </h3>
                    <CipCategoryList categories={application.appCategories} />
                  </>
                )}
                {application.uploadedDocuments && (
                  <>
                    <h3 className='subHeader2'> Documents </h3>
                    {application.uploadedDocuments.map(document => (
                      //NOTE: we have used this way here because MaterialUi wont accept having popovers inside of children
                      <Row key={document.miscDocumentId} style={{ margin: 20 }} className='hoverItem'>
                        <DocumentDetails
                          {...document}
                          accountId={accountId}
                          investigation={{ type: 'application', accountHolderId: application.accountHolderId }}
                          onView={onApplicationDocumentView}
                        />
                        <Button
                          aria-haspopup='listbox'
                          aria-controls='lock-menu'
                          onClick={e => {
                            handleClickListBtn(e, { ...document, investigationType: 'application' });
                          }}
                          style={Styles.investigation.moreButton}>
                          <MoreVert fontSize='small' />
                        </Button>
                      </Row>
                    ))}
                  </>
                )}
              </div>
            </div>
          ));
        })}
      </>
      {curDocument && (
        <DocumentActionsDropdown
          accountId={accountId}
          element={curDocument}
          onClose={handleClose}
          anchorElement={anchorEl}
        />
      )}
      {viewDocument && <FileViewerModal onClose={onFileViewerModalClose} file={viewDocument} />}
    </div>
  );
};
