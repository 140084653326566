import { CSSProperties } from 'react';

export const statusTagStyle: CSSProperties = {
  padding: 4,
  display: 'flex',
  justifyContent: 'center',
  borderRadius: 4,
  fontWeight: 600,
  textAlign: 'center',
  width: '100%',
};

export const StatusTag = ({ value, style }: { value: string; style: { background: string; color: string } }) => (
  <div style={{ ...statusTagStyle, backgroundColor: style.background, color: style.color }}>{value}</div>
);
