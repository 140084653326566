import { AccountBalanceDto } from '../dtos/accountBalances.dtos';
import {
  OfferOrderCardPaymentDetailsDto,
  OfferOrderCardPaymentDto,
  OfferOrderRefundDto,
  OfferOrderDetailsDto,
  OfferOrderDto,
  OfferOrderSecurityDeliveryOptionDto,
} from '../dtos/offerOrders.dtos';
import {
  OfferOrder,
  OfferOrderCardPayment,
  OfferOrderCardPaymentDetails,
  OfferOrderCardPaymentIntentStatus,
  OfferOrderRefund,
  OfferOrderRefundReason,
  OfferOrderRefundStatus,
  OfferOrderDetails,
  OfferOrderPaymentType,
  OfferOrderStatus,
  OfferOrderPaymentStatus,
  OfferOrderRetirementAccountFundingType,
} from '../models/offerOrders.models';
import { findCashAvailable } from './accountBalances.mappers';

export const replaceSecurityDeliveryOptionTooltipIfFound = (
  securityDeliveryOption?: OfferOrderSecurityDeliveryOptionDto,
): string => {
  if (!securityDeliveryOption) return '';
  if (!securityDeliveryOption.tooltip) return securityDeliveryOption.label;

  let result = securityDeliveryOption.label;
  result = result.replace(/<\/?tooltip>/g, '');

  return result;
};

export const mapOfferOrderRefundDtoListToModel = (list: OfferOrderRefundDto[]): OfferOrderRefund[] =>
  list.map(aDto => ({
    id: aDto.id,
    amount: aDto.amount,
    mechanism: aDto.mechanism,
    externalId: aDto.externalId,
    reason: new OfferOrderRefundReason(aDto.reason),
    status: new OfferOrderRefundStatus(aDto.status),
    description: aDto.description,
    failureReason: aDto.failureReason,
    bankName: aDto.bankName,
    adminNote: aDto.adminNote,
    createdAt: aDto.createdAt,
    updatedAt: aDto.updatedAt,
  }));

export const mapOfferOrderCardPaymentDtoToModel = (dto: OfferOrderCardPaymentDto): OfferOrderCardPayment => ({
  ...dto,
  intentStatus: new OfferOrderCardPaymentIntentStatus(dto.intentStatus),
});

export const mapOfferOrderCardPaymentListDtoToModel = (dtoList: OfferOrderCardPaymentDto[]): OfferOrderCardPayment[] =>
  dtoList.map(mapOfferOrderCardPaymentDtoToModel);

export const mapOfferOrderCardPaymentDetailsDtoToModel = (
  dto: OfferOrderCardPaymentDetailsDto,
): OfferOrderCardPaymentDetails => mapOfferOrderCardPaymentDtoToModel(dto);

export const mapOfferOrderDtoToModel = (dto: OfferOrderDto, balance?: AccountBalanceDto): OfferOrder => {
  const { cardPayment, ...baseDto } = dto;

  const result: OfferOrder = {
    ...baseDto,
    paymentType: new OfferOrderPaymentType(dto.paymentType),
    paymentStatus: new OfferOrderPaymentStatus(dto),
    status: new OfferOrderStatus(dto.status),
    cashBalance: balance?.cashBalance,
    cashAvailable: balance ? findCashAvailable(balance) : undefined,
    refunds: mapOfferOrderRefundDtoListToModel(dto.refunds),
    retirementAccountFundingType: dto.retirementAccountFundingType
      ? new OfferOrderRetirementAccountFundingType(dto.retirementAccountFundingType)
      : undefined,
  };

  if (cardPayment) {
    result.cardPayment = mapOfferOrderCardPaymentDtoToModel(cardPayment);
  }

  return result;
};

export const mapOfferOrderDetailsDtoToModel = (dto: OfferOrderDetailsDto): OfferOrderDetails => {
  const result: OfferOrderDetails = {
    ...mapOfferOrderDtoToModel(dto),
    paymentInstructions: dto.paymentInstructions,
  };

  return result;
};
