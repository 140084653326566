import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { NumberInput, BooleanInput, required, useRecordContext, useDataProvider, useNotify } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { DataProviderResource } from 'src/constants';
import { OfferCategoryNameDto } from 'src/dtos';
import { OfferOrderAggregate } from 'src/models';
import { getCurrencyFormatter } from 'src/utils';

import { GridBreak } from '../../../../lib/GridBreak/GridBreak';

interface PriceCreateEditProps {
  isEditMode?: boolean;
  categoryName?: string;
}

export const PriceCreateEdit = ({ isEditMode = false, categoryName }: PriceCreateEditProps) => {
  const [offerOrderAggregate, setOfferOrderAggregate] = useState<OfferOrderAggregate>();

  const record = useRecordContext();
  const { setValue, register } = useFormContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    setValue('_categoryName', categoryName);
  }, [categoryName]);

  useEffect(() => {
    if (isEditMode) {
      dataProvider
        .getOne<OfferOrderAggregate>(DataProviderResource.AggregateOfferOrders, {
          id: record?.id?.toString(),
        })
        .then(({ data }) => {
          setOfferOrderAggregate(data);
        })
        .catch(error => {
          notify(error.message, {
            type: 'error',
            autoHideDuration: 7500,
          });
        });
    }
  }, [isEditMode]);

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <h2>{isEditMode ? 'Edit' : 'Add'} Price & Limits Information </h2>
        {isEditMode ? (
          <h4>(Funds Indicated {getCurrencyFormatter().format(offerOrderAggregate?.indicatedFunds ?? 0)}) </h4>
        ) : (
          ''
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Minimum Raise Amount'
          source='minTotalRaiseCurrencyAmt'
          validate={[required()]}
          fullWidth
          min='0'
          max='999999999'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Maximum Raise Amount'
          source='maxTotalRaiseCurrencyAmt'
          validate={[required()]}
          fullWidth
          min='0'
          max='9999999900'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Minimum Investment Currency'
          source='minimumInvestCurrencyAmt'
          validate={[required()]}
          fullWidth
          min='0'
          max='999999999'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Maximum Investment Currency'
          source='maximumInvestCurrencyAmt'
          fullWidth
          min='0'
          max='999999999'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Minimum Investment Increment'
          source='minimumInvestIncCurrencyAmt'
          validate={[required()]}
          fullWidth
          min='0'
          max='999999999'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Minimum Investment Share Increment Qty'
          source='minimumInvestShareIncrQty'
          fullWidth
          validate={required()}
          min='0'
          max='999999999'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Minimum Invest Share Qty'
          source='minimumInvestShareQty'
          fullWidth
          validate={required()}
          min='0'
          max='999999999'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Maximum Invest Share Qty'
          source='maximumInvestShareQty'
          min='0'
          max='999999999'
          fullWidth
          validate={required()}
        />
      </Grid>
      {categoryName !== OfferCategoryNameDto.Conditional && (
        <Grid item xs={12} sm={6}>
          <NumberInput
            label='Price Per Share'
            source='pricePerShare'
            validate={[required()]}
            fullWidth
            min='0'
            max='999999999'
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <BooleanInput
          label='Allow restricted persons'
          source='allowRestrictedPersons'
          validate={[required()]}
          fullWidth
        />
      </Grid>
      {categoryName === OfferCategoryNameDto.Conditional && (
        <>
          <GridBreak />
          <Grid item xs={12}>
            <h3>Conditional Offer Details</h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              label='Price Per Share'
              source='pricePerShare'
              validate={[required()]}
              fullWidth
              min='0'
              max='999999999'
            />
          </Grid>
          <GridBreak />
          <Grid item xs={12} sm={6}>
            <NumberInput
              label='Min Share Price'
              source='minSharePrice'
              min='0.01'
              max='999999999'
              fullWidth
              validate={required()}
            />
          </Grid>
          <GridBreak />
          <Grid item xs={12} sm={6}>
            <NumberInput
              label='Max Share Price'
              source='maxSharePrice'
              validate={[required()]}
              fullWidth
              min='0.01'
              max='999999999'
            />
          </Grid>
          {isEditMode && <input hidden {...register('_categoryName')} />}
        </>
      )}
    </Grid>
  );
};
export default PriceCreateEdit;
