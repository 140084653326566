import { DeleteParams, GetListParams, GetOneParams, GetOneResult, UpdateParams, UpdateResult } from 'react-admin';
import { getClientApi } from 'src/data-communication';
import { mapGetInvestigationListResponseDtoToModel } from 'src/mappers';

const clientApi = getClientApi();

export const getInvestigationList = async (params: GetListParams) => {
  const { filter } = params;
  const accountId = filter.accountId;

  const data = await clientApi.investigations.list({
    params: {
      accountId,
    },
  });

  return {
    data: [{ ...mapGetInvestigationListResponseDtoToModel(data), id: accountId }],
    total: 1,
  };
};

export const deleteInvestigationDocument = async (params: DeleteParams): Promise<UpdateResult<{ id: string }>> => {
  const { id } = params;
  const { miscDocumentId } = params.meta;

  await clientApi.investigations.deleteDocument({
    params: {
      accountId: id,
      miscDocumentId,
    },
  });

  return {
    data: { id: id.toString() },
  };
};

export const submitInvestigationDocument = async (params: UpdateParams) => {
  const { id, data } = params;
  const { accountId } = data;

  await clientApi.investigations.submitDocument({
    params: {
      accountId,
      miscDocumentId: Number(id),
    },
  });

  return {
    data: { id: id.toString() },
  };
};

export const appealInvestigation = async (params: UpdateParams) => {
  const { data } = params;
  const { accountId } = data;

  await clientApi.investigations.appeal({
    params: {
      accountId,
    },
  });

  return {
    data: { id: accountId },
  };
};

export const rejectInvestigation = async (params: UpdateParams) => {
  const { data } = params;
  const { accountId } = data;

  await clientApi.investigations.reject({
    params: {
      accountId,
    },
  });

  return {
    data: { id: accountId },
  };
};

export const downloadInvestigationDocument = async (
  params: GetOneParams,
): Promise<GetOneResult<{ id: string; file: { url: string; type: string } }>> => {
  const { id, meta } = params;
  const { accountId, investigationId, type } = meta;

  const response = await clientApi.investigations.downloadDocument({
    params: {
      accountId,
      investigationId,
      documentId: id,
    },
  });

  const fileUrl = URL.createObjectURL(response);

  return {
    data: { id, file: { url: fileUrl, type } },
  };
};

export const deleteApplicationDocument = async (params: DeleteParams) => {
  const { id } = params;
  const { miscDocumentId } = params.meta;

  await clientApi.investigations.deleteApplicationDocument({
    params: {
      accountId: id,
      miscDocumentId,
    },
  });

  return {
    data: { id: id.toString() },
  };
};

export const submitApplicationDocument = async (params: UpdateParams) => {
  const { id, data } = params;
  const { accountId } = data;

  await clientApi.investigations.submitApplicationDocument({
    params: {
      accountId,
      miscDocumentId: Number(id),
    },
  });

  return {
    data: { id: id.toString() },
  };
};

export const continueApplication = async (params: UpdateParams) => {
  const { accountId } = params.data;

  await clientApi.investigations.continueApplication({
    params: {
      accountId,
    },
  });

  return {
    data: { id: accountId },
  };
};

export const downloadApplicationDocument = async (
  params: GetOneParams,
): Promise<GetOneResult<{ id: string; file: { url: string; type: string } }>> => {
  const { id, meta } = params;
  const { accountId, accountHolderId, type } = meta;

  const response = await clientApi.investigations.downloadApplicationDocument({
    params: {
      accountId,
      accountHolderId,
      documentId: id,
    },
  });

  const fileUrl = URL.createObjectURL(response);

  return {
    data: { id, file: { url: fileUrl, type } },
  };
};
