import { Grid } from '@mui/material';
import { DateField, FunctionField, Show, SimpleShowLayout, TextField, useShowController } from 'react-admin';
import { StatusTag } from 'src/lib';
import { FileExport } from 'src/models';
import { getFileExportStatusTagPalette } from 'src/utils';

import { FileExportActions } from './FileExportActions';

export const FileExportShow = () => {
  const { record } = useShowController<FileExport>();

  return (
    <Show emptyWhileLoading title={'File Export'} actions={<FileExportActions />}>
      <Grid container direction='row' spacing={2}>
        <Grid item md={12}>
          <SimpleShowLayout>
            <h2>File Export</h2>
          </SimpleShowLayout>
        </Grid>
        <Grid item md={6}>
          <SimpleShowLayout>
            <TextField source='id' label='Id' fullWidth />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={6}>
          <SimpleShowLayout>
            <TextField source='name' label='File Name' fullWidth />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={6}>
          <SimpleShowLayout>
            <FunctionField
              label='Type'
              render={(record?: FileExport) => (record ? record.type.label : null)}
              sortable={false}
            />
          </SimpleShowLayout>
        </Grid>
        {record?.type?.isOfferOrder && record?.offer && (
          <Grid item md={6}>
            <SimpleShowLayout>
              <TextField source='offer.name' label='Offer Name' fullWidth />
            </SimpleShowLayout>
          </Grid>
        )}
        {record?.externalId && (
          <Grid item md={6}>
            <SimpleShowLayout>
              <TextField source='externalId' label='External Id' fullWidth />
            </SimpleShowLayout>
          </Grid>
        )}
        <Grid item md={12}>
          <Grid container direction='row' spacing={2}>
            <Grid item md={2}>
              <SimpleShowLayout>
                <FunctionField
                  label='Status'
                  render={(record?: FileExport) =>
                    record ? (
                      <StatusTag
                        value={record.status.label}
                        style={getFileExportStatusTagPalette(record.status.value)}
                      />
                    ) : null
                  }
                  sortable={false}
                />
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6}>
          <SimpleShowLayout>
            <DateField source='createdAt' label='Created at' showTime sortable={false} />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={12}>
          <SimpleShowLayout>
            <FunctionField
              label='Error'
              render={(record?: FileExport) => {
                if (!record) {
                  return null;
                }

                if (!record.error) {
                  return 'NA';
                }

                return record.error;
              }}
            />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </Show>
  );
};
