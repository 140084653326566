import { Labeled, TextField, useRecordContext } from 'react-admin';
import { Row, Col } from 'react-grid-system';
import { AccountHolderEmploymentStatusDto } from 'src/dtos';
import { Account } from 'src/models';

export const FinancialInformationShow = () => {
  const record = useRecordContext<Account>();

  const primaryEmployerStatus = record.primaryAccountHolder?.financialInformation?.employmentStatus;
  const secondaryEmployerStatus = record.secondaryAccountHolder?.financialInformation?.employmentStatus;

  return (
    <>
      <div className='showContent'>
        <Row>
          <Col sm={6}>
            <h2 className='title'>Financial Assets</h2>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.financialInformation.annualIncome.label' label='Annual Income' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled>
              <TextField
                source='primaryAccountHolder.financialInformation.totalNetWorth.label'
                label='Total Net Worth'
              />
            </Labeled>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Labeled>
              <TextField
                source='primaryAccountHolder.financialInformation.liquidNetWorth.label'
                label='Liquide Net Worth'
              />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.financialInformation.taxBracket.label' label='Tax Bracket' />
            </Labeled>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <h2 className='title'>{record.type.isJoint ? 'Primary Account Holder' : 'Financial Employment'}</h2>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled>
              <TextField
                source='primaryAccountHolder.financialInformation.employmentStatus.label'
                label='Employment Status'
              />
            </Labeled>
          </Col>
          {primaryEmployerStatus?.value === AccountHolderEmploymentStatusDto.Employed && (
            <Col md={6}>
              <Labeled>
                <TextField source='primaryAccountHolder.financialInformation.jobTitle' label='Job Title' />
              </Labeled>
            </Col>
          )}
        </Row>

        {primaryEmployerStatus?.value === AccountHolderEmploymentStatusDto.Employed && (
          <>
            <Row>
              <Col md={6}>
                <Labeled>
                  <TextField source='primaryAccountHolder.financialInformation.employerName' label='Employer Name' />
                </Labeled>
              </Col>
              <Col md={6}>
                <Labeled>
                  <TextField
                    source='primaryAccountHolder.financialInformation.yearsEmployed.label'
                    label='Years Employed'
                  />
                </Labeled>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <h4>Employer Address </h4>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Labeled>
                  <TextField
                    source='primaryAccountHolder.financialInformation.employerAddress.address1'
                    label='Street Address (Line 1)'
                  />
                </Labeled>
              </Col>
              <Col md={6}>
                <Labeled>
                  <TextField
                    source='primaryAccountHolder.financialInformation.employerAddress.address2'
                    label='Street Address (Line 2)'
                  />
                </Labeled>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Labeled>
                  <TextField source='primaryAccountHolder.financialInformation.employerAddress.city' label='City' />
                </Labeled>
              </Col>
              <Col md={6}>
                <Labeled>
                  <TextField
                    source='primaryAccountHolder.financialInformation.employerAddress.state.label'
                    label='State'
                  />
                </Labeled>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Labeled>
                  <TextField
                    source='primaryAccountHolder.financialInformation.employerAddress.country.label'
                    label='Country'
                  />
                </Labeled>
              </Col>
              <Col md={6}>
                <Labeled>
                  <TextField
                    source='primaryAccountHolder.financialInformation.employerAddress.postalCode'
                    label='Postal Code'
                  />
                </Labeled>
              </Col>
            </Row>
          </>
        )}
      </div>

      {record.type.isJoint && (
        <div className='showContent'>
          <Row>
            <Col md={12}>
              <h2 className='title'>Joint Account Holder</h2>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled>
                <TextField
                  source='secondaryAccountHolder.financialInformation.employmentStatus.label'
                  label='Employment Status'
                />
              </Labeled>
            </Col>
            {secondaryEmployerStatus?.value === AccountHolderEmploymentStatusDto.Employed && (
              <Col md={6}>
                <Labeled>
                  <TextField source='secondaryAccountHolder.financialInformation.jobTitle' label='Job Title' />
                </Labeled>
              </Col>
            )}
          </Row>

          {secondaryEmployerStatus?.value === AccountHolderEmploymentStatusDto.Employed && (
            <>
              <Row>
                <Col md={6}>
                  <Labeled>
                    <TextField
                      source='secondaryAccountHolder.financialInformation.employerName'
                      label='Employer Name'
                    />
                  </Labeled>
                </Col>
                <Col md={6}>
                  <Labeled>
                    <TextField
                      source='secondaryAccountHolder.financialInformation.yearsEmployed.label'
                      label='Years Employed'
                    />
                  </Labeled>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <h4>Employer Address </h4>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Labeled>
                    <TextField
                      source='secondaryAccountHolder.financialInformation.employerAddress.address1'
                      label='Street Address (Line 1)'
                    />
                  </Labeled>
                </Col>
                <Col md={6}>
                  <Labeled>
                    <TextField
                      source='secondaryAccountHolder.financialInformation.employerAddress.address2'
                      label='Street Address (Line 2)'
                    />
                  </Labeled>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Labeled>
                    <TextField source='secondaryAccountHolder.financialInformation.employerAddress.city' label='City' />
                  </Labeled>
                </Col>
                <Col md={6}>
                  <Labeled>
                    <TextField
                      source='secondaryAccountHolder.financialInformation.employerAddress.state.label'
                      label='State'
                    />
                  </Labeled>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Labeled>
                    <TextField
                      source='secondaryAccountHolder.financialInformation.employerAddress.country.label'
                      label='Country'
                    />
                  </Labeled>
                </Col>
                <Col md={6}>
                  <Labeled>
                    <TextField
                      source='secondaryAccountHolder.financialInformation.employerAddress.postalCode'
                      label='Postal Code'
                    />
                  </Labeled>
                </Col>
              </Row>
            </>
          )}
        </div>
      )}
    </>
  );
};
