import { useState } from 'react';

import FileDownloadIcon from '@mui/icons-material/GetApp';
import { Button, TopToolbar } from 'react-admin';

import { OfferOrderFileExportModal } from './components/OfferOrderFileExportModal';
import { modelFields } from './constants';

const OfferOrdersListActions = () => {
  const [isExportDialogOpen, setIsExportDialogOpen] = useState<boolean>(false);

  return (
    <TopToolbar>
      <Button label='Export' onClick={() => setIsExportDialogOpen(true)}>
        <FileDownloadIcon />
      </Button>
      <OfferOrderFileExportModal
        isOpen={isExportDialogOpen}
        onClose={() => setIsExportDialogOpen(false)}
        fields={modelFields}
      />
    </TopToolbar>
  );
};

export default OfferOrdersListActions;
