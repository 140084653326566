import { ListButton, TopToolbar, useShowController } from 'react-admin';
import { FileExport } from 'src/models';

import { CancelFileExportButton } from './CancelFileExportButton';
import { DeleteFileExportButton } from './DeleteFileExportButton';
import { DownloadFileExportButton } from './DownloadFileExportButton';

export const FileExportActions = () => {
  const { record } = useShowController<FileExport>();

  return (
    <TopToolbar>
      {record && <DownloadFileExportButton record={record} />}
      {record && <CancelFileExportButton record={record} />}
      {record && <DeleteFileExportButton record={record} />}
      <ListButton />
    </TopToolbar>
  );
};
