import { isEmpty } from 'lodash';
import {
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateManyParams,
  UpdateManyResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { getClientApi } from 'src/data-communication';
import {
  CreateOfferOrderRefundBodyDto,
  UpdateOfferOrderBodyDto,
  UpdateOfferOrderListStatusBodyDto,
  GetAccountBalanceListResponseDto,
  CreateOfferOrderFileExportBodyDto,
  FileExportDto,
} from 'src/dtos';
import {
  mapOfferOrderCardPaymentDetailsDtoToModel,
  mapOfferOrderCardPaymentListDtoToModel,
  mapOfferOrderDetailsDtoToModel,
  mapOfferOrderDtoToModel,
} from 'src/mappers';
import { OfferOrderCardPayment, OfferOrderCardPaymentDetails, OfferOrderDetails } from 'src/models';

import { Logger } from '../utils/Logger';

const clientApi = getClientApi();

export const getOfferOrderList = async (params: GetListParams) => {
  const { data, total } = await clientApi.offerOrders.list({
    queryParams: {
      skip: params.pagination.perPage * (params.pagination.page - 1),
      take: params.pagination.perPage,
      sortBy: params.sort.field as any,
      sortDirection: params.sort.order?.toLocaleLowerCase() as any,
      filterBy: params.filter,
    },
  });

  let balances: GetAccountBalanceListResponseDto = { data: [], total: 0 };

  try {
    const accountIds = data.map(anOfferOrder => anOfferOrder.accountId);

    if (!isEmpty(accountIds)) {
      balances = await getClientApi().balance.list({ body: { accountIds } });
    }
  } catch (error) {
    Logger.error('Error occurred while getting account balance list');
    Logger.error(error);
  }

  return {
    data: data.map(anOfferOrder =>
      mapOfferOrderDtoToModel(
        anOfferOrder,
        balances.data.find(aBalance => aBalance.accountId === anOfferOrder.accountId),
      ),
    ),
    total: total || data.length || 0,
  };
};

export const getOfferOrderDetails = async (
  params: GetOneParams<OfferOrderDetails>,
): Promise<GetOneResult<OfferOrderDetails>> => {
  const data = await clientApi.offerOrders.retrieve({ params });

  return {
    data: mapOfferOrderDetailsDtoToModel(data),
  };
};

export const updateOfferOrderListStatus = async (
  params: UpdateManyParams<Omit<UpdateOfferOrderListStatusBodyDto, 'ids'>>,
): Promise<UpdateManyResult<{ id: string }>> => {
  await clientApi.offerOrders.updateListStatus({
    body: { ...params.data, ids: params.ids as string[] },
  });

  return {
    data: params.ids.map(id => id.toString()),
  };
};

export const updateOfferOrder = async ({
  data,
  id,
}: UpdateParams<UpdateOfferOrderBodyDto>): Promise<UpdateResult<OfferOrderDetails>> => {
  const response = await clientApi.offerOrders.update({ params: { id: id.toString() }, body: data });

  return {
    data: mapOfferOrderDetailsDtoToModel(response),
  };
};

export const deleteOfferOrder = async (
  params: DeleteParams<OfferOrderDetails>,
): Promise<DeleteResult<{ id: string }>> => {
  await clientApi.offerOrders.delete({ params });

  return {
    data: {
      id: params.id,
    },
  };
};

export const createOfferOrderRefund = async (
  params: CreateParams<CreateOfferOrderRefundBodyDto>,
): Promise<CreateResult<OfferOrderDetails>> => {
  const response = await clientApi.offerOrders.createRefund({
    body: {
      mechanism: params.data.mechanism,
      adminNote: params.data.adminNote,
      bankName: params.data.bankName,
      reason: params.data.reason,
    },
    params: {
      id: params.meta.offerOrderId,
    },
  });

  return {
    data: mapOfferOrderDetailsDtoToModel(response),
  };
};

export const getOfferOrderCardPaymentList = async (
  params: GetListParams,
): Promise<GetListResult<OfferOrderCardPayment>> => {
  const response = await clientApi.offerOrders.listCardPayments({
    queryParams: {
      accountId: params.filter.accountId,
      skip: params.pagination.perPage * (params.pagination.page - 1),
      take: params.pagination.perPage,
    },
  });

  return {
    data: mapOfferOrderCardPaymentListDtoToModel(response.data),
    total: response.total,
  };
};

export const getOfferOrderCardPaymentDetails = async (
  params: GetOneParams,
): Promise<GetOneResult<OfferOrderCardPaymentDetails>> => {
  const response = await clientApi.offerOrders.retrieveCardPayment({
    params,
  });

  return {
    data: mapOfferOrderCardPaymentDetailsDtoToModel(response),
  };
};

export const createOfferOrderFileExport = async (
  params: CreateParams<CreateOfferOrderFileExportBodyDto>,
): Promise<CreateResult<FileExportDto>> => {
  const response = await clientApi.offerOrders.createFileExport({
    body: {
      columns: params.data.columns,
      name: params.data.name,
      offerId: params.data.offerId,
      status: params.data.status,
    },
  });

  return {
    data: response,
  };
};
