import { Cancel } from '@mui/icons-material';
import { Button, useNotify, useRefresh, useUpdate } from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { FileExport } from 'src/models';

export const CancelFileExportButton = ({ record }: { record: FileExport }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [cancelFileExport] = useUpdate<FileExport>();

  const onCancel = () => {
    cancelFileExport(
      DataProviderResource.CancelFileExport,
      {
        id: record.id,
        data: {},
      },
      {
        mutationMode: 'pessimistic',
        onSuccess: () => {
          notify(`File export was successfully canceled.`, {
            type: 'success',
            undoable: true,
          });
        },
        onSettled: () => {
          refresh();
        },
        onError: (error: any) => {
          notify(`${JSON.stringify(error?.message ?? error)}`, {
            type: 'error',
          });
        },
      },
    );
  };

  return (
    <Button
      label='Cancel'
      onClick={onCancel}
      startIcon={<Cancel />}
      disabled={!(record.status.isPendingBalance || record.status.isPending)}
      sx={{
        color: 'rgba(211, 47, 47)',
        '&:hover': {
          backgroundColor: 'rgba(211, 47, 47, 0.12)',
        },
      }}
    />
  );
};
