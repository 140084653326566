import { isEmpty } from 'lodash';
import { GetListParams, GetOneParams, GetOneResult, UpdateParams, UpdateResult } from 'react-admin';
import { getClientApi } from 'src/data-communication';
import {
  AdminTransferDetailsDto,
  SearchTransferSortByQueryParamTypeDto,
  SortDirectionTypeDto,
  TransferDetailsDto,
} from 'src/dtos';
import {
  mapTransferSearchListResponseDtoToModel,
  mapTransferDetailsDtoToModel,
  mapTransferDtoToModel,
} from 'src/mappers';
import { TransferDetails } from 'src/models';
import { Maybe } from 'src/utils';

const clientApi = getClientApi();

export const mapGetListSortOrderToSortDirectionQueryParamTypeDto = (sort: any): SortDirectionTypeDto => {
  return sort?.order ? sort.order.toLowerCase() : SortDirectionTypeDto.Asc;
};

export const mapSearchTransferListFilterToFilterByQueryParamDto = (
  filter: any,
): Maybe<{ query?: string; state?: string }> => {
  let result: { query?: string; state?: string } = {};

  if (filter.query) {
    result.query = filter.query;
  }

  if (filter.state) {
    result.state = filter.state;
  }

  if (isEmpty(result)) {
    return;
  }

  return result;
};

export const searchTransferList = async (params: GetListParams) => {
  const { pagination, sort } = params;

  const { data, total } = await clientApi.transfers.search({
    queryParams: {
      skip: pagination.perPage && pagination.perPage * (pagination.page - 1),
      take: pagination.perPage,
      filterBy: mapSearchTransferListFilterToFilterByQueryParamDto(params.filter),
      sortDirection: mapGetListSortOrderToSortDirectionQueryParamTypeDto(sort),
      sortBy: sort.field as SearchTransferSortByQueryParamTypeDto,
    },
  });

  return {
    data: mapTransferSearchListResponseDtoToModel(data),
    total,
  };
};

export const getTransferList = async (params: GetListParams) => {
  const { filter } = params;
  const { accountNumber } = <{ accountNumber?: string }>params.meta;
  const accountId = filter.accountId;

  const { data, total } = await clientApi.transfers.list({
    params: { accountId },
  });

  const models = data.map(anItem => mapTransferDtoToModel({ ...anItem.tradingBlockTransfer, accountNumber }));

  return {
    data: models,
    total,
  };
};

export const getTransfer = async (
  params: GetOneParams,
): Promise<GetOneResult<AdminTransferDetailsDto & { id: string; state: string }>> => {
  const { id } = params;
  const response = await clientApi.transfers.retrieve({
    params: { id: id.toString() },
  });

  return {
    data: {
      ...response,
      id: response.transferId.toString(),
      state: response.status,
    },
  };
};

export const updateTransfer = async (params: UpdateParams): Promise<UpdateResult<TransferDetails>> => {
  const { id } = params;
  const { note, accountId } = params.data;

  const response = await clientApi.transfers.update({
    params: { id: id.toString(), accountId },
    body: { adminNote: note },
  });

  return {
    data: mapTransferDetailsDtoToModel(response),
  };
};

export const cancelTransfer = async (params: UpdateParams): Promise<UpdateResult<TransferDetails>> => {
  const { id } = params;
  const { comment, accountId } = params.data;

  const response = await clientApi.transfers.cancel({
    params: { id: id.toString(), accountId },
    body: { comment },
  });

  return {
    data: mapTransferDetailsDtoToModel(response),
  };
};

export const firmApproveTransfer = async (params: UpdateParams): Promise<UpdateResult<TransferDetails>> => {
  const { id } = params;
  const { accountId, note } = params.data;

  const response = await clientApi.transfers.firmApprove({
    params: { id: id.toString(), accountId },
    body: { adminNote: note },
  });

  return {
    data: mapTransferDetailsDtoToModel(response),
  };
};

export const repApproveTransfer = async (params: UpdateParams): Promise<UpdateResult<TransferDetails>> => {
  const { id } = params;
  const { accountId, note } = params.data;

  const response = await clientApi.transfers.repApprove({
    params: { id: id.toString(), accountId },
    body: { adminNote: note },
  });

  return {
    data: mapTransferDetailsDtoToModel(response),
  };
};

export const rejectTransfer = async (params: UpdateParams): Promise<UpdateResult<TransferDetailsDto>> => {
  const { id } = params;
  const { accountId, note } = params.data;

  const response = await clientApi.transfers.reject({
    params: { id: id.toString(), accountId },
    body: { adminNote: note },
  });

  return {
    data: response,
  };
};
