import { Labeled, TextField, useRecordContext } from 'react-admin';
import { Row, Col } from 'react-grid-system';
import { Account } from 'src/models';

export const PhysicalAddressShow = () => {
  const record = useRecordContext<Account>();

  return (
    <>
      <div className='showContent'>
        <Row>
          <Col sm={12}>
            <h2 className='title'>
              {record.type.isJoint ? 'Primary Account Holder Address' : 'Account Holder Address'}
            </h2>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled label='Street Address (Line 1)'>
              <TextField source='primaryAccountHolder.physicalAddress.address1' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled label='Street Address (Line 2)'>
              <TextField source='primaryAccountHolder.physicalAddress.address2' />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled label='City'>
              <TextField source='primaryAccountHolder.physicalAddress.city' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled label='State'>
              <TextField source='primaryAccountHolder.physicalAddress.state.label' />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled label='Country'>
              <TextField source='primaryAccountHolder.physicalAddress.country.label' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled label='Postal Code'>
              <TextField source='primaryAccountHolder.physicalAddress.postalCode' />
            </Labeled>
          </Col>
        </Row>
      </div>
      {record.type.isJoint && (
        <div className='showContent'>
          <Row>
            <Col sm={12}>
              <h2 className='title'>Joint Account Holder Address</h2>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Street Address (Line 1)'>
                <TextField source='primaryAccountHolder.physicalAddress.address1' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Street Address (Line 2)'>
                <TextField source='primaryAccountHolder.physicalAddress.address2' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='City'>
                <TextField source='primaryAccountHolder.physicalAddress.city' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='State'>
                <TextField source='primaryAccountHolder.physicalAddress.state.label' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Country'>
                <TextField source='primaryAccountHolder.physicalAddress.country.label' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Postal Code'>
                <TextField source='primaryAccountHolder.physicalAddress.postalCode' />
              </Labeled>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
