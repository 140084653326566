import { ListButton, TopToolbar, useShowController } from 'react-admin';
import { OfferOversubscription } from 'src/models';

import { ApplyOfferOversubscriptionButton } from './ApplyOfferOversubscriptionButton';
import { CancelOfferOversubscriptionButton } from './CancelOfferOversubscriptionButton';

export const OfferOversubscriptionActions = () => {
  const { record } = useShowController<OfferOversubscription>();

  return (
    <TopToolbar>
      {record && <ApplyOfferOversubscriptionButton record={record} />}
      {record && <CancelOfferOversubscriptionButton record={record} />}
      <ListButton />
    </TopToolbar>
  );
};
