import * as React from 'react';

import { Grid } from '@mui/material';
import { BooleanInput, SelectInput, required, useRecordContext } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { OfferCardPaymentAccountTypeDto, OfferDetailsDto } from 'src/dtos';
import { GridBreak } from 'src/lib';

const accountTypeChoices = [
  { id: OfferCardPaymentAccountTypeDto.Custom, name: 'Custom' },
  { id: OfferCardPaymentAccountTypeDto.Standard, name: 'Standard' },
];

export interface PaymentsCreateEditProps {
  isEditMode?: boolean;
}

export const PaymentsCreateEdit = ({ isEditMode }: PaymentsCreateEditProps) => {
  const record = useRecordContext<OfferDetailsDto>();
  const allowCardPayments = useWatch<OfferDetailsDto, 'allowCardPayments'>({ name: 'allowCardPayments' });

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <h2>{isEditMode ? 'Edit' : 'Add'} Offer Payments</h2>
      </Grid>
      <Grid item xs={12} md={6}>
        <BooleanInput
          label='Allow ACH Transfers'
          source='allowAchTransfers'
          defaultValue={isEditMode ? record.allowAchTransfers : true}
          validate={[required()]}
          fullWidth
        />
        <BooleanInput
          label='Allow Wire Transfers'
          source='allowWireTransfers'
          defaultValue={isEditMode ? record.allowWireTransfers : true}
          validate={[required()]}
          fullWidth
        />
        <BooleanInput
          label='Allow Check Transfers'
          source='allowCheckTransfers'
          defaultValue={isEditMode ? record.allowCheckTransfers : true}
          validate={[required()]}
          fullWidth
        />
        <BooleanInput
          label='Allow ACAT Transfers'
          source='allowAcatTransfers'
          defaultValue={isEditMode ? record.allowAcatTransfers : true}
          validate={[required()]}
          fullWidth
        />
        <BooleanInput
          label='Allow IRA Contribution Wire Transfers'
          source='allowIraContributionWireTransfers'
          defaultValue={isEditMode ? record.allowIraContributionWireTransfers : true}
          validate={[required()]}
          fullWidth
        />
        <BooleanInput
          label='Allow IRA Contribution Check Transfers'
          source='allowIraContributionCheckTransfers'
          defaultValue={isEditMode ? record.allowIraContributionCheckTransfers : true}
          validate={[required()]}
          fullWidth
        />
      </Grid>
      <GridBreak />
      <Grid item xs={12} sm={6} md={4}>
        <BooleanInput label='Allow Card Payments' source='allowCardPayments' validate={[required()]} fullWidth />
        <SelectInput
          label='Account Type'
          source='cardPaymentAccount.type'
          disabled={isEditMode ? Boolean(record?.cardPaymentAccount) || !allowCardPayments : !allowCardPayments}
          choices={accountTypeChoices}
          validate={[required()]}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
