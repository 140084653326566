import {
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { getClientApi } from 'src/data-communication';
import { mapFileExportDtoToModel } from 'src/mappers/fileExport.mapper';
import { DownloadFileExportResponse, FileExport } from 'src/models';

const clientApi = getClientApi();

export const getFileExportList = async (params: GetListParams): Promise<GetListResult<FileExport>> => {
  const result = await clientApi.fileExport.list({
    queryParams: {
      name: params.filter?.name,
      offerId: params.filter?.offerId,
      status: params.filter?.status,
      skip: params.pagination.perPage * (params.pagination.page - 1),
      take: params.pagination.perPage,
    },
  });

  return {
    data: result.data.map(mapFileExportDtoToModel),
    total: result.total,
  };
};

export const getFileExport = async (params: GetOneParams): Promise<GetOneResult<FileExport>> => {
  const result = await clientApi.fileExport.retrieve({
    params: {
      id: params.id,
    },
  });

  return {
    data: mapFileExportDtoToModel(result),
  };
};

export const cancelFileExport = async (params: UpdateParams): Promise<UpdateResult<FileExport>> => {
  const result = await clientApi.fileExport.cancel({
    params: {
      id: params.id.toString(),
    },
  });

  return {
    data: mapFileExportDtoToModel(result),
  };
};

export const downloadFileExport = async (params: GetOneParams): Promise<GetOneResult<DownloadFileExportResponse>> => {
  const result = await clientApi.fileExport.download({
    params: {
      id: params.id,
    },
  });

  const fileUrl = URL.createObjectURL(result);

  return {
    data: { id: params.id, file: { url: fileUrl, type: 'csv' } },
  };
};

export const deleteFileExport = async (params: DeleteParams): Promise<DeleteResult<{ id: string }>> => {
  await clientApi.fileExport.delete({
    params: {
      id: params.id,
    },
  });

  return {
    data: { id: params.id },
  };
};
