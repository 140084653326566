import { Grid } from '@mui/material';
import { DateField, FunctionField, Show, SimpleShowLayout, TextField, useShowController } from 'react-admin';
import { StatusTag } from 'src/lib';
import { OfferOversubscription } from 'src/models';
import { getCurrencyFormatter, getOfferOversubscriptionStatusTagPalette } from 'src/utils';

import { OfferOversubscriptionActions } from './OfferOversubscriptionActions';
import { OfferOversubscriptionDraftOrderList } from './OfferOversubscriptionDraftOrderList';

export const OfferOversubscriptionShow = () => {
  const record = useShowController<OfferOversubscription>();

  return (
    <Show emptyWhileLoading title={'Offer Oversubscription'} actions={<OfferOversubscriptionActions />}>
      <Grid container direction='row' spacing={2}>
        <Grid item md={12}>
          <SimpleShowLayout>
            <h2>Offer Oversubscription</h2>
          </SimpleShowLayout>
        </Grid>
        <Grid item md={6}>
          <SimpleShowLayout>
            <TextField source='id' label='Id' fullWidth />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={6}>
          <SimpleShowLayout>
            <TextField source='offer.name' label='Offer' />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={6}>
          <SimpleShowLayout>
            <FunctionField
              label='Reduction Type'
              render={(record?: OfferOversubscription) => {
                if (!record) {
                  return null;
                }

                return `${record.type} (${record.percentage}%)`;
              }}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={6}>
          <SimpleShowLayout>
            <DateField source='createdAt' label='Created at' showTime />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={12}>
          <Grid container direction='row' spacing={2}>
            <Grid item md={2}>
              <SimpleShowLayout>
                <FunctionField
                  label='Status'
                  render={(record?: OfferOversubscription) =>
                    record ? (
                      <StatusTag
                        value={record.status.label}
                        style={getOfferOversubscriptionStatusTagPalette(record.status.value)}
                      />
                    ) : null
                  }
                />
              </SimpleShowLayout>
            </Grid>
          </Grid>
        </Grid>
        {record.error && (
          <Grid item md={12}>
            <SimpleShowLayout>
              <FunctionField
                label='Error'
                render={(record?: OfferOversubscription) => {
                  if (!record) {
                    return null;
                  }

                  if (!record.error) {
                    return 'NA';
                  }

                  return record.error;
                }}
              />
            </SimpleShowLayout>
          </Grid>
        )}
        <Grid item md={6}>
          <SimpleShowLayout>
            <FunctionField
              label='Total Funds Received'
              render={(record?: OfferOversubscription) => {
                if (!record) {
                  return null;
                }

                if (!record.receivedFunds) {
                  return 'NA';
                }

                return getCurrencyFormatter().format(record.receivedFunds);
              }}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={6}>
          <SimpleShowLayout>
            <FunctionField
              label='Total Oversubscribed Amount'
              render={(record?: OfferOversubscription) => {
                if (!record) {
                  return null;
                }

                if (!record.oversubscribedFunds) {
                  return 'NA';
                }

                if (record.oversubscribedFunds < 0) {
                  return 'NA';
                }

                return getCurrencyFormatter().format(record.oversubscribedFunds);
              }}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={12}>
          <SimpleShowLayout>
            <h2>Draft Orders</h2>
          </SimpleShowLayout>
          <OfferOversubscriptionDraftOrderList />
        </Grid>
      </Grid>
    </Show>
  );
};
