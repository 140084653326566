import { GetListParams, UpdateParams } from 'react-admin';
import { getClientApi } from 'src/data-communication';

const clientApi = getClientApi();

export const listAccountHolderUpdateRequests = async (params: GetListParams) => {
  const { filter } = params;
  const { accountId, accountHolderId } = filter;

  const data = await clientApi.accountHolders.listUpdateRequests({
    params: {
      id: accountId,
      accountHolderId,
    },
  });

  return {
    data,
    total: data.length,
  };
};

export const approveAccountHolderUpdateRequest = async (params: UpdateParams) => {
  const requestId = params.id;
  const { accountId, accountHolderId, note } = params.data;
  await clientApi.accountHolders.approveUpdateRequest({
    params: {
      id: accountId,
      accountHolderId,
      requestId: requestId.toString(),
    },
    body: {
      note,
    },
  });

  return {
    data: { id: requestId.toString() },
  };
};

export const rejectAccountHolderUpdateRequest = async (params: any) => {
  const requestId = params.id;
  const { accountId, accountHolderId, note } = params.data;
  await clientApi.accountHolders.rejectUpdateRequest({
    params: {
      id: accountId,
      requestId: requestId.toString(),
      accountHolderId,
    },
    body: {
      note,
    },
  });

  return {
    data: { id: requestId.toString() },
  };
};
