import { DeleteButton, useNotify, useRefresh } from 'react-admin';
import { FileExport } from 'src/models';

export const DeleteFileExportButton = ({ record }: { record: FileExport }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  return (
    <>
      <DeleteButton
        label='Delete'
        mutationMode='pessimistic'
        confirmContent={`Are you sure you want to delete "${record?.name}" file export?`}
        confirmTitle='Delete File Export'
        mutationOptions={{
          onSuccess: () => {
            notify('File export was successfully deleted.', {
              type: 'success',
              undoable: true,
            });
          },
          onSettled: () => {
            refresh();
          },
          onError: (error: any) => {
            notify(`${JSON.stringify(error?.message ?? error)}`, {
              type: 'error',
            });
          },
        }}
      />
    </>
  );
};
