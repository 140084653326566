import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import DroppableArea from './DroppableArea';
import { DragDropElementTree } from './FileExportSelector';

interface DragAndDropAreaProps {
  items: DragDropElementTree[] | undefined;
  handleOnDragEnd: (result: DropResult) => void;
  onChangeValue: (itemId: string) => void;
}

export const DragAndDropArea = ({ items, handleOnDragEnd, onChangeValue }: DragAndDropAreaProps) => {
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <DroppableArea items={items} onChangeValue={onChangeValue} />
    </DragDropContext>
  );
};
