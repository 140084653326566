import { BaseListResponseDto, PaginationQueryParams, SortDirectionQueryParams } from './common.dtos';
import { OfferExternalCheckInstructionsDto, OfferExternalWireInstructionsDto } from './offers.dtos';

export enum OfferOrderPaymentTypeDto {
  Ach = 'Ach',
  Wire = 'Wire',
  Check = 'Check',
  Card = 'Card',
  Acat = 'Acat',
}

export enum OfferOrderRetirementAccountFundingTypeDto {
  IraContribution = 'IraContribution',
}

export enum OfferOrderStatusDto {
  PendingFunds = 'PendingFunds',
  PendingAction = 'PendingAction',
  PendingOfferClose = 'PendingOfferClose',
  Approved = 'Approved',
  Complete = 'Complete',
  PendingFirmCancellation = 'PendingFirmCancellation',
  Cancelled = 'Cancelled',
  Rejected = 'Rejected',
  Deleted = 'Deleted',
}

export enum OfferOrderCardPaymentIntentStatusDto {
  RequiresPaymentMethod = 'RequiresPaymentMethod',
  RequiresConfirmation = 'RequiresConfirmation',
  RequiresAction = 'RequiresAction',
  Processing = 'Processing',
  RequiresCapture = 'RequiresCapture',
  Canceled = 'Canceled',
  Succeeded = 'Succeeded',
}

export enum OfferOrderRefundStatusDto {
  Pending = 'Pending',
  Failed = 'Failed',
  Succeeded = 'Succeeded',
  Canceled = 'Canceled',
  RequiresAction = 'RequiresAction',
}

export enum OfferOrderRefundReasonDto {
  Duplicate = 'Duplicate',
  Fraudulent = 'Fraudulent',
  RequestedByCustomer = 'RequestedByCustomer',
}

export enum OfferOrderRefundFailureReasonDto {
  LostOrStolenCard = 'LostOrStolenCard',
  ExpiredOrCanceledCard = 'ExpiredOrCanceledCard',
  ChargeForPendingRefundDisputed = 'ChargeForPendingRefundDisputed',
  InsufficientFunds = 'InsufficientFunds',
  Declined = 'Declined',
  MerchantRequest = 'MerchantRequest',
  Unknown = 'Unknown',
}

export interface OfferOrderCardPaymentDto {
  id: string;
  intentId: string;
  intentStatus: OfferOrderCardPaymentIntentStatusDto;
  isConfirmed: boolean;
  amountRefunded: number;
  amount: number;
  cardholder?: string;
  createdAt: string;
  updatedAt?: string;
}

export enum OfferOrderRefundMechanismDto {
  Ach = 'Ach',
  Card = 'Card',
  Wire = 'Wire',
  Check = 'Check',
}

export interface OfferOrderRefundDto {
  id: string;
  amount: number;
  mechanism: OfferOrderRefundMechanismDto;
  externalId?: string;
  status?: OfferOrderRefundStatusDto;
  reason?: OfferOrderRefundReasonDto;
  description?: string;
  failureReason?: OfferOrderRefundFailureReasonDto;
  bankName?: string;
  adminNote?: string;
  createdAt: string;
  updatedAt?: string;
}

export interface OfferOrderConditionalDto {
  minSharePrice: number;
  maxSharePrice: number;
  totalInvestment: number;
}

export interface OfferOrderSecurityDeliveryOptionDto {
  label: string;
  tooltip?: string;
}

export interface OfferOrderExternalDetailsDto {
  securityDeliveryOption?: OfferOrderSecurityDeliveryOptionDto;
}

export interface CreateBaseOfferOrderDto {
  offerId: string;
  isAccreditedInvestor?: boolean;
  localeDate: string;
  securityDeliveryOption?: OfferOrderSecurityDeliveryOptionDto;
}

export interface CreateStandardOfferOrderBodyDto extends CreateBaseOfferOrderDto {
  quantity: number;
  paymentType?: OfferOrderPaymentTypeDto;
  cardPaymentIntentId?: string;
  retirementAccountFundingType?: OfferOrderRetirementAccountFundingTypeDto;
}

export interface CreateConditionalOfferOrderBodyDto extends CreateBaseOfferOrderDto {
  totalInvestment: number;
  paymentType?: Exclude<OfferOrderPaymentTypeDto, OfferOrderPaymentTypeDto.Card>;
}

export type UpdateOfferOrderStatusDto = {
  ids: string[];
  status: OfferOrderStatusDto;
  useEmailNotifier?: boolean;
};

export interface OfferOrderWireInstructionsDto extends OfferExternalWireInstructionsDto {
  transferAmount?: number;
}

export interface OfferOrderCheckInstructionsDto extends OfferExternalCheckInstructionsDto {
  transferAmount?: number;
}

export type OfferOrderPaymentInstructionsDto = OfferOrderWireInstructionsDto | OfferOrderCheckInstructionsDto;

export interface OfferOrderDto {
  id: string;
  userId: string;
  offerId: string;
  offerName: string;
  offerCloseDate?: string;
  name?: string;
  symbol?: string;
  status: OfferOrderStatusDto;
  paymentType?: OfferOrderPaymentTypeDto;
  accountId: number;
  quantity: number;
  price: number;
  totalCost: number;
  isExternal: boolean;
  isAccreditedInvestor?: boolean;
  amountRefunded: number;
  refunds: OfferOrderRefundDto[];
  cardPayment?: OfferOrderCardPaymentDto;
  conditional?: OfferOrderConditionalDto;
  externalDetails?: OfferOrderExternalDetailsDto;
  retirementAccountFundingType?: OfferOrderRetirementAccountFundingTypeDto;
  createdAt: string;
  createdBy: string;
  updatedAt?: string;
  updatedBy?: string;
}

export interface OfferOrderCardPaymentDetailsDto extends OfferOrderCardPaymentDto {}

export interface OfferOrderDetailsDto extends OfferOrderDto {
  paymentInstructions?: OfferOrderPaymentInstructionsDto;
}

export interface CreateOfferOrderParamsDto {
  accountId: number;
}

export type CreateOfferOrderBodyDto = CreateConditionalOfferOrderBodyDto | CreateStandardOfferOrderBodyDto;

export interface CreateOfferOrderDto {
  params: CreateOfferOrderParamsDto;
  body: CreateOfferOrderBodyDto;
}

export interface GetOfferOrderListResponseDto extends BaseListResponseDto<OfferOrderDto[]> {}

export enum GetOfferOrderListSortByQueryParamDto {
  OfferName = 'offerName',
  Status = 'status',
  CreatedAt = 'createdAt',
  TotalCost = 'totalCost',
}

export interface GetOfferOrderListFilterByQueryParamDto {
  offerId?: string;
  offerStatusId?: string;
  status?: OfferOrderStatusDto[];
  accountId?: number;
}

export interface GetOfferOrderListQueryParamsDto extends Partial<PaginationQueryParams>, SortDirectionQueryParams {
  sortBy: GetOfferOrderListSortByQueryParamDto;
  filterBy?: GetOfferOrderListFilterByQueryParamDto;
}

export interface GetOfferOrderListDto {
  queryParams: GetOfferOrderListQueryParamsDto;
}

export interface GetOfferOrderDetailsParamsDto {
  id: string;
}

export interface GetOfferOrderDetailsDto {
  params: GetOfferOrderDetailsParamsDto;
}

export interface UpdateOfferOrderParamsDto {
  id: string;
}

export interface UpdateOfferOrderBodyDto {
  status?: OfferOrderStatusDto;
  isCardPaymentConfirmed?: boolean;
  localeDate?: string;
  useEmailNotifier?: boolean;
}

export interface UpdateOfferOrderDto {
  params: UpdateOfferOrderParamsDto;
  body: UpdateOfferOrderBodyDto;
}

export interface UpdateOfferOrderListStatusBodyDto {
  ids: string[];
  status: OfferOrderStatusDto;
  useEmailNotifier?: boolean;
}

export interface UpdateOfferOrderListStatusDto {
  body: UpdateOfferOrderListStatusBodyDto;
}

export interface DeleteOfferOrderParamsDto {
  id: string;
}

export interface DeleteOfferOrderDto {
  params: DeleteOfferOrderParamsDto;
}

export interface CreateOfferOrderCardPaymentIntentParamsDto {
  accountId: number;
}

export interface CreateOfferOrderCardPaymentIntentBodyDto {
  quantity: number;
  offerId: string;
  accountId: number;
}

export interface CreateOfferOrderCardPaymentIntentDto {
  body: CreateOfferOrderCardPaymentIntentBodyDto;
}

export interface CreateOfferOrderCardPaymentIntentResponseDto {
  clientSecret: string;
  cardPaymentIntentId: string;
  totalCost: number;
}

export interface UpdateOfferOrderCardPaymentIntentParamsDto {
  id: string;
}

export interface UpdateOfferOrderCardPaymentIntentBodyDto {
  quantity: number;
  cardPaymentAccount: {
    externalId: string;
  };
}

export interface UpdateOfferOrderCardPaymentIntentDto {
  params: UpdateOfferOrderCardPaymentIntentParamsDto;
  body: UpdateOfferOrderCardPaymentIntentBodyDto;
}

export interface UpdateOfferOrderCardPaymentIntentResponseDto {
  clientSecret: string;
  cardPaymentIntentId: string;
  totalCost: number;
}

export interface CreateOfferOrderRefundParamsDto {
  id: string;
}

export interface CreateOfferOrderRefundBodyDto {
  reason?: OfferOrderRefundReasonDto;
  bankName?: string;
  adminNote?: string;
  mechanism: OfferOrderRefundMechanismDto;
}

export interface CreateOfferOrderCardPaymentRefundDto {
  params: CreateOfferOrderRefundParamsDto;
  body: CreateOfferOrderRefundBodyDto;
}

export interface GetOfferOrderCardPaymentListDto {
  queryParams: GetOfferOrderCardPaymentListQueryParamsDto;
}

export interface GetOfferOrderCardPaymentParamsDto {
  id: string;
}

export interface GetOfferOrderCardPaymentDetailsDto {
  params: GetOfferOrderCardPaymentParamsDto;
}

export interface GetOfferOrderCardPaymentListResponseDto extends BaseListResponseDto<OfferOrderCardPaymentDto[]> {}

export interface GetOfferOrderCardPaymentListQueryParamsDto extends PaginationQueryParams {
  accountId?: number;
}

export enum OfferOrderFileExportColumnTypeDto {
  Id = 'id',
  Status = 'status',
  PaymentType = 'paymentType',
  Price = 'price',
  Quantity = 'quantity',
  TotalCost = 'totalCost',
  CreatedAt = 'createdAt',
  IsAccreditedInvestor = 'isAccreditedInvestor',
  SecurityDeliveryOption = 'securityDeliveryOption',
  AccountId = 'accountId',
  AccountNumber = 'accountNumber',
  PrimaryAccountHolderFirstName = 'primaryAccountHolderFirstName',
  PrimaryAccountHolderMiddleName = 'primaryAccountHolderMiddleName',
  PrimaryAccountHolderLastName = 'primaryAccountHolderLastName',
  PrimaryAccountHolderSuffix = 'primaryAccountHolderSuffix',
  PrimaryAccountHolderEmail = 'primaryAccountHolderEmail',
  PrimaryAccountHolderPhoneNumber = 'primaryAccountHolderPhoneNumber',
  PrimaryAccountHolderSsid = 'primaryAccountHolderSsid',
  PrimaryAccountHolderAddressLine1 = 'primaryAccountHolderAddressLine1',
  PrimaryAccountHolderAddressLine2 = 'primaryAccountHolderAddressLine2',
  PrimaryAccountHolderAddressCity = 'primaryAccountHolderAddressCity',
  PrimaryAccountHolderAddressState = 'primaryAccountHolderAddressState',
  PrimaryAccountHolderAddressPostalCode = 'primaryAccountHolderAddressPostalCode',
  SecondaryAccountHolderFirstName = 'secondaryAccountHolderFirstName',
  SecondaryAccountHolderMiddleName = 'secondaryAccountHolderMiddleName',
  SecondaryAccountHolderLastName = 'secondaryAccountHolderLastName',
  SecondaryAccountHolderSuffix = 'secondaryAccountHolderSuffix',
  SecondaryAccountHolderSsid = 'secondaryAccountHolderSsid',
  CashBalance = 'cashBalance',
  CashAvailable = 'cashAvailable',
  OfferName = 'offerName',
  OfferType = 'offerType',
  OfferStatus = 'offerStatus',
  OfferIndustry = 'offerIndustry',
  OfferSector = 'offerSector',
  OfferCategory = 'offerCategory',
  OfferSecurityType = 'offerSecurityType',
}

export enum OfferOrderFileExportColumnLabelDto {
  Id = 'Order Id',
  Status = 'Order Status',
  PaymentType = 'Payment Type',
  Price = 'Offer Price per Share',
  Quantity = 'Number of Shares',
  TotalCost = 'Requested Total Currency Amount',
  CreatedAt = 'Created At',
  IsAccreditedInvestor = 'Accreditation',
  SecurityDeliveryOption = 'Security Delivery Option',
  AccountId = 'Account Id',
  AccountNumber = 'Account Number',
  PrimaryAccountHolderFirstName = 'First Name',
  PrimaryAccountHolderMiddleName = 'Middle Name',
  PrimaryAccountHolderLastName = 'Last Name',
  PrimaryAccountHolderSuffix = 'Suffix',
  PrimaryAccountHolderEmail = 'Email',
  PrimaryAccountHolderPhoneNumber = 'Phone Number',
  PrimaryAccountHolderSsid = 'Ssid',
  PrimaryAccountHolderAddressLine1 = 'Address Line 1',
  PrimaryAccountHolderAddressLine2 = 'Address Line 2',
  PrimaryAccountHolderAddressCity = 'City',
  PrimaryAccountHolderAddressState = 'State',
  PrimaryAccountHolderAddressPostalCode = 'Postal Code',
  SecondaryAccountHolderFirstName = 'Joint First Name',
  SecondaryAccountHolderMiddleName = 'Joint Middle Name',
  SecondaryAccountHolderLastName = 'Joint Last Name',
  SecondaryAccountHolderSuffix = 'Joint Suffix',
  SecondaryAccountHolderSsid = 'Joint Ssid',
  CashBalance = 'Cash Balance',
  CashAvailable = 'Cash Available',
  OfferName = 'Offer Name',
  OfferType = 'Offer Type',
  OfferStatus = 'Offer Status',
  OfferIndustry = 'Offer Industry',
  OfferSector = 'Offer Sector',
  OfferCategory = 'Offer Category',
  OfferSecurityType = 'Offer Security Type',
}

export interface OfferOrderFileExportColumnDto {
  index: number;
  value: OfferOrderFileExportColumnTypeDto;
  children?: OfferOrderFileExportColumnTypeDto[]; // If it's a merge then it will have multiple values, e.g. when id and status is merged it will become ['id', 'status']
}

export interface CreateOfferOrderFileExportBodyDto {
  name: string;
  offerId: string;
  status: OfferOrderStatusDto;
  columns: OfferOrderFileExportColumnDto[];
}
export interface CreateOfferOrderFileExportDto {
  body: CreateOfferOrderFileExportBodyDto;
}
