import { FunctionField, Labeled, TextField, useRecordContext } from 'react-admin';
import { Row, Col } from 'react-grid-system';
import { Account, AccountHolderFinancialInvestmentExperienceType } from 'src/models';

export const InvestmentExperience = ({
  title,
  experience,
}: {
  title: string;
  experience?: AccountHolderFinancialInvestmentExperienceType;
}) => {
  return (
    <>
      <Row>
        <Col md={6}>
          <div className='investmentExperience'>
            <span>{title}</span>
            <Row>
              <Col sm={6}>
                <Labeled>
                  <FunctionField
                    render={(record?: AccountHolderFinancialInvestmentExperienceType) =>
                      record?.yearsOfExperience?.label ?? 'NA'
                    }
                    record={experience}
                    label='Years of Experience'
                  />
                </Labeled>
              </Col>
              <Col sm={6}>
                <Labeled>
                  <FunctionField
                    render={(record?: AccountHolderFinancialInvestmentExperienceType) =>
                      record?.tradesPerYear?.label ?? 'NA'
                    }
                    record={experience}
                    label='Trades per Year'
                  />
                </Labeled>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export const SuitabilityInformationShow = () => {
  const record = useRecordContext<Account>();

  return (
    <div className='showContent'>
      <Row>
        <Col sm={12}>
          <h2 className='title'>Suitability Information</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled>
            <TextField
              source='primaryAccountHolder.suitabilityInformation.liquidityNeeds.label'
              label='Liquidity Needs'
            />
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled>
            <TextField
              label='Investment Objectives'
              source='primaryAccountHolder.suitabilityInformation.investmentObjectives.label'
            />
          </Labeled>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled>
            <TextField
              source='primaryAccountHolder.suitabilityInformation.riskTolerance.label'
              label='Risk Tolerance'
            />
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled>
            <TextField source='primaryAccountHolder.suitabilityInformation.timeHorizon.label' label='Time Horizon' />
          </Labeled>
        </Col>
      </Row>
      <div className='subHeader'>Investment Experience</div>
      <InvestmentExperience
        title='Stocks'
        experience={record?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.stocks}
      />
      <InvestmentExperience
        title='Bonds'
        experience={record?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.bonds}
      />
      <InvestmentExperience
        title='Commodities'
        experience={record?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.commodities}
      />
      <InvestmentExperience
        title='Options'
        experience={record?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.options}
      />
      <InvestmentExperience
        title='Margins'
        experience={record?.primaryAccountHolder?.suitabilityInformation?.financialInvestmentExperience?.margins}
      />
    </div>
  );
};
