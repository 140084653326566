import { AccountBalanceDto } from 'src/dtos';
import { AccountBalance } from 'src/models';

export const findCashAvailable = (dto: AccountBalanceDto): number =>
  Number((dto.availableFunds - dto.pendingOfferOrdersTotal + dto.pendingExternalOfferOrdersTotal).toFixed(2));

export const mapAccountBalanceDtoToModel = (dto: AccountBalanceDto): AccountBalance => ({
  ...dto,
  cashAvailable: findCashAvailable(dto),
  id: dto.accountId.toString(),
});
