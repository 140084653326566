import { useEffect } from 'react';

import {
  useGetList,
  useList,
  ListContextProvider,
  Datagrid,
  TextField,
  DateField,
  Pagination,
  Loading,
  NumberField,
  FunctionField,
} from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { StatusTag } from 'src/lib';
import { Transfer } from 'src/models';
import { getTransferStatusTagColor } from 'src/utils/colors.utils';

import { usePagination } from '../../../hooks/usePagination';

export interface AccountTransferListProps {
  accountId: number;
  accountNumber?: string;
}

export const AccountTransferList = ({ accountId, accountNumber }: AccountTransferListProps) => {
  const { data, isLoading } = useGetList<Transfer>(DataProviderResource.Transfers, {
    filter: { accountId },
    meta: { accountNumber },
  });

  const { dataPage, setData, page, setPage, perPage, setPerPage } = usePagination<any>({});
  const listContext = useList({ data: dataPage, isLoading });

  useEffect(() => {
    setData(data ?? []);
  }, [data]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ListContextProvider
      value={{
        ...listContext,
        setPage,
        page,
      }}>
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          label='Status'
          render={(record?: Transfer) => {
            if (record) {
              return <StatusTag value={record.status.label} style={getTransferStatusTagColor(record.status.label)} />;
            }
          }}
        />
        <TextField source='description' label='Type' />
        <NumberField source='amount' label='Amount' options={{ style: 'currency', currency: 'USD' }} />
        <TextField source='from' />
        <TextField source='to' />
        <DateField showTime source='createdAt' label='Date of creation' />
        <TextField source='id' />
        <FunctionField
          label='TB State'
          render={(record?: Transfer) => {
            if (record) {
              return record.status.value.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase();
            }
          }}
        />
      </Datagrid>
      <Pagination
        perPage={perPage}
        total={data?.length ?? 0}
        rowsPerPageOptions={[5, 10]}
        page={page}
        setPerPage={setPerPage}
      />
    </ListContextProvider>
  );
};
