import { isEmpty, orderBy } from 'lodash';
import {
  OfferOrderCardPaymentDto,
  OfferOrderCardPaymentIntentStatusDto,
  OfferOrderRefundDto,
  OfferOrderRefundReasonDto,
  OfferOrderRefundStatusDto,
  OfferOrderDto,
  OfferOrderExternalDetailsDto,
  OfferOrderPaymentInstructionsDto,
  OfferOrderPaymentTypeDto,
  OfferOrderStatusDto,
  OfferOrderDetailsDto,
  OfferOrderRetirementAccountFundingTypeDto,
} from 'src/dtos';
import { Maybe } from 'src/utils';

export enum OfferOrderPaymentTypeLabel {
  Ach = 'ACH (Bank-to-Bank)',
  Wire = 'Wire transfer',
  Check = 'Check transfer',
  Card = 'Credit card',
  Acat = 'ACAT transfer',
}

export class OfferOrderPaymentType {
  private _value: OfferOrderPaymentTypeDto | undefined;

  constructor(dto?: OfferOrderPaymentTypeDto) {
    this._value = dto;
  }

  get value(): OfferOrderPaymentTypeDto | undefined {
    return this._value;
  }

  get isCard(): boolean {
    return this._value === OfferOrderPaymentTypeDto.Card;
  }

  get isAch(): boolean {
    return this._value === OfferOrderPaymentTypeDto.Ach;
  }

  get isWire(): boolean {
    return this._value === OfferOrderPaymentTypeDto.Wire;
  }

  get isCheck(): boolean {
    return this._value === OfferOrderPaymentTypeDto.Check;
  }

  get isAcat(): boolean {
    return this._value === OfferOrderPaymentTypeDto.Acat;
  }

  get label(): Maybe<OfferOrderPaymentTypeLabel> {
    switch (this._value) {
      case OfferOrderPaymentTypeDto.Ach:
        return OfferOrderPaymentTypeLabel.Ach;
      case OfferOrderPaymentTypeDto.Wire:
        return OfferOrderPaymentTypeLabel.Wire;
      case OfferOrderPaymentTypeDto.Check:
        return OfferOrderPaymentTypeLabel.Check;
      case OfferOrderPaymentTypeDto.Card:
        return OfferOrderPaymentTypeLabel.Card;
      case OfferOrderPaymentTypeDto.Acat:
        return OfferOrderPaymentTypeLabel.Acat;
    }
  }
}

export enum OfferOrderCardPaymentIntentStatusLabel {
  Canceled = 'Canceled',
  Processing = 'Processing',
  RequiresAction = 'Requires Action',
  RequiresCapture = 'Requires Capture',
  RequiresConfirmation = 'Requires Confirmation',
  RequiresPaymentMethod = 'Requires Payment Method',
  Succeeded = 'Succeeded',
  NA = 'NA',
}

export class OfferOrderCardPaymentIntentStatus {
  private _value: OfferOrderCardPaymentIntentStatusDto;

  constructor(status: OfferOrderCardPaymentIntentStatusDto) {
    this._value = status;
  }

  get value(): OfferOrderCardPaymentIntentStatusDto {
    return this._value;
  }

  get label(): OfferOrderCardPaymentIntentStatusLabel {
    switch (this._value) {
      case OfferOrderCardPaymentIntentStatusDto.Canceled:
        return OfferOrderCardPaymentIntentStatusLabel.Canceled;
      case OfferOrderCardPaymentIntentStatusDto.Processing:
        return OfferOrderCardPaymentIntentStatusLabel.Processing;
      case OfferOrderCardPaymentIntentStatusDto.RequiresAction:
        return OfferOrderCardPaymentIntentStatusLabel.RequiresAction;
      case OfferOrderCardPaymentIntentStatusDto.RequiresCapture:
        return OfferOrderCardPaymentIntentStatusLabel.RequiresCapture;
      case OfferOrderCardPaymentIntentStatusDto.RequiresConfirmation:
        return OfferOrderCardPaymentIntentStatusLabel.RequiresConfirmation;
      case OfferOrderCardPaymentIntentStatusDto.RequiresPaymentMethod:
        return OfferOrderCardPaymentIntentStatusLabel.RequiresPaymentMethod;
      case OfferOrderCardPaymentIntentStatusDto.Succeeded:
        return OfferOrderCardPaymentIntentStatusLabel.Succeeded;
      default:
        return OfferOrderCardPaymentIntentStatusLabel.NA;
    }
  }
}

// NOTE: this type is used only on admin panel to display refund status in the UI (if refunds exist)
// otherwise, if it's a card payment then the card payment status is used
export type OfferOrderPaymentStatusType =
  | OfferOrderCardPaymentIntentStatusDto
  | 'Refunded'
  | 'PartiallyRefunded'
  | 'PendingRefund'
  | 'NA';

export type OfferOrderPaymentStatusLabel =
  | OfferOrderCardPaymentIntentStatusLabel
  | 'Refunded'
  | 'Partially Refunded'
  | 'Refund Pending'
  | 'NA';

export class OfferOrderPaymentStatus {
  private _value: OfferOrderPaymentStatusType;

  constructor(dto: OfferOrderDto | OfferOrderDetailsDto) {
    this._value = this.findValue(dto);
  }

  private isLastRefundPending(dto: OfferOrderDto | OfferOrderDetailsDto) {
    const sortedRefunds = orderBy(dto.refunds, 'createdAt', 'desc');
    const lastRefund = sortedRefunds?.[0];

    return (
      lastRefund.status === OfferOrderRefundStatusDto.Pending ||
      lastRefund.status === OfferOrderRefundStatusDto.RequiresAction
    );
  }

  private isLastRefundSucceeded(dto: OfferOrderDto | OfferOrderDetailsDto) {
    const sortedRefunds = orderBy(dto.refunds, 'createdAt', 'desc');
    const lastRefund = sortedRefunds?.[0];

    return lastRefund.status === OfferOrderRefundStatusDto.Succeeded;
  }

  private findValue(dto: OfferOrderDto | OfferOrderDetailsDto): OfferOrderPaymentStatusType {
    if (
      dto.amountRefunded > 0 &&
      dto.amountRefunded < dto.totalCost &&
      !isEmpty(dto.refunds) &&
      this.isLastRefundSucceeded(dto)
    ) {
      return 'PartiallyRefunded';
    }

    if (
      dto.amountRefunded > 0 &&
      dto.amountRefunded === dto.totalCost &&
      !isEmpty(dto.refunds) &&
      this.isLastRefundSucceeded(dto)
    ) {
      return 'Refunded';
    }

    if (!isEmpty(dto.refunds) && this.isLastRefundPending(dto)) {
      return 'PendingRefund';
    }

    if (dto.paymentType === OfferOrderPaymentTypeDto.Card && dto.cardPayment) {
      return dto.cardPayment.intentStatus;
    }

    return 'NA';
  }

  get value(): OfferOrderPaymentStatusType {
    return this._value;
  }

  get label(): OfferOrderPaymentStatusLabel {
    switch (this._value) {
      case 'Refunded':
        return 'Refunded';
      case 'PartiallyRefunded':
        return 'Partially Refunded';
      case 'PendingRefund':
        return 'Refund Pending';
      case 'NA':
        return 'NA';
      default:
        return new OfferOrderCardPaymentIntentStatus(this._value).label;
    }
  }
}

export enum OfferOrderStatusLabel {
  PendingFunds = 'PENDING FUNDS',
  PendingAction = 'PENDING ACTION NEEDED',
  PendingOfferClose = 'PENDING OFFERING CLOSING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Complete = 'COMPLETED',
  PendingFirmCancellation = 'PENDING CANCELLATION',
  Cancelled = 'CANCELLED',
  Deleted = 'DELETED',
}

export class OfferOrderStatus {
  private _value: OfferOrderStatusDto;
  private _label: OfferOrderStatusLabel;

  constructor(state: OfferOrderStatusDto) {
    this._value = state;
    this._label = this.findLabel();
  }

  private findLabel(): OfferOrderStatusLabel {
    switch (this._value) {
      case OfferOrderStatusDto.PendingFunds:
        return OfferOrderStatusLabel.PendingFunds;
      case OfferOrderStatusDto.PendingAction:
        return OfferOrderStatusLabel.PendingAction;
      case OfferOrderStatusDto.PendingOfferClose:
        return OfferOrderStatusLabel.PendingOfferClose;
      case OfferOrderStatusDto.Approved:
        return OfferOrderStatusLabel.Approved;
      case OfferOrderStatusDto.Rejected:
        return OfferOrderStatusLabel.Rejected;
      case OfferOrderStatusDto.Complete:
        return OfferOrderStatusLabel.Complete;
      case OfferOrderStatusDto.Cancelled:
        return OfferOrderStatusLabel.Cancelled;
      case OfferOrderStatusDto.Deleted:
        return OfferOrderStatusLabel.Deleted;
      case OfferOrderStatusDto.PendingFirmCancellation:
        return OfferOrderStatusLabel.PendingFirmCancellation;
    }
  }

  get value(): OfferOrderStatusDto {
    return this._value;
  }

  set value(dto: OfferOrderStatusDto) {
    this._value = dto;
    this._label = this.findLabel();
  }

  get label(): OfferOrderStatusLabel {
    return this._label;
  }

  get isPendingFunds(): boolean {
    return this._value === OfferOrderStatusDto.PendingFunds;
  }

  get isPendingAction(): boolean {
    return this._value === OfferOrderStatusDto.PendingAction;
  }

  get isPendingOfferClose(): boolean {
    return this._value === OfferOrderStatusDto.PendingOfferClose;
  }

  get isApproved() {
    return this._value === OfferOrderStatusDto.Approved;
  }

  get isPendingFirmCancellation() {
    return this._value === OfferOrderStatusDto.PendingFirmCancellation;
  }

  get isCancelled() {
    return this._value === OfferOrderStatusDto.Cancelled;
  }

  get isRejected() {
    return this._value === OfferOrderStatusDto.Rejected;
  }

  get isCompleted() {
    return this._value === OfferOrderStatusDto.Complete;
  }
}

export class OfferOrderRefundStatus {
  private _value?: OfferOrderRefundStatusDto;
  private _label?: string;

  constructor(dto?: OfferOrderRefundStatusDto) {
    this._value = dto;
    this._label = this.findLabel(dto);
  }

  private findLabel(dto?: OfferOrderRefundStatusDto) {
    switch (dto) {
      case OfferOrderRefundStatusDto.Canceled:
        return 'Canceled';
      case OfferOrderRefundStatusDto.Failed:
        return 'Failed';
      case OfferOrderRefundStatusDto.Pending:
        return 'Pending';
      case OfferOrderRefundStatusDto.RequiresAction:
        return 'Requires Action';
      case OfferOrderRefundStatusDto.Succeeded:
        return 'Succeeded';
      default:
        return dto;
    }
  }

  get value(): OfferOrderRefundStatusDto | 'NA' {
    return this._value ?? 'NA';
  }

  get isPending() {
    return (
      this._value === OfferOrderRefundStatusDto.Pending || this._value === OfferOrderRefundStatusDto.RequiresAction
    );
  }

  get hasFailed() {
    return this._value === OfferOrderRefundStatusDto.Failed;
  }

  get hasSucceeded() {
    return this._value === OfferOrderRefundStatusDto.Succeeded;
  }

  get label(): string {
    return this._label ?? 'NA';
  }
}

export class OfferOrderRefundReason {
  private _value?: OfferOrderRefundReasonDto;
  private _label?: string;

  constructor(dto?: OfferOrderRefundReasonDto) {
    this._value = dto;
    this._label = this.findLabel(dto);
  }

  private findLabel(dto?: OfferOrderRefundReasonDto) {
    switch (dto) {
      case OfferOrderRefundReasonDto.Duplicate:
        return 'Duplicate';
      case OfferOrderRefundReasonDto.Fraudulent:
        return 'Fraudulent';
      case OfferOrderRefundReasonDto.RequestedByCustomer:
        return 'Requested by Customer';
      default:
        return dto;
    }
  }

  get value(): OfferOrderRefundReasonDto | 'NA' {
    return this._value ?? 'NA';
  }

  get label(): string {
    return this._label ?? 'NA';
  }
}

export interface OfferOrderRefund extends Omit<OfferOrderRefundDto, 'status' | 'reason'> {
  status: OfferOrderRefundStatus;
  reason: OfferOrderRefundReason;
}

export interface OfferOrderCardPayment extends Omit<OfferOrderCardPaymentDto, 'intentStatus'> {
  intentStatus: OfferOrderCardPaymentIntentStatus;
}

export interface OfferOrderCardPaymentDetails extends OfferOrderCardPayment {}

export enum OfferOrderRetirementAccountFundingTypeLabel {
  IraContribution = 'IRA Contribution',
}

export class OfferOrderRetirementAccountFundingType {
  private _value: OfferOrderRetirementAccountFundingTypeDto;
  private _label: OfferOrderRetirementAccountFundingTypeLabel;

  constructor(dto: OfferOrderRetirementAccountFundingTypeDto) {
    this._value = dto;
    this._label = this.findLabel();
  }

  private findLabel(): OfferOrderRetirementAccountFundingTypeLabel {
    return OfferOrderRetirementAccountFundingTypeLabel.IraContribution;
  }

  get value(): OfferOrderRetirementAccountFundingTypeDto {
    return this._value;
  }

  get label(): string {
    return this._label;
  }
}

export interface OfferOrder
  extends Omit<OfferOrderDto, 'paymentType' | 'status' | 'cardPayment' | 'refunds' | 'retirementAccountFundingType'> {
  paymentType: OfferOrderPaymentType;
  paymentStatus: OfferOrderPaymentStatus;
  status: OfferOrderStatus;
  cashBalance?: number;
  cashAvailable?: number;
  refunds: OfferOrderRefund[];
  cardPayment?: OfferOrderCardPayment;
  externalDetails?: OfferOrderExternalDetailsDto;
  retirementAccountFundingType?: OfferOrderRetirementAccountFundingType;
}

export interface OfferOrderDetails extends Omit<OfferOrder, 'cashBalance' | 'cashAvailable' | 'cardPayment'> {
  paymentInstructions?: OfferOrderPaymentInstructionsDto;
}
