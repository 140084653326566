import * as React from 'react';

import {
  Button,
  EditButton,
  ListButton,
  Show,
  Tab,
  TabbedShowLayout,
  TopToolbar,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { useLocation } from 'react-router-dom';

import Styles from '../../constants/styles';
import AssetsShow from './components/Assets/AssetsShow';
import { CreateOfferOversubscriptionButton } from './components/CreateOfferOversubscriptionButton';
import DescriptionShow from './components/Description/DescriptionShow';
import DisclaimerShow from './components/Disclaimer/DisclaimerShow';
import DocumentsShow from './components/Documents/DocumentsShow';
import { OfferEmailsShow } from './components/Emails/OfferEmailsShow';
import { ExternalOfferShow } from './components/External/ExternalOfferShow';
import InfoShow from './components/Info/InfoShow';
import OrdersShow from './components/Orders/OrdersShow';
import { PaymentsShow } from './components/Payments/PaymentsShow';
import PriceShow from './components/Price&Limits/PriceShow';

const Title = () => {
  const record = useRecordContext();

  return (
    <span>
      Offer: <strong>{record.name}</strong> ({record.id})
    </span>
  );
};

const Actions = () => {
  const record = useRecordContext();
  const location = useLocation();
  const notify = useNotify();
  const refresh = useRefresh();
  const [update, { isSuccess }] = useUpdate();
  const [url, setUrl] = React.useState<string>('');

  const onCompleteClick = () => {
    update(
      'offers.offer-orders',
      { id: record.id },
      {
        mutationMode: 'undoable',
        onSuccess: () => {
          notify(`All Offer Orders in status Active will be completed`, {
            type: 'info',
            undoable: true,
          });
        },
        onSettled: () => {
          refresh();
        },
        onError: err => {
          notify(`Something went wrong ${err}`, {
            type: 'error',
          });
        },
      },
    );
  };

  React.useEffect(() => {
    if (isSuccess) {
      notify(`Offer orders have ben successfully completed.`, {
        type: 'success',
      });
    }
  }, [isSuccess]);

  React.useEffect(() => {
    const split = location?.pathname?.split('/') || [];
    let tabNumber = split.length > 4 ? Number.parseInt(split.pop() || '', 10) : NaN;
    if (tabNumber > 6) tabNumber = 0;
    setUrl(`/offers/${record?.id}${!Number.isNaN(tabNumber) && tabNumber !== 0 ? `/${tabNumber}` : ''}`);
  }, [location]);

  return (
    <TopToolbar>
      <CreateOfferOversubscriptionButton />
      <Button
        onClick={() => onCompleteClick()}
        style={Styles.offer.completeButton}
        label={'Mark Offer Orders Completed'.toUpperCase()}></Button>
      <EditButton to={`${url}`} />
      <ListButton />
    </TopToolbar>
  );
};

export const OfferShow = () => (
  <Show emptyWhileLoading title={<Title />} actions={<Actions />}>
    <TabbedShowLayout>
      <Tab label='Info'>
        <InfoShow />
      </Tab>
      <Tab label='External'>
        <ExternalOfferShow />
      </Tab>
      <Tab label='Assets'>
        <AssetsShow />
      </Tab>
      <Tab label='Price & Limits'>
        <PriceShow />
      </Tab>
      <Tab label='Payments'>
        <PaymentsShow />
      </Tab>
      <Tab label='Description'>
        <DescriptionShow />
      </Tab>
      <Tab label='Disclaimer'>
        <DisclaimerShow />
      </Tab>
      <Tab label='Documents'>
        <DocumentsShow />
      </Tab>
      <Tab label='Emails'>
        <OfferEmailsShow />
      </Tab>
      <Tab label='Orders'>
        <OrdersShow />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default OfferShow;
