import { OfferOversubscriptionDraftOrderDto, OfferOversubscriptionDto } from 'src/dtos';
import {
  OfferOrderStatus,
  OfferOversubscription,
  OfferOversubscriptionDraftOrder,
  OfferOversubscriptionStatus,
} from 'src/models';

export const mapOfferOversubscriptionDtoToModel = (dto: OfferOversubscriptionDto): OfferOversubscription => ({
  ...dto,
  status: new OfferOversubscriptionStatus(dto.status),
});

export const mapOfferOversubscriptionDraftOrderDtoToModel = (
  dto: OfferOversubscriptionDraftOrderDto,
): OfferOversubscriptionDraftOrder => ({
  ...dto,
  actual: {
    ...dto.actual,
    status: new OfferOrderStatus(dto.actual.status),
  },
  oversubscription: {
    ...dto.oversubscription,
    status: new OfferOversubscriptionStatus(dto.oversubscription.status),
  },
});
