import { Grid } from '@mui/material';
import { FunctionField, Loading, NumberField, SimpleShowLayout, useGetOne, useRecordContext } from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { OfferDetailsDto, OfferOrderPaymentTypeDto } from 'src/dtos';
import { OfferOrderAggregate, OfferOrderPaymentsAggregate } from 'src/models';

import { OfferOrderPaymentAggregateField } from './OfferOrderPaymentAggregateField';
import { OfferOrderStatusSummaryAggregateField } from './OfferOrderStatusSummaryAggregateField';

export const OrdersShow = () => {
  const record = useRecordContext();

  const { data: offerOrderAggregate, isLoading } = useGetOne<OfferOrderAggregate>(
    DataProviderResource.AggregateOfferOrders,
    {
      id: record.id.toString(),
    },
  );
  const { data: offerOrderPaymentsAggregate } = useGetOne<OfferOrderPaymentsAggregate>(
    DataProviderResource.AggregateOfferOrderPayments,
    {
      id: record.id.toString(),
    },
  );

  if (isLoading) return <Loading />;

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>Orders Information</h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <NumberField
            record={offerOrderAggregate}
            label='Funds Indicated'
            source='indicatedFunds'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <NumberField
            label='Maximum Raise Amount'
            source='maxTotalRaiseCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <NumberField
            label='Minimum Raise Amount'
            source='minTotalRaiseCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <FunctionField
            label='Total Oversubscribed Amount'
            record={record}
            render={(offer: any) => {
              const overSubscriptionValue = (offerOrderAggregate?.receivedFunds ?? 0) - offer.maxTotalRaiseCurrencyAmt;

              return `${
                overSubscriptionValue > 0
                  ? `$${overSubscriptionValue.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : 'N/A'
              }`;
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <NumberField
            record={offerOrderAggregate}
            label='Total Funds Received'
            source='receivedFunds'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <NumberField
            record={offerOrderAggregate}
            label='Funds Available For Closing'
            source='availableForClosingFunds'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <NumberField
            record={offerOrderAggregate}
            label='Closed Funds'
            source='closedFunds'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <FunctionField
            label='Balance Available to Raise'
            render={(offer?: OfferDetailsDto) => {
              if (!offer) {
                return 'N/A';
              }

              const balanceAvailableToRaise =
                offer.maxTotalRaiseCurrencyAmt - (offerOrderAggregate?.receivedFunds ?? 0);

              return `${
                balanceAvailableToRaise > 0
                  ? `$${balanceAvailableToRaise.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : 'N/A'
              }`;
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <SimpleShowLayout>
          <NumberField record={offerOrderAggregate} label='Potential Investors' source='potentialInvestors' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <OfferOrderStatusSummaryAggregateField value={offerOrderAggregate} />
      </Grid>
      <Grid item xs={12} md={12}>
        <SimpleShowLayout>
          <h2>Payments Information</h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <OfferOrderPaymentAggregateField title='Existing Funds' paymentType='NA' value={offerOrderPaymentsAggregate} />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <OfferOrderPaymentAggregateField
          title='Ach Transfers'
          paymentType={OfferOrderPaymentTypeDto.Ach}
          value={offerOrderPaymentsAggregate}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <OfferOrderPaymentAggregateField
          title='Wire Transfers'
          paymentType={OfferOrderPaymentTypeDto.Wire}
          value={offerOrderPaymentsAggregate}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <OfferOrderPaymentAggregateField
          title='Check Transfers'
          paymentType={OfferOrderPaymentTypeDto.Check}
          value={offerOrderPaymentsAggregate}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <OfferOrderPaymentAggregateField
          title='Card Payments'
          paymentType={OfferOrderPaymentTypeDto.Card}
          value={offerOrderPaymentsAggregate}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <OfferOrderPaymentAggregateField
          title='Acat Transfers'
          paymentType={OfferOrderPaymentTypeDto.Acat}
          value={offerOrderPaymentsAggregate}
        />
      </Grid>
    </Grid>
  );
};
export default OrdersShow;
