import { FileExportDto } from 'src/dtos';
import { FileExport, FileExportStatus, FileExportType } from 'src/models';

export const mapFileExportDtoToModel = (dto: FileExportDto): FileExport => ({
  id: dto.id,
  name: dto.name,
  type: new FileExportType(dto.type),
  status: new FileExportStatus(dto.status),
  offer: dto.offer ?? undefined,
  error: dto.error,
  externalId: dto.externalId,
  createdAt: dto.createdAt,
  createdBy: dto.createdBy,
  updatedAt: dto.updatedAt,
});
