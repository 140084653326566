import { Datagrid, DateField, FunctionField, List, ShowButton, TextField } from 'react-admin';
import { StatusTag } from 'src/lib';
import { OfferOversubscription } from 'src/models';
import { getCurrencyFormatter, getOfferOversubscriptionStatusTagPalette } from 'src/utils';

import { ApplyOfferOversubscriptionButton } from './ApplyOfferOversubscriptionButton';
import { CancelOfferOversubscriptionButton } from './CancelOfferOversubscriptionButton';

export const OfferOversubscriptionList = () => {
  return (
    <List
      title='Offer Oversubscriptions'
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={false}
      emptyWhileLoading>
      <Datagrid bulkActionButtons={false}>
        <TextField source='id' label='Id' sortable={false} />
        <TextField source='offer.name' label='Offer' sortable={false} />
        <FunctionField
          label='Reduction Type'
          sortable={false}
          render={(record?: OfferOversubscription) => {
            if (!record) {
              return null;
            }

            return `${record.type} (${record.percentage}%)`;
          }}
        />
        <FunctionField
          label='Status'
          render={(record?: OfferOversubscription) =>
            record ? (
              <StatusTag
                value={record.status.label}
                style={getOfferOversubscriptionStatusTagPalette(record.status.value)}
              />
            ) : null
          }
          sortable={false}
        />
        <FunctionField
          label='Total Funds Received'
          render={(record?: OfferOversubscription) => {
            if (!record) {
              return null;
            }

            if (!record.receivedFunds) {
              return 'NA';
            }

            return getCurrencyFormatter().format(record.receivedFunds);
          }}
          sortable={false}
        />
        <FunctionField
          label='Total Oversubscribed Amount'
          render={(record?: OfferOversubscription) => {
            if (!record) {
              return null;
            }

            if (!record.oversubscribedFunds) {
              return 'NA';
            }

            if (record.oversubscribedFunds < 0) {
              return 'NA';
            }

            return getCurrencyFormatter().format(record.oversubscribedFunds);
          }}
          sortable={false}
        />
        <FunctionField
          label='Apply'
          render={(record?: OfferOversubscription) => {
            if (!record) {
              return null;
            }

            return <ApplyOfferOversubscriptionButton record={record} />;
          }}
          sortable={false}
        />
        <FunctionField
          label='Cancel'
          render={(record?: OfferOversubscription) => {
            if (!record) {
              return null;
            }

            return <CancelOfferOversubscriptionButton record={record} />;
          }}
          sortable={false}
        />
        <DateField source='createdAt' label='Created at' showTime sortable={false} />
        <ShowButton label='Show' />
      </Datagrid>
    </List>
  );
};
