import { Grid } from '@mui/material';
import get from 'lodash/get';
import {
  BooleanField,
  FunctionField,
  Loading,
  NumberField,
  SelectField,
  SimpleShowLayout,
  useGetOne,
  useRecordContext,
} from 'react-admin';
import { AccountBalance } from 'src/models';

import {
  annualIncomeChoices,
  employmentStatusChoices,
  liquidityNetWorthChoices,
  totalNetWorthChoices,
} from '../../../../constants/choices/financial.information.choices';
import { AccountTransferList } from '../../../Accounts/Summary/AccountTransferList';
import { AccountNontradablePositionList } from '../../../Orders/AccountNontradablePositionList';
import { AccountTradablePositionsList } from '../../../Orders/AccountTradablePositionsList';

export const UserInfoDetailsShow = () => {
  const record = useRecordContext();

  const { data: user, isLoading } = useGetOne('users', { id: record.userId });

  const { data: accountBalance } = useGetOne<AccountBalance>('balances', { id: record.accountId });

  if (isLoading) return <Loading />;

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>User Info</h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <SelectField
            record={user}
            label='Employment Status'
            source='account.primaryAccountHolder.financialInformation.employmentStatus'
            choices={employmentStatusChoices}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <FunctionField
            label='Finra Affiliate'
            record={user}
            source='account.primaryAccountHolder.disclosure.industryEmployed'
            render={(record: any | undefined, source: string | undefined) => (
              <BooleanField source='isFinra' record={{ ...record, isFinra: source ? !!get(record, source) : false }} />
            )}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <FunctionField
            label='Stakeholder'
            record={user}
            source='account.primaryAccountHolder.disclosure.stakeholder'
            render={(record: any | undefined, source: string | undefined) => (
              <BooleanField
                source='isStakeholder'
                record={{ ...record, isStakeholder: source ? !!get(record, source) : false }}
              />
            )}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <SelectField
            record={user}
            label='Annual Income'
            source='account.primaryAccountHolder.financialInformation.annualIncome'
            choices={annualIncomeChoices}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <SelectField
            record={user}
            label='Total Net Worth'
            source='account.primaryAccountHolder.financialInformation.totalNetWorth'
            choices={totalNetWorthChoices}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <SelectField
            record={user}
            label='Liquid Net Worth'
            source='account.primaryAccountHolder.financialInformation.liquidNetWorth'
            choices={liquidityNetWorthChoices}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <NumberField
            label='Cash Balance'
            source='cashBalance'
            record={accountBalance}
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <NumberField
            label='Cash Available'
            source='cashAvailable'
            record={accountBalance}
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <NumberField
            label='Funds available for withdrawal'
            source='cashAvailableForWithdrawal'
            record={accountBalance}
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={12} md={10} lg={10}>
        <SimpleShowLayout>
          <h2>Money Transactions</h2>
          <AccountTransferList accountId={record.accountId} />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={12} md={10} lg={10}>
        <SimpleShowLayout>
          <h2>Security Holdings</h2>
          <AccountTradablePositionsList accountId={record.accountId} />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={12} md={10} lg={10}>
        <SimpleShowLayout>
          <h2>Non-Traded Holdings</h2>
          <AccountNontradablePositionList accountId={record.accountId} />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};
export default UserInfoDetailsShow;
