import { useRecordContext } from 'react-admin';
import { Account } from 'src/models';

import { AccountHolderUpdateRequestList } from './AccountHolderUpdateRequestList';

export const UpdateRequestsShow = () => {
  const record = useRecordContext<Account>();

  return (
    <>
      {record.primaryAccountHolder?.id && (
        <AccountHolderUpdateRequestList isPrimary accountHolderId={record.primaryAccountHolder.id} />
      )}
      {record.secondaryAccountHolder?.id && (
        <AccountHolderUpdateRequestList isPrimary={false} accountHolderId={record.secondaryAccountHolder.id} />
      )}
    </>
  );
};
