import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { NumberField, SimpleShowLayout, useDataProvider, useGetOne, useNotify, useRecordContext } from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { Account, AccountBalance, GetAccountWithdrawalFundsBalanceResponse } from 'src/models';

import { AccountNontradablePositionList } from '../../Orders/AccountNontradablePositionList';
import { AccountTradablePositionsList } from '../../Orders/AccountTradablePositionsList';
import { UserAchRelationsList } from './UserAchRelationsList';

export interface TransactionsShowProps {
  accountId: number;
}

export const SummaryShow = ({ accountId }: TransactionsShowProps) => {
  const [withdrawalFundsBalance, setWithdrawalFundsBalance] = useState<GetAccountWithdrawalFundsBalanceResponse | null>(
    null,
  );
  const [cashAvailableForWithdrawal, setCashAvailableForWithdrawal] = useState<number | null>(null);
  const record = useRecordContext<Account>();
  const provider = useDataProvider();
  const notify = useNotify();
  const { data: accountBalance } = useGetOne<AccountBalance>('balances', { id: accountId.toString() });

  useEffect(() => {
    if (accountBalance && withdrawalFundsBalance) {
      setCashAvailableForWithdrawal(
        withdrawalFundsBalance.availableForWithdrawal -
          accountBalance.pendingOfferOrdersTotal +
          accountBalance.pendingExternalOfferOrdersTotal,
      );
    }
  }, [accountBalance, withdrawalFundsBalance]);

  useEffect(() => {
    if (!record.type.isRetirement) {
      provider
        .getOne<GetAccountWithdrawalFundsBalanceResponse>(DataProviderResource.AccountWithdrawalFundsBalance, {
          id: accountId.toString(),
        })
        .then(({ data }) => {
          setWithdrawalFundsBalance(data);
        })
        .catch(error => {
          notify(error.message, {
            type: 'error',
            autoHideDuration: 7500,
          });
        });
    }
  }, [record.type, accountId]);

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>Account Summary</h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <NumberField
            label='Cash balance'
            source='cashBalance'
            record={accountBalance}
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <NumberField
            label='Cash available'
            source='cashAvailable'
            record={accountBalance}
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4}>
        <SimpleShowLayout>
          <NumberField
            label='Funds available for withdrawal'
            source='cashAvailableForWithdrawal'
            record={{ cashAvailableForWithdrawal }}
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={12}>
        <SimpleShowLayout>
          <h2>ACH Relationships</h2>
          {!record.type.isRetirement && <UserAchRelationsList accountId={accountId} />}
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={12}>
        <SimpleShowLayout>
          <h2> Securities Holdings</h2>
          <AccountTradablePositionsList accountId={accountId} />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={12}>
        <SimpleShowLayout>
          <h2> Non-Traded Holdings</h2>
          <AccountNontradablePositionList accountId={accountId} />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};
