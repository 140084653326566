import { OfferOversubscriptionDto, OfferOversubscriptionStatusDto } from 'src/dtos';

import { OfferOrderStatus } from './offerOrders.models';

export enum OfferOversubscriptionStatusLabel {
  Error = 'Error',
  Canceled = 'Canceled',
  Pending = 'Pending',
  UnderReview = 'Under Review',
  Applying = 'Applying',
  Completed = 'Completed',
}

export class OfferOversubscriptionStatus {
  private _value: OfferOversubscriptionStatusDto;
  private _label: OfferOversubscriptionStatusLabel;

  constructor(type: OfferOversubscriptionStatusDto) {
    this._value = type;
    this._label = this.findLabel(type);
  }

  private findLabel(type: OfferOversubscriptionStatusDto): OfferOversubscriptionStatusLabel {
    switch (type) {
      case OfferOversubscriptionStatusDto.Error:
        return OfferOversubscriptionStatusLabel.Error;
      case OfferOversubscriptionStatusDto.Canceled:
        return OfferOversubscriptionStatusLabel.Canceled;
      case OfferOversubscriptionStatusDto.Completed:
        return OfferOversubscriptionStatusLabel.Completed;
      case OfferOversubscriptionStatusDto.Pending:
        return OfferOversubscriptionStatusLabel.Pending;
      case OfferOversubscriptionStatusDto.UnderReview:
        return OfferOversubscriptionStatusLabel.UnderReview;
      case OfferOversubscriptionStatusDto.Applying:
        return OfferOversubscriptionStatusLabel.Applying;
    }
  }

  get value(): OfferOversubscriptionStatusDto {
    return this._value;
  }

  get label(): OfferOversubscriptionStatusLabel {
    return this._label;
  }

  get isPending(): boolean {
    return this._value === OfferOversubscriptionStatusDto.Pending;
  }

  get isUnderReview(): boolean {
    return this._value === OfferOversubscriptionStatusDto.UnderReview;
  }

  get isCompleted(): boolean {
    return this._value === OfferOversubscriptionStatusDto.Completed;
  }
}

export interface OfferOversubscription extends Omit<OfferOversubscriptionDto, 'status'> {
  status: OfferOversubscriptionStatus;
}

export interface OfferOversubscriptionDraftOrder {
  id: string;
  quantity: number;
  totalCost: number;
  actual: {
    id: string;
    quantity: number;
    price: number;
    totalCost: number;
    accountId: number;
    status: OfferOrderStatus;
    createdAt: string;
  };
  previous?: {
    quantity: number;
    totalCost: number;
    price: number;
  };
  oversubscription: {
    id: string;
    status: OfferOversubscriptionStatus;
  };
  offer: {
    id: string;
    name: string;
  };
  createdAt: string;
  updatedAt?: string;
}
