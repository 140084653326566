import { AccountHolderDto } from 'src/dtos';
import {
  AccountHolder,
  AccountHolderAddress,
  AccountHolderDisclosures,
  AccountHolderFinancialInformation,
  AccountHolderMaritalStatus,
  AccountHolderSuitabilityInformation,
  AccountHolderTrustedContact,
} from 'src/models';
import { getCountryOptionLabel } from 'src/utils';

export const mapAccountHolderBirthCountryDtoToModel = (birthCountry: string) => ({
  value: birthCountry,
  label: getCountryOptionLabel(birthCountry) ?? birthCountry,
});

export const mapAccountHolderCitizenshipCountryDtoToModel = (citizenship: string) => ({
  value: citizenship,
  label: 'U.S. Citizen',
});

export const mapAccountHolderDtoToModel = (dto: AccountHolderDto): AccountHolder => ({
  ...dto,
  citizenshipCountry: dto.citizenshipCountry
    ? mapAccountHolderCitizenshipCountryDtoToModel(dto.citizenshipCountry)
    : undefined,
  birthCountry: dto.birthCountry ? mapAccountHolderBirthCountryDtoToModel(dto.birthCountry) : undefined,
  physicalAddress: dto.physicalAddress ? new AccountHolderAddress(dto.physicalAddress) : undefined,
  mailingAddress: dto.mailingAddress ? new AccountHolderAddress(dto.mailingAddress) : undefined,
  maritalStatus: dto.maritalStatus ? new AccountHolderMaritalStatus(dto.maritalStatus) : undefined,
  trustedContact: dto.trustedContact ? new AccountHolderTrustedContact(dto.trustedContact) : undefined,
  suitabilityInformation: dto.suitabilityInformation
    ? new AccountHolderSuitabilityInformation(dto.suitabilityInformation)
    : undefined,
  financialInformation: dto.financialInformation
    ? new AccountHolderFinancialInformation(dto.financialInformation)
    : undefined,
  disclosure: dto.disclosure ? new AccountHolderDisclosures(dto.disclosure) : undefined,
  jointTenantsInCommonInterestPercent: dto.jointTenantsInCommonInterestPercent,
});
