import { Fragment } from 'react';

import { Grid, Typography } from '@mui/material';
import { SimpleShowLayout } from 'react-admin';
import { OfferOrderStatusDtoTag } from 'src/components/Fields/OfferOrderStatusDtoTag';
import { OfferOrderStatusDto } from 'src/dtos';
import { OfferOrderAggregate } from 'src/models';
import { getCurrencyFormatter } from 'src/utils';

interface OfferOrderStatusSummaryAggregateFieldProps {
  value?: OfferOrderAggregate;
}

export const OfferOrderStatusSummaryAggregateField = ({ value }: OfferOrderStatusSummaryAggregateFieldProps) => {
  const findStatusAggregate = (status: OfferOrderStatusDto) => {
    return value?.statusSummary?.find(item => item.status === status);
  };

  const rows = Object.values(OfferOrderStatusDto).map(item => ({
    key: item,
    status: <OfferOrderStatusDtoTag value={item} />,
    total: findStatusAggregate(item)?.total ?? 0,
    totalAmount: getCurrencyFormatter().format(findStatusAggregate(item)?.totalAmount ?? 0),
  }));

  return (
    <SimpleShowLayout>
      <Grid item xs={12} sm={12} md={12}>
        <h3>Accumulated Investments</h3>
      </Grid>
      <Grid container spacing={2} xs={4}>
        <Grid item xs={8}>
          <Typography variant='subtitle2'>Status</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='subtitle2'>Total</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant='subtitle2'>Total Amount</Typography>
        </Grid>
        {rows.map(row => (
          <Fragment key={row.key}>
            <Grid item xs={8}>
              {row.status}
            </Grid>
            <Grid item xs={2}>
              {row.total}
            </Grid>
            <Grid item xs={2}>
              {row.totalAmount}
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </SimpleShowLayout>
  );
};
