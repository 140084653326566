import React, { useState } from 'react';

import { CheckCircle, Cancel } from '@mui/icons-material';
import { Confirm, useNotify, useRefresh, useUpdate } from 'react-admin';

import Colors from '../../../../constants/colors';
import Styles from '../../../../constants/styles';

const DecideActions = ({ accountId }: { accountId?: number }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const notify = useNotify();
  const refresh = useRefresh();
  const [update] = useUpdate();

  const handleApprove = () => {
    setConfirmOpen(true);
    setActiveItem('investigation.APPEAL');
  };

  const handleReject = () => {
    setConfirmOpen(true);
    setActiveItem('investigation.REJECT');
  };

  const actionEntity = (actionType: string, data: any) => {
    setBtnDisabled(true);

    update(
      actionType,
      { data },
      {
        onError: (error: any) => {
          notify(error.message ?? 'An uncaught error occurred!', { type: 'error' });
        },
        onSuccess: () => {
          getNotificationMessage();
          refresh();
        },
        onSettled: () => {
          setBtnDisabled(false);
          setConfirmOpen(false);
        },
      },
    );
  };

  const confirmAction = () => {
    switch (activeItem) {
      case 'investigation.APPEAL':
        actionEntity('investigation.APPEAL', { accountId });
        break;

      case 'investigation.REJECT':
        actionEntity('investigation.REJECT', { accountId });
        break;

      default:
        break;
    }
  };

  const getConfirmModalContent = () => {
    switch (activeItem) {
      case 'investigation.APPEAL':
        return {
          header: 'Appeal Investigation',
          body: 'Are you sure you want to appeal the investigation?',
        };
      case 'investigation.REJECT':
        return {
          header: 'Reject Investigation',
          body: 'Are you sure you want to reject the investigation?',
        };
      default:
        return {
          header: 'Confirm',
          body: 'Are you sure you want to do the following action?',
        };
    }
  };

  const getNotificationMessage = () => {
    switch (activeItem) {
      case 'investigation.APPEAL':
        return notify('Investigation appealed successfully', { type: 'success' });
      case 'investigation.REJECT':
        return notify('Investigation rejected successfully', { type: 'success' });
    }
  };

  return (
    <>
      <button className='customButton' style={Styles.achRelationship.menuItem} onClick={() => handleApprove()}>
        <CheckCircle style={{ color: Colors.SUCCESS }} />
        <span style={{ color: Colors.SUCCESS_DARK, paddingLeft: 5 }}>APPEAL</span>
      </button>
      <button className='customButton' style={Styles.achRelationship.menuItem} onClick={() => handleReject()}>
        <Cancel style={{ color: Colors.DANGER }} />
        <span style={{ color: Colors.DANGER_DARK, paddingLeft: 5 }}>REJECT</span>
      </button>
      <Confirm
        isOpen={confirmOpen}
        loading={btnDisabled}
        title={getConfirmModalContent()?.header}
        content={getConfirmModalContent()?.body}
        onConfirm={confirmAction}
        onClose={() => setConfirmOpen(false)}
      />
    </>
  );
};
export default DecideActions;
