import { isEmpty } from 'lodash';
import { BooleanField, Labeled } from 'react-admin';
import { Row, Col } from 'react-grid-system';
import { TradingBlockApplicationCipCategory, TradingBlockInvestigationCipCategory } from 'src/models';

import StatusField from '../../../Fields/StatusField';

export interface CipCategoryListProps {
  categories: TradingBlockInvestigationCipCategory[] | TradingBlockApplicationCipCategory[];
}

export const isTradingBlockInvestigationInvestigationCipCategory = (
  value: TradingBlockInvestigationCipCategory | TradingBlockApplicationCipCategory,
): value is TradingBlockInvestigationCipCategory => Boolean((value as TradingBlockInvestigationCipCategory).state);

export const CipCategoryList = ({ categories }: CipCategoryListProps) => (
  <>
    {categories.map((category: TradingBlockInvestigationCipCategory | TradingBlockApplicationCipCategory) => (
      <Row key={'category' + category.name} style={{ padding: 0 }}>
        <Col md={4}>
          <Labeled label={category.name}>
            {isTradingBlockInvestigationInvestigationCipCategory(category) ? (
              <StatusField
                status={category.state}
                success={['Accepted', 'Appealed']}
                warning={['Pending', 'New', 'Indeterminate']}
                fail={['Rejected', 'Error', 'Canceled']}
              />
            ) : (
              <></>
            )}
          </Labeled>
        </Col>
        <Col md={4}>
          <>
            <p>Requested Documents </p>
            {isEmpty(category.requestedDocuments)
              ? 'NA'
              : category.requestedDocuments?.map(doc => doc.label).join(', ')}
          </>
        </Col>
        <Col md={4}>
          <Labeled label='Is Resolved'>
            <BooleanField source='isResolved' record={category} />
          </Labeled>
        </Col>
      </Row>
    ))}
  </>
);
