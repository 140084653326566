import { useEffect, useState } from 'react';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Dialog,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Divider,
} from '@mui/material';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useDataProvider, useGetList, useNotify } from 'react-admin';
import { DataProviderResource, QueryParamConstant } from 'src/constants';
import { OfferDto, OfferOrderStatusDto } from 'src/dtos';
import { FileExportDto } from 'src/dtos/fileExport.dto';
import { FileExportSelector, DragDropElementTree } from 'src/lib';
import { OfferOrderAggregate } from 'src/models';
import * as yup from 'yup';

const schema = yup.object().shape({
  filename: yup.string().required('Filename is required'),
  offerId: yup.string().required('Offer Name is required'),
  status: yup.string().oneOf(Object.values(OfferOrderStatusDto), 'Invalid order status'),
});

interface OfferOrderFileExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  fields: DragDropElementTree[];
}

export const OfferOrderFileExportModal = ({ isOpen, onClose, fields }: OfferOrderFileExportModalProps) => {
  const [sortedFields, updateSortedFields] = useState<DragDropElementTree[]>(JSON.parse(JSON.stringify(fields)));
  const [isLoading, setIsLoading] = useState(false);
  const [offerOrderAggregate, setOfferOrderAggregate] = useState<OfferOrderAggregate>();
  const [offerOrdersTotal, setOfferOrdersTotal] = useState(0);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { data } = useGetList<OfferDto>('offers', {
    pagination: { perPage: QueryParamConstant.SKIP_PAGINATION, page: QueryParamConstant.SKIP_PAGINATION },
  });

  const onExport = async (formValues: { filename: string; offerId: string; status?: string }) => {
    setIsLoading(true);

    let columns = sortedFields.filter(field => {
      if (isEmpty(field.children)) {
        return field.isSelected;
      }

      return field.children.some(child => child.isSelected);
    });

    columns = columns.map(aField => {
      if (isEmpty(aField.children)) {
        return aField;
      }

      const firstSelectedChildIndex = aField.children.findIndex(child => child.isSelected);

      return {
        id: aField.children[firstSelectedChildIndex].id,
        name: aField.children[firstSelectedChildIndex].name,
        isSelected: aField.children[firstSelectedChildIndex].isSelected,
        children: aField.children.slice(firstSelectedChildIndex + 1).filter(aChild => aChild.isSelected),
      };
    });

    dataProvider
      .create<FileExportDto>(DataProviderResource.CreateOfferOrderFileExport, {
        data: {
          name: formValues.filename,
          offerId: formValues.offerId,
          status: isEmpty(formValues.status?.trim()) ? undefined : formValues.status,
          columns: columns.map((column, index) => ({
            index,
            value: column.id,
            children: column.children?.map(child => child.id) ?? [],
          })),
        },
      })
      .then(response => {
        notify(
          `Offer order file export request (id=${response.data.id}, filename=${response.data.name}) was successfully created.`,
          {
            type: 'success',
            autoHideDuration: 7500,
          },
        );
        _onClose();
      })
      .catch(error => {
        notify(error.message, {
          type: 'error',
          autoHideDuration: 7500,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      filename: '',
      offerId: '',
      status: '',
    },
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: onExport,
  });

  const resetModal = () => {
    updateSortedFields(JSON.parse(JSON.stringify(fields)));
    formik.resetForm();
    setOfferOrderAggregate(undefined);
    setOfferOrdersTotal(0);
  };

  const _onClose = () => {
    onClose();
    resetModal();
  };

  useEffect(() => {
    if (offerOrderAggregate) {
      if (!formik.values.status) {
        const allOfferOrders = offerOrderAggregate.statusSummary.reduce((acc, item) => acc + item.total, 0);
        setOfferOrdersTotal(allOfferOrders);

        return;
      }
      const statusSummary = offerOrderAggregate.statusSummary.find(item => item.status === formik.values.status);
      setOfferOrdersTotal(statusSummary?.total ?? 0);
    }
  }, [offerOrderAggregate, formik.values.status]);

  useEffect(() => {
    if (formik.values.offerId) {
      dataProvider
        .getOne<OfferOrderAggregate>(DataProviderResource.AggregateOfferOrders, {
          id: formik.values.offerId,
        })
        .then(({ data }) => {
          setOfferOrderAggregate(data);
        })
        .catch(error => {
          notify(error.message, {
            type: 'error',
            autoHideDuration: 7500,
          });
        });
    }
  }, [formik.values.offerId]);

  return (
    <Dialog open={isOpen} maxWidth='md' fullWidth onClose={_onClose}>
      <DialogTitle id='alert-dialog-title'>{'Export Options'}</DialogTitle>
      <DialogContent dividers>
        <Grid container direction='column' alignItems='center' justifyContent='space-between'>
          <FileExportSelector items={sortedFields} onChange={updateSortedFields} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <Grid container direction='column' spacing={2}>
            <Grid item xs={6} md={6}>
              <TextField
                name='filename'
                label='Filename'
                variant='standard'
                margin='dense'
                type='text'
                disabled={isLoading}
                error={Boolean(formik.errors.filename)}
                helperText={formik.errors.filename}
                value={formik.values.filename}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                fullWidth
                sx={{ width: '35%' }}
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl margin='dense' variant='standard' sx={{ width: '35%' }}>
                <InputLabel id='offer-label'>Offer Name</InputLabel>
                <Select
                  name='offerId'
                  labelId='offer-label'
                  id='offer'
                  disabled={isLoading}
                  error={Boolean(formik.errors.offerId)}
                  value={formik.values.offerId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}>
                  {data?.map(offer => (
                    <MenuItem key={offer.id} value={offer.id}>
                      {offer.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.offerId && (
                  <Typography variant='caption' color='error'>
                    {formik.errors.offerId}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6} md={6}>
              <FormControl margin='dense' variant='standard' fullWidth sx={{ width: '35%' }}>
                <InputLabel id='status-label'>Offer Order Status</InputLabel>
                <Select
                  name='status'
                  labelId='status-label'
                  id='status'
                  disabled={isLoading}
                  error={formik.touched.status && !!formik.errors.status}
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}>
                  <MenuItem value=''>
                    <em>None</em>
                  </MenuItem>
                  <Divider />
                  {Object.values(OfferOrderStatusDto).map(status => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
                {formik.errors.status && (
                  <Typography variant='caption' color='error'>
                    {formik.errors.status}
                  </Typography>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='caption' color='textSecondary'>
                Offer Orders Total: {offerOrdersTotal}
              </Typography>
            </Grid>
            <Grid item xs={12} container direction='row' justifyContent='flex-end' spacing={2}>
              <Grid item>
                <Button variant='outlined' color='inherit' size='medium' onClick={_onClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant='contained' color='primary' size='medium' disabled={isLoading} type='submit'>
                  Create
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogActions>
    </Dialog>
  );
};
