import { Row, Col } from 'react-grid-system';

import { AccountOrderList } from '../../Orders/AccountOrderList';
import { AccountTransferList } from '../Summary/AccountTransferList';
import { AccountOfferOrderCardPaymentList } from './AccountOfferOrderCardPaymentList';
import { AccountOfferOrderList } from './AccountOfferOrderList';

export interface HistoryShowProps {
  accountId: number;
  accountNumber?: string;
}

export const HistoryShow = ({ accountId, accountNumber }: HistoryShowProps) => {
  return (
    <div className='transactionsContent'>
      <Row>
        <Col sm={12}>
          <h2 className='title'>Account History</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h3 className='subHeader'> Money Transfers</h3>
          <AccountTransferList accountId={accountId} accountNumber={accountNumber} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h3 className='subHeader'> Card Payments</h3>
          <AccountOfferOrderCardPaymentList accountId={accountId} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h3 className='subHeader'> Offer Orders</h3>
          <AccountOfferOrderList accountId={accountId} />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <h3> Security Buy/Sell Orders</h3>
          <AccountOrderList accountId={accountId} />
        </Col>
      </Row>
    </div>
  );
};
