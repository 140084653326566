import {
  AccountHolderMaritalStatusDto,
  AccountHolderAddressDto,
  AccountHolderAnnualIncomeDto,
  AccountHolderDisclosureDto,
  AccountHolderEmploymentStatusDto,
  AccountHolderFinancialInformationDto,
  AccountHolderFinancialInvestmentExperienceTypeDto,
  AccountHolderInvestmentExperienceDto,
  AccountHolderInvestmentObjectivesDto,
  AccountHolderLiquidityNeedsDto,
  AccountHolderLiquidNetWorthDto,
  AccountHolderRiskToleranceDto,
  AccountHolderStatementDeliveryDto,
  AccountHolderSuitabilityInformationDto,
  AccountHolderTaxBracketDto,
  AccountHolderTimeHorizonDto,
  AccountHolderTotalNetWorthDto,
  AccountHolderTradesPerYearDto,
  AccountHolderTrustedContactDto,
  AccountHolderYearsEmployedDto,
  AccountHolderYearsOfExperienceDto,
  PhoneNumberDto,
} from 'src/dtos';
import { getCountryOptionLabel, getUnitedStateOptionLabel, Maybe } from 'src/utils';

export class AccountHolderAddress {
  private _value: Omit<AccountHolderAddressDto, 'state' | 'country'> & {
    state: { value: string; label: string };
    country: { value: string; label: string };
    sameAsPhysical?: boolean;
  };

  constructor(dto: AccountHolderAddressDto & { isSameASPhysical?: boolean }) {
    this._value = {
      ...dto,
      state: { value: dto.state, label: getUnitedStateOptionLabel(dto.state) ?? 'NA' },
      country: { value: dto.country, label: getCountryOptionLabel(dto.country) ?? 'NA' },
    };
  }

  get address1(): string {
    return this._value.address1;
  }

  get address2(): Maybe<string> {
    return this._value.address2;
  }

  get city(): string {
    return this._value.city;
  }

  get state(): { value: string; label: string } {
    return this._value.state;
  }

  get country(): { value: string; label: string } {
    return this._value.country;
  }

  get postalCode(): string {
    return this._value.postalCode;
  }

  get sameAsPhysical(): Maybe<boolean> {
    return this._value.sameAsPhysical;
  }
}

export enum AccountHolderMaritalStatusLabel {
  Single = 'Single',
  Married = 'Married',
  Divorced = 'Divorced',
  Widowed = 'Widowed',
}

export class AccountHolderMaritalStatus {
  private _label?: AccountHolderMaritalStatusLabel;
  private _value?: AccountHolderMaritalStatusDto;

  constructor(status?: AccountHolderMaritalStatusDto) {
    this._value = status;
    this._label = this.findLabel();
  }

  private findLabel(): Maybe<AccountHolderMaritalStatusLabel> {
    switch (this._value) {
      case AccountHolderMaritalStatusDto.Single:
        return AccountHolderMaritalStatusLabel.Single;
      case AccountHolderMaritalStatusDto.Married:
        return AccountHolderMaritalStatusLabel.Married;
      case AccountHolderMaritalStatusDto.Divorced:
        return AccountHolderMaritalStatusLabel.Divorced;
      case AccountHolderMaritalStatusDto.Widowed:
        return AccountHolderMaritalStatusLabel.Widowed;
      default:
        return undefined;
    }
  }

  get value(): Maybe<AccountHolderMaritalStatusDto> {
    return this._value;
  }

  get label(): Maybe<AccountHolderMaritalStatusLabel> {
    return this._label;
  }
}

export enum AccountHolderLiquidityNeedsLabel {
  NotImportant = 'Not Important',
  SomewhatImportant = 'Somewhat Important',
  VeryImportant = 'Very Important',
}

export enum AccountHolderInvestmentObjectivesLabel {
  CapitalPreservation = 'Capital Preservation',
  Income = 'Income',
  Growth = 'Growth',
  GrowthAndIncome = 'Growth and Income',
  Speculation = 'Speculation',
}

export enum AccountHolderRiskToleranceLabel {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export enum AccountHolderTimeHorizonLabel {
  Short = 'Short',
  Average = 'Average',
  Long = 'Long',
}

export enum AccountHolderYearsOfExperienceLabel {
  One = '1',
  TwoToThree = '2-3',
  FourToNine = '4-9',
  TenOrMore = '10+',
}

export enum AccountHolderTradesPerYearLabel {
  OneToFive = '1-5',
  SixToTen = '6-10',
  ElevenToTwenty = '11-20',
  MoreThanTwenty = '20+',
}

export class AccountHolderFinancialInvestmentExperienceType {
  private _tradesPerYear: { value: AccountHolderTradesPerYearDto; label: AccountHolderTradesPerYearLabel };
  private _yearsOfExperience: {
    value: AccountHolderYearsOfExperienceDto;
    label: AccountHolderYearsOfExperienceLabel;
  };

  constructor(dto: AccountHolderFinancialInvestmentExperienceTypeDto) {
    this._tradesPerYear = {
      value: dto.tradesPerYear,
      label: this.findTradesPerYearLabel(dto.tradesPerYear),
    };
    this._yearsOfExperience = {
      value: dto.yearsOfExperience,
      label: this.findYearsOfExperienceLabel(dto.yearsOfExperience),
    };
  }

  private findYearsOfExperienceLabel(value: AccountHolderYearsOfExperienceDto): AccountHolderYearsOfExperienceLabel {
    switch (value) {
      case AccountHolderYearsOfExperienceDto.One:
        return AccountHolderYearsOfExperienceLabel.One;
      case AccountHolderYearsOfExperienceDto.TwoToThree:
        return AccountHolderYearsOfExperienceLabel.TwoToThree;
      case AccountHolderYearsOfExperienceDto.FourToNine:
        return AccountHolderYearsOfExperienceLabel.FourToNine;
      case AccountHolderYearsOfExperienceDto.TenOrMore:
        return AccountHolderYearsOfExperienceLabel.TenOrMore;
    }
  }

  private findTradesPerYearLabel(value: AccountHolderTradesPerYearDto): AccountHolderTradesPerYearLabel {
    switch (value) {
      case AccountHolderTradesPerYearDto.OneToFive:
        return AccountHolderTradesPerYearLabel.OneToFive;
      case AccountHolderTradesPerYearDto.SixToTen:
        return AccountHolderTradesPerYearLabel.SixToTen;
      case AccountHolderTradesPerYearDto.ElevenToTwenty:
        return AccountHolderTradesPerYearLabel.ElevenToTwenty;
      case AccountHolderTradesPerYearDto.MoreThanTwenty:
        return AccountHolderTradesPerYearLabel.MoreThanTwenty;
    }
  }

  get tradesPerYear(): { value: AccountHolderTradesPerYearDto; label: AccountHolderTradesPerYearLabel } {
    return this._tradesPerYear;
  }

  get yearsOfExperience(): { value: AccountHolderYearsOfExperienceDto; label: AccountHolderYearsOfExperienceLabel } {
    return this._yearsOfExperience;
  }
}

export class AccountHolderFinancialInvestmentExperience {
  private _stocks: Maybe<AccountHolderFinancialInvestmentExperienceType>;
  private _options: Maybe<AccountHolderFinancialInvestmentExperienceType>;
  private _commodities: Maybe<AccountHolderFinancialInvestmentExperienceType>;
  private _bonds: Maybe<AccountHolderFinancialInvestmentExperienceType>;
  private _margins: Maybe<AccountHolderFinancialInvestmentExperienceType>;

  constructor({ stocks, options, commodities, bonds, margins }: AccountHolderInvestmentExperienceDto) {
    this._stocks = stocks ? new AccountHolderFinancialInvestmentExperienceType(stocks) : undefined;
    this._options = options ? new AccountHolderFinancialInvestmentExperienceType(options) : undefined;
    this._commodities = commodities ? new AccountHolderFinancialInvestmentExperienceType(commodities) : undefined;
    this._bonds = bonds ? new AccountHolderFinancialInvestmentExperienceType(bonds) : undefined;
    this._margins = margins ? new AccountHolderFinancialInvestmentExperienceType(margins) : undefined;
  }

  get stocks(): Maybe<AccountHolderFinancialInvestmentExperienceType> {
    return this._stocks;
  }

  get options(): Maybe<AccountHolderFinancialInvestmentExperienceType> {
    return this._options;
  }

  get commodities(): Maybe<AccountHolderFinancialInvestmentExperienceType> {
    return this._commodities;
  }

  get bonds(): Maybe<AccountHolderFinancialInvestmentExperienceType> {
    return this._bonds;
  }

  get margins(): Maybe<AccountHolderFinancialInvestmentExperienceType> {
    return this._margins;
  }
}

export class AccountHolderSuitabilityInformation {
  private _liquidityNeeds: {
    value: AccountHolderLiquidityNeedsDto;
    label: AccountHolderLiquidityNeedsLabel;
  };
  private _investmentObjectives: {
    value: AccountHolderInvestmentObjectivesDto;
    label: AccountHolderInvestmentObjectivesLabel;
  };
  private _riskTolerance: { value: AccountHolderRiskToleranceDto; label: AccountHolderRiskToleranceLabel };
  private _timeHorizon: { value: AccountHolderTimeHorizonDto; label: AccountHolderTimeHorizonLabel };
  private _financialInvestmentExperience: Maybe<AccountHolderFinancialInvestmentExperience>;

  constructor(suitabilityInformation: AccountHolderSuitabilityInformationDto) {
    this._liquidityNeeds = {
      value: suitabilityInformation.liquidityNeeds,
      label: this.findLiquidityNeedsLabel(suitabilityInformation.liquidityNeeds),
    };
    this._investmentObjectives = {
      value: suitabilityInformation.investmentObjectives,
      label: this.findInvestmentObjectivesLabel(suitabilityInformation.investmentObjectives),
    };
    this._riskTolerance = {
      value: suitabilityInformation.riskTolerance,
      label: this.findRiskToleranceLabel(suitabilityInformation.riskTolerance),
    };
    this._timeHorizon = {
      value: suitabilityInformation.timeHorizon,
      label: this.findTimeHorizonLabel(suitabilityInformation.timeHorizon),
    };
    this._financialInvestmentExperience = suitabilityInformation.financialInvestmentExperience
      ? new AccountHolderFinancialInvestmentExperience(suitabilityInformation.financialInvestmentExperience)
      : undefined;
  }

  private findLiquidityNeedsLabel(value: AccountHolderLiquidityNeedsDto): AccountHolderLiquidityNeedsLabel {
    switch (value) {
      case AccountHolderLiquidityNeedsDto.NotImportant:
        return AccountHolderLiquidityNeedsLabel.NotImportant;
      case AccountHolderLiquidityNeedsDto.SomewhatImportant:
        return AccountHolderLiquidityNeedsLabel.SomewhatImportant;
      case AccountHolderLiquidityNeedsDto.VeryImportant:
        return AccountHolderLiquidityNeedsLabel.VeryImportant;
    }
  }

  private findInvestmentObjectivesLabel(
    value: AccountHolderInvestmentObjectivesDto,
  ): AccountHolderInvestmentObjectivesLabel {
    switch (value) {
      case AccountHolderInvestmentObjectivesDto.CapitalPreservation:
        return AccountHolderInvestmentObjectivesLabel.CapitalPreservation;
      case AccountHolderInvestmentObjectivesDto.Income:
        return AccountHolderInvestmentObjectivesLabel.Income;
      case AccountHolderInvestmentObjectivesDto.Growth:
        return AccountHolderInvestmentObjectivesLabel.Growth;
      case AccountHolderInvestmentObjectivesDto.GrowthAndIncome:
        return AccountHolderInvestmentObjectivesLabel.GrowthAndIncome;
      case AccountHolderInvestmentObjectivesDto.Speculation:
        return AccountHolderInvestmentObjectivesLabel.Speculation;
    }
  }

  private findRiskToleranceLabel(value: AccountHolderRiskToleranceDto): AccountHolderRiskToleranceLabel {
    switch (value) {
      case AccountHolderRiskToleranceDto.Low:
        return AccountHolderRiskToleranceLabel.Low;
      case AccountHolderRiskToleranceDto.Medium:
        return AccountHolderRiskToleranceLabel.Medium;
      case AccountHolderRiskToleranceDto.High:
        return AccountHolderRiskToleranceLabel.High;
    }
  }

  private findTimeHorizonLabel(value: AccountHolderTimeHorizonDto): AccountHolderTimeHorizonLabel {
    switch (value) {
      case AccountHolderTimeHorizonDto.Short:
        return AccountHolderTimeHorizonLabel.Short;
      case AccountHolderTimeHorizonDto.Average:
        return AccountHolderTimeHorizonLabel.Average;
      case AccountHolderTimeHorizonDto.Long:
        return AccountHolderTimeHorizonLabel.Long;
    }
  }

  get liquidityNeeds(): { value: AccountHolderLiquidityNeedsDto; label: AccountHolderLiquidityNeedsLabel } {
    return this._liquidityNeeds;
  }

  get investmentObjectives(): {
    value: AccountHolderInvestmentObjectivesDto;
    label: AccountHolderInvestmentObjectivesLabel;
  } {
    return this._investmentObjectives;
  }

  get riskTolerance(): { value: AccountHolderRiskToleranceDto; label: AccountHolderRiskToleranceLabel } {
    return this._riskTolerance;
  }

  get timeHorizon(): { value: AccountHolderTimeHorizonDto; label: AccountHolderTimeHorizonLabel } {
    return this._timeHorizon;
  }

  get financialInvestmentExperience(): Maybe<AccountHolderFinancialInvestmentExperience> {
    return this._financialInvestmentExperience;
  }
}

export class AccountHolderTrustedContact {
  private _value: Omit<
    AccountHolderTrustedContactDto,
    'address1' | 'address2' | 'city' | 'state' | 'country' | 'postalCode'
  > & {
    address: AccountHolderAddress;
  };

  constructor(dto: AccountHolderTrustedContactDto) {
    this._value = {
      ...dto,
      address: new AccountHolderAddress({
        address1: dto.address1,
        address2: dto.address2,
        city: dto.city,
        state: dto.state,
        country: dto.country,
        postalCode: dto.postalCode,
      }),
    };
  }

  get firstName(): Maybe<string> {
    return this._value.firstName;
  }

  get lastName(): Maybe<string> {
    return this._value.lastName;
  }

  get email(): Maybe<string> {
    return this._value.email;
  }

  get phones(): Maybe<PhoneNumberDto[]> {
    return this._value.phones;
  }

  get address1(): string {
    return this._value.address.address1;
  }

  get address2(): Maybe<string> {
    return this._value.address.address2;
  }

  get city(): string {
    return this._value.address.city;
  }

  get state(): { value: string; label: string } {
    return this._value.address.state;
  }

  get country(): { value: string; label: string } {
    return this._value.address.country;
  }

  get postalCode(): string {
    return this._value.address.postalCode;
  }
}

export enum AccountHolderAnnualIncomeLabel {
  LessThan25K = '$0K-$25K',
  Between25KAnd50K = '$25K-$50K',
  Between50KAnd100K = '$50K-$100K',
  Between100KAnd200K = '$100K-$200K',
  Between200KAnd300K = '$200K-$300K',
  Between300KAnd500K = '$300K-$500K',
  Between500KAnd1Point2M = '$500K-$1.2M',
  MoreThan1Point2M = '$1.2M+',
}

export enum AccountHolderTotalNetWorthLabel {
  LessThan50K = '$0K-$50K',
  Between50KAnd100K = '$50K-$100K',
  Between100KAnd200K = '$100K-$200K',
  Between200KAnd500K = '$200K-$500K',
  Between500KAnd1M = '$500K-$1M',
  Between1MAnd5M = '$1M-$5M',
  MoreThan5M = '$5M+',
}

export enum AccountHolderLiquidNetWorthLabel {
  LessThan50K = '$0K-$50K',
  Between50KAnd100K = '$50K-$100K',
  Between100KAnd200K = '$100K-$200K',
  Between200KAnd500K = '$200K-$500K',
  Between500KAnd1M = '$500K-$1M',
  Between1MAnd5M = '$1M-$5M',
  MoreThan5M = '$5M+',
}

export enum AccountHolderTaxBracketLabel {
  Bottom = '10-19%',
  Middle = '20-29%',
  Top = '30-37%',
}

export enum AccountHolderEmploymentStatusLabel {
  Employed = 'Employed',
  Retired = 'Retired',
  Student = 'Student',
  Unemployed = 'Unemployed',
}

export enum AccountHolderYearsEmployedLabel {
  BetweenZeroAndOne = 'Less than 1 year',
  BetweenTwoAndFive = '2 to 5 years',
  BetweenFiveAndTen = '5 to 10 years',
  BetweenTenAndTwenty = '10 to 20 years',
  MoreThanTwenty = 'More than 20 years',
}

interface Range {
  min: number;
  max: number;
}

export class AccountHolderFinancialInformation {
  private _annualIncomeValue: Maybe<AccountHolderAnnualIncomeDto>;
  private _annualIncomeLabel: Maybe<AccountHolderAnnualIncomeLabel>;
  private _totalNetWorthValue: Maybe<AccountHolderTotalNetWorthDto>;
  private _totalNetWorthLabel: Maybe<AccountHolderTotalNetWorthLabel>;
  private _liquidNetWorthValue: Maybe<AccountHolderLiquidNetWorthDto>;
  private _liquidNetWorthLabel: Maybe<AccountHolderLiquidNetWorthLabel>;
  private _taxBracketValue: Maybe<AccountHolderTaxBracketDto>;
  private _taxBracketLabel: Maybe<AccountHolderTaxBracketLabel>;
  private _employmentStatusValue: Maybe<AccountHolderEmploymentStatusDto>;
  private _employmentStatusLabel: Maybe<AccountHolderEmploymentStatusLabel>;
  private _employerName: Maybe<string>;
  private _jobTitle: Maybe<string>;
  private _yearsEmployedValue: Maybe<AccountHolderYearsEmployedDto>;
  private _yearsEmployedLabel: Maybe<AccountHolderYearsEmployedLabel>;
  private _employerAddress: Maybe<AccountHolderAddress>;
  private _annualIncomeBucketToRangeMapper: Record<AccountHolderAnnualIncomeDto, Range> = {
    lessThan25K: { min: 0, max: 24999 },
    between25KAnd50K: { min: 25000, max: 49999 },
    between50KAnd100K: { min: 50000, max: 99999 },
    between100KAnd200K: { min: 100000, max: 199999 },
    between200KAnd300K: { min: 200000, max: 299999 },
    between300KAnd500K: { min: 300000, max: 499999 },
    between500KAnd1Point2M: { min: 500000, max: 1199999 },
    moreThan1Point2M: { min: 1200000, max: Number.MAX_VALUE },
  };
  private _totalNetWorthBucketToRangeMapper: Record<AccountHolderTotalNetWorthDto, Range> = {
    lessThan50K: { min: 0, max: 49999 },
    between50KAnd100K: { min: 50000, max: 99999 },
    between100KAnd200K: { min: 100000, max: 199999 },
    between200KAnd500K: { min: 200000, max: 499999 },
    between500KAnd1M: { min: 50000, max: 999999 },
    between1MAnd5M: { min: 1000000, max: 4999999 },
    moreThan5M: { min: 5000000, max: Number.MAX_VALUE },
  };
  private _liquidNetWorthBucketToRangeMapper: Record<AccountHolderLiquidNetWorthDto, Range> = {
    lessThan50K: { min: 0, max: 49999 },
    between50KAnd100K: { min: 50000, max: 99999 },
    between100KAnd200K: { min: 100000, max: 199999 },
    between200KAnd500K: { min: 200000, max: 499999 },
    between500KAnd1M: { min: 500000, max: 999999 },
    between1MAnd5M: { min: 1000000, max: 4999999 },
    moreThan5M: { min: 5000000, max: Number.MAX_VALUE },
  };

  constructor(dto: AccountHolderFinancialInformationDto) {
    this._annualIncomeValue = dto.annualIncome;
    this._annualIncomeLabel = dto.annualIncome ? this.findAnnualIncomeLabel(dto.annualIncome) : undefined;
    this._totalNetWorthValue = dto.totalNetWorth;
    this._totalNetWorthLabel = dto.totalNetWorth ? this.findTotalNetWorthLabel(dto.totalNetWorth) : undefined;
    this._liquidNetWorthValue = dto.liquidNetWorth;
    this._liquidNetWorthLabel = dto.liquidNetWorth ? this.findLiquidNetWorthLabel(dto.liquidNetWorth) : undefined;
    this._taxBracketValue = dto.taxBracket;
    this._taxBracketLabel = dto.taxBracket ? this.findTaxBracketLabel(dto.taxBracket) : undefined;
    this._employmentStatusValue = dto.employmentStatus;
    this._employmentStatusLabel = dto.employmentStatus
      ? this.findEmploymentStatusLabel(dto.employmentStatus)
      : undefined;
    this._employerName = dto.employerName;
    this._jobTitle = dto.jobTitle;
    this._yearsEmployedValue = dto.yearsEmployed;
    this._yearsEmployedLabel = dto.yearsEmployed ? this.findYearsEmployedLabel(dto.yearsEmployed) : undefined;
    this._employerAddress = dto.employerAddress ? this.findEmployerAddress(dto.employerAddress) : undefined;
  }

  private findAnnualIncomeLabel(annualIncome: AccountHolderAnnualIncomeDto): AccountHolderAnnualIncomeLabel {
    switch (annualIncome) {
      case AccountHolderAnnualIncomeDto.LessThan25K:
        return AccountHolderAnnualIncomeLabel.LessThan25K;
      case AccountHolderAnnualIncomeDto.Between25KAnd50K:
        return AccountHolderAnnualIncomeLabel.Between25KAnd50K;
      case AccountHolderAnnualIncomeDto.Between50KAnd100K:
        return AccountHolderAnnualIncomeLabel.Between50KAnd100K;
      case AccountHolderAnnualIncomeDto.Between100KAnd200K:
        return AccountHolderAnnualIncomeLabel.Between100KAnd200K;
      case AccountHolderAnnualIncomeDto.Between200KAnd300K:
        return AccountHolderAnnualIncomeLabel.Between200KAnd300K;
      case AccountHolderAnnualIncomeDto.Between300KAnd500K:
        return AccountHolderAnnualIncomeLabel.Between300KAnd500K;
      case AccountHolderAnnualIncomeDto.Between500KAnd1Point2M:
        return AccountHolderAnnualIncomeLabel.Between500KAnd1Point2M;
      case AccountHolderAnnualIncomeDto.MoreThan1Point2M:
        return AccountHolderAnnualIncomeLabel.MoreThan1Point2M;
    }
  }

  private findTotalNetWorthLabel(totalNetWorth: AccountHolderTotalNetWorthDto): AccountHolderTotalNetWorthLabel {
    switch (totalNetWorth) {
      case AccountHolderTotalNetWorthDto.LessThan50K:
        return AccountHolderTotalNetWorthLabel.LessThan50K;
      case AccountHolderTotalNetWorthDto.Between50KAnd100K:
        return AccountHolderTotalNetWorthLabel.Between50KAnd100K;
      case AccountHolderTotalNetWorthDto.Between100KAnd200K:
        return AccountHolderTotalNetWorthLabel.Between100KAnd200K;
      case AccountHolderTotalNetWorthDto.Between200KAnd500K:
        return AccountHolderTotalNetWorthLabel.Between200KAnd500K;
      case AccountHolderTotalNetWorthDto.Between500KAnd1M:
        return AccountHolderTotalNetWorthLabel.Between500KAnd1M;
      case AccountHolderTotalNetWorthDto.Between1MAnd5M:
        return AccountHolderTotalNetWorthLabel.Between1MAnd5M;
      case AccountHolderTotalNetWorthDto.MoreThan5M:
        return AccountHolderTotalNetWorthLabel.MoreThan5M;
    }
  }

  private findLiquidNetWorthLabel(liquidNetWorth: AccountHolderLiquidNetWorthDto): AccountHolderLiquidNetWorthLabel {
    switch (liquidNetWorth) {
      case AccountHolderLiquidNetWorthDto.LessThan50K:
        return AccountHolderLiquidNetWorthLabel.LessThan50K;
      case AccountHolderLiquidNetWorthDto.Between50KAnd100K:
        return AccountHolderLiquidNetWorthLabel.Between50KAnd100K;
      case AccountHolderLiquidNetWorthDto.Between100KAnd200K:
        return AccountHolderLiquidNetWorthLabel.Between100KAnd200K;
      case AccountHolderLiquidNetWorthDto.Between200KAnd500K:
        return AccountHolderLiquidNetWorthLabel.Between200KAnd500K;
      case AccountHolderLiquidNetWorthDto.Between500KAnd1M:
        return AccountHolderLiquidNetWorthLabel.Between500KAnd1M;
      case AccountHolderLiquidNetWorthDto.Between1MAnd5M:
        return AccountHolderLiquidNetWorthLabel.Between1MAnd5M;
      case AccountHolderLiquidNetWorthDto.MoreThan5M:
        return AccountHolderLiquidNetWorthLabel.MoreThan5M;
    }
  }

  private findTaxBracketLabel(taxBracket: AccountHolderTaxBracketDto): AccountHolderTaxBracketLabel {
    switch (taxBracket) {
      case AccountHolderTaxBracketDto.Bottom:
        return AccountHolderTaxBracketLabel.Bottom;
      case AccountHolderTaxBracketDto.Middle:
        return AccountHolderTaxBracketLabel.Middle;
      case AccountHolderTaxBracketDto.Top:
        return AccountHolderTaxBracketLabel.Top;
    }
  }

  private findEmploymentStatusLabel(
    employmentStatus: AccountHolderEmploymentStatusDto,
  ): AccountHolderEmploymentStatusLabel {
    switch (employmentStatus) {
      case AccountHolderEmploymentStatusDto.Employed:
        return AccountHolderEmploymentStatusLabel.Employed;
      case AccountHolderEmploymentStatusDto.Retired:
        return AccountHolderEmploymentStatusLabel.Retired;
      case AccountHolderEmploymentStatusDto.Student:
        return AccountHolderEmploymentStatusLabel.Student;
      case AccountHolderEmploymentStatusDto.Unemployed:
        return AccountHolderEmploymentStatusLabel.Unemployed;
    }
  }

  private findYearsEmployedLabel(yearsEmployed: AccountHolderYearsEmployedDto): AccountHolderYearsEmployedLabel {
    switch (yearsEmployed) {
      case AccountHolderYearsEmployedDto.BetweenZeroAndOne:
        return AccountHolderYearsEmployedLabel.BetweenZeroAndOne;
      case AccountHolderYearsEmployedDto.BetweenTwoAndFive:
        return AccountHolderYearsEmployedLabel.BetweenTwoAndFive;
      case AccountHolderYearsEmployedDto.BetweenFiveAndTen:
        return AccountHolderYearsEmployedLabel.BetweenFiveAndTen;
      case AccountHolderYearsEmployedDto.BetweenTenAndTwenty:
        return AccountHolderYearsEmployedLabel.BetweenTenAndTwenty;
      case AccountHolderYearsEmployedDto.MoreThanTwenty:
        return AccountHolderYearsEmployedLabel.MoreThanTwenty;
    }
  }

  private findEmployerAddress(address: AccountHolderAddressDto): AccountHolderAddress {
    return new AccountHolderAddress(address);
  }

  get annualIncome(): Maybe<{
    value: AccountHolderAnnualIncomeDto;
    label: AccountHolderAnnualIncomeLabel;
    range: Range;
  }> {
    return this._annualIncomeValue && this._annualIncomeLabel
      ? {
          value: this._annualIncomeValue,
          label: this._annualIncomeLabel,
          range: this._annualIncomeBucketToRangeMapper[this._annualIncomeValue],
        }
      : undefined;
  }

  get totalNetWorth(): Maybe<{
    value: AccountHolderTotalNetWorthDto;
    label: AccountHolderTotalNetWorthLabel;
    range: Range;
  }> {
    return this._totalNetWorthValue && this._totalNetWorthLabel
      ? {
          value: this._totalNetWorthValue,
          label: this._totalNetWorthLabel,
          range: this._totalNetWorthBucketToRangeMapper[this._totalNetWorthValue],
        }
      : undefined;
  }

  get liquidNetWorth(): Maybe<{
    value: AccountHolderLiquidNetWorthDto;
    label: AccountHolderLiquidNetWorthLabel;
    range: Range;
  }> {
    return this._liquidNetWorthValue && this._liquidNetWorthLabel
      ? {
          value: this._liquidNetWorthValue,
          label: this._liquidNetWorthLabel,
          range: this._liquidNetWorthBucketToRangeMapper[this._liquidNetWorthValue],
        }
      : undefined;
  }

  get taxBracket(): Maybe<{ value: AccountHolderTaxBracketDto; label: AccountHolderTaxBracketLabel }> {
    return this._taxBracketValue && this._taxBracketLabel
      ? { value: this._taxBracketValue, label: this._taxBracketLabel }
      : undefined;
  }

  get employmentStatus(): Maybe<{
    value: AccountHolderEmploymentStatusDto;
    label: AccountHolderEmploymentStatusLabel;
  }> {
    return this._employmentStatusValue && this._employmentStatusLabel
      ? { value: this._employmentStatusValue, label: this._employmentStatusLabel }
      : undefined;
  }

  get employerName(): Maybe<string> {
    return this._employerName;
  }

  get jobTitle(): Maybe<string> {
    return this._jobTitle;
  }

  get yearsEmployed(): Maybe<{ value: AccountHolderYearsEmployedDto; label: AccountHolderYearsEmployedLabel }> {
    return this._yearsEmployedValue && this._yearsEmployedLabel
      ? { value: this._yearsEmployedValue, label: this._yearsEmployedLabel }
      : undefined;
  }

  get employerAddress(): Maybe<AccountHolderAddress> {
    return this._employerAddress;
  }
}

export enum AccountHolderStatementDeliveryLabel {
  Electronic = 'Electronic (Free)',
  Paper = 'Paper (Fees apply)',
}

export class AccountHolderDisclosures {
  private _value: Omit<AccountHolderDisclosureDto, 'statementDelivery'> & {
    statementDelivery: { value: AccountHolderStatementDeliveryDto; label: AccountHolderStatementDeliveryLabel };
  };

  constructor(dto: AccountHolderDisclosureDto) {
    this._value = {
      ...dto,
      statementDelivery: {
        value: dto.statementDelivery,
        label:
          dto.statementDelivery === AccountHolderStatementDeliveryDto.Electronic
            ? AccountHolderStatementDeliveryLabel.Electronic
            : AccountHolderStatementDeliveryLabel.Paper,
      },
    };
  }

  get statementDelivery(): { value: AccountHolderStatementDeliveryDto; label: AccountHolderStatementDeliveryLabel } {
    return this._value.statementDelivery;
  }

  get stakeholder(): false | { tickerSymbol: string } {
    return this._value.stakeholder;
  }

  get industryEmployed(): false | { firmName: string } {
    return this._value.industryEmployed;
  }
}

export interface AccountHolder {
  id: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  suffix?: string;
  dateOfBirth?: string;
  phones?: PhoneNumberDto[];
  email?: string;
  ssid?: string;
  maskedSsid?: string;
  maritalStatus?: AccountHolderMaritalStatus;
  numberOfDependents?: number;
  birthCountry?: { label: string; value: string };
  citizenshipCountry?: { label: string; value: string };
  physicalAddress?: AccountHolderAddress;
  mailingAddress?: AccountHolderAddress;
  suitabilityInformation?: AccountHolderSuitabilityInformation;
  trustedContact?: AccountHolderTrustedContact;
  financialInformation?: AccountHolderFinancialInformation;
  disclosure?: AccountHolderDisclosures;
  jointTenantsInCommonInterestPercent?: number;
}
