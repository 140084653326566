import { useState } from 'react';

import { Grid, Typography, Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { Combine, DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';

import { DragAndDropArea } from './DragAndDropArea';

export interface DragDropElementTree {
  id: string;
  name?: string;
  isSelected?: boolean;
  children: DragDropElementTree[];
}

const findParentField = (fields: DragDropElementTree[], id: string): DragDropElementTree | null => {
  let output: DragDropElementTree | null = null;

  fields.some((field: DragDropElementTree) => {
    if (!isEmpty(field.children)) {
      const anyChild = field.children?.find((child: DragDropElementTree) => child.id === id);

      if (anyChild) {
        output = field;

        return true;
      }
    }

    return false;
  });

  return output;
};

const findRootField = (fields: DragDropElementTree[], id: string): DragDropElementTree | null => {
  return fields.find((field: DragDropElementTree) => field.id === id) ?? null;
};

const findChildField = (fields: DragDropElementTree[], id: string): DragDropElementTree | null => {
  let result: DragDropElementTree | null = null;
  fields.some((field: DragDropElementTree) => {
    if (!isEmpty(field.children)) {
      const child = field.children?.find((child: DragDropElementTree) => child.id === id);

      if (child) {
        result = child;

        return true;
      }
    }

    return false;
  });

  return result;
};

const findSelectedField = (fields: DragDropElementTree[], id: string): DragDropElementTree | null => {
  let result: DragDropElementTree | null = null;

  for (const field of fields) {
    if (result) {
      break;
    }

    if (field.id === id) {
      result = field;
      break;
    }

    if (!isEmpty(field.children)) {
      const child = field.children.find(child => child.id === id);

      if (child) {
        result = child;
        break;
      }
    }
  }

  return result;
};

const selectAll = (fields: DragDropElementTree[], isChecked: boolean) => {
  fields.forEach(field => {
    field.isSelected = isChecked;
    field.children.forEach(child => {
      child.isSelected = isChecked;
    });
  });
};

interface FileExportSelectorProps {
  items: DragDropElementTree[];
  onChange: (items: DragDropElementTree[]) => void;
}

export const FileExportSelector = ({ items, onChange }: FileExportSelectorProps) => {
  const [areAllFieldsSelected, setAreAllFieldsSelected] = useState(false);

  const dragInField = (draggableId: string, combine: Combine) => {
    let fields = Array.from(items);
    // current root field
    let currentRootField = findRootField(fields, draggableId);
    // new parent
    let newRootField = findRootField(fields, combine.draggableId);

    if (!newRootField) return;

    if (currentRootField) {
      // NOTE: root field is being moved to another root field
      fields = fields.filter(item => item.id !== currentRootField?.id);

      fields = fields.map(item => {
        if (item.id === newRootField?.id) {
          return {
            id: uuid(),
            children: isEmpty(newRootField?.children)
              ? [{ ...newRootField, children: [] }, currentRootField]
              : [...newRootField?.children, currentRootField],
          };
        }

        return item;
      }) as any;

      onChange(fields);

      return;
    }

    // NOTE: child field is being moved to another root field
    const child = findChildField(fields, draggableId);
    const parent = findParentField(fields, draggableId);

    if (parent) {
      parent.children = parent.children.filter(item => item.id !== draggableId);
    }

    if (child) {
      newRootField.children.push(child);
    }

    onChange(fields);
  };

  const dragOutField = (draggableId: string) => {
    let fields = Array.from(items);
    const child = findChildField(fields, draggableId);
    let parent = findParentField(fields, draggableId);

    if (parent) {
      parent.children = parent.children.filter(item => item.id !== draggableId);

      if (parent.children.length === 1) {
        fields = fields.map(item => {
          if (item.id === parent?.id) {
            return {
              id: parent.children[0].id,
              name: parent.children[0].name,
              isSelected: parent.children[0].isSelected,
              children: [],
            };
          }

          return item;
        });
      }
    }

    if (child) {
      fields.push(child);
    }
    onChange(fields);
  };

  const moveField = (source: DraggableLocation, destination: DraggableLocation) => {
    let fields = Array.from(items);

    const isNestedSwap = source.droppableId.includes('parent.');

    const sourceDroppableId = isNestedSwap ? source.droppableId.split('parent.')[1] : source.droppableId;
    const sourceIndex = source.index;
    const destinationIndex = destination.index || 0;

    const sourceField = findSelectedField(fields, sourceDroppableId);

    if (isNestedSwap) {
      const sourceChildren = sourceField?.children || [];
      const [removed] = sourceChildren.splice(sourceIndex, 1);
      sourceChildren.splice(destinationIndex, 0, removed);
      onChange(fields);

      return;
    }

    const [removed] = fields.splice(sourceIndex, 1);
    fields.splice(destinationIndex, 0, removed);

    onChange(fields);
  };

  const onToggle = (itemId: string) => {
    const fields = Array.from(items);
    const item = findSelectedField(fields, itemId);
    if (!item) return;
    item.isSelected = !item?.isSelected;
    onChange(fields);
  };

  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination, combine, draggableId } = result;

    if (draggableId && combine) {
      dragInField(draggableId, combine);

      return;
    }

    if (source.droppableId === destination?.droppableId) {
      moveField(source, destination);

      return;
    }

    // Wnen the field is moved out of the group, then only the graggableId is returned
    // destination is null, combine is null
    // source = { draggableId}
    if (draggableId) {
      dragOutField(draggableId);
    }
  };

  const onCheckAll = () => {
    const fields = Array.from(items);
    selectAll(fields, !areAllFieldsSelected);
    setAreAllFieldsSelected(!areAllFieldsSelected);
    onChange(fields);
  };

  return (
    <Grid container>
      <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
        <Grid item xs='auto'>
          <Typography>Select the fields that you want to display on the exported file</Typography>
        </Grid>
        <Grid item xs='auto'>
          <Button onClick={onCheckAll}> {areAllFieldsSelected ? 'Uncheck All' : 'Check All'}</Button>
        </Grid>
      </Grid>
      <DragAndDropArea items={items} handleOnDragEnd={handleOnDragEnd} onChangeValue={onToggle} />
    </Grid>
  );
};
