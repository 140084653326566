import { useState } from 'react';

import { Cancel } from '@mui/icons-material';
import { Button, Confirm, useNotify, useRefresh, useUpdate } from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { OfferOversubscription } from 'src/models';

export const CancelOfferOversubscriptionButton = ({ record }: { record: OfferOversubscription }) => {
  const [isOpen, setIsOpen] = useState(false);

  const notify = useNotify();
  const refresh = useRefresh();
  const [cancelOfferOversubscription] = useUpdate<OfferOversubscription>();

  const onCancel = () => {
    cancelOfferOversubscription(
      DataProviderResource.CancelOfferOversubscription,
      {
        id: record.id,
        data: {},
      },
      {
        mutationMode: 'pessimistic',
        onSuccess: () => {
          notify(`Offer oversubscription was successfully canceled.`, {
            type: 'success',
            undoable: true,
          });
          onClose();
        },
        onSettled: () => {
          refresh();
        },
        onError: (error: any) => {
          notify(`${JSON.stringify(error?.message ?? error)}`, {
            type: 'error',
          });
        },
      },
    );
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onConfirm = () => {
    onCancel();
  };

  return (
    <>
      <Button
        label='Cancel'
        onClick={() => setIsOpen(true)}
        startIcon={<Cancel />}
        disabled={!record.status.isUnderReview}
        sx={{
          color: 'rgba(211, 47, 47)',
          '&:hover': {
            backgroundColor: 'rgba(211, 47, 47, 0.12)',
          },
        }}
      />
      <Confirm
        isOpen={isOpen}
        title='Cancel Offer Oversubscription'
        content={'Are you sure you want to cancel offer oversubscription?'}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </>
  );
};
