import { isUndefined } from 'lodash';
import { FunctionField, Labeled, TextField, useRecordContext } from 'react-admin';
import { Row, Col } from 'react-grid-system';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Account } from 'src/models';

export const DisclosureShow = () => {
  const record = useRecordContext<Account>();

  return (
    <>
      <div className='showContent'>
        <Row>
          <Col sm={12}>
            <h2 className='title'>{record.type.isJoint ? 'Primary Account Holder' : 'Disclosure Information'}</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Labeled>
              <TextField source='primaryAccountHolder.disclosure.statementDelivery.label' label='Statement Delivery' />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Labeled>
              <FunctionField
                render={(record?: Account) => {
                  const industryEmployed = record?.primaryAccountHolder?.disclosure?.industryEmployed;

                  if (!isUndefined(industryEmployed) && industryEmployed === false) {
                    return 'False';
                  }

                  return industryEmployed?.firmName;
                }}
                label='Industry Employed Firm Name'
              />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Labeled>
              <FunctionField
                render={(record?: Account) => {
                  const stakeholder = record?.primaryAccountHolder?.disclosure?.stakeholder;

                  if (!isUndefined(stakeholder) && stakeholder === false) {
                    return 'False';
                  }

                  return stakeholder?.tickerSymbol;
                }}
                label='Stakeholder'
              />
            </Labeled>
          </Col>
        </Row>
      </div>
      {record.type.isJoint && (
        <div className='showContent'>
          <Row>
            <Col sm={12}>
              <h2 className='title'>Joint Account Holder</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Labeled>
                <FunctionField
                  render={(record?: Account) => {
                    const industryEmployed = record?.secondaryAccountHolder?.disclosure?.industryEmployed;

                    if (!isUndefined(industryEmployed) && industryEmployed === false) {
                      return 'False';
                    }

                    return industryEmployed?.firmName;
                  }}
                  label='Industry Employed Firm Name'
                />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Labeled>
                <FunctionField
                  render={(record?: Account) => {
                    const stakeholder = record?.secondaryAccountHolder?.disclosure?.stakeholder;

                    if (!isUndefined(stakeholder) && stakeholder === false) {
                      return 'False';
                    }

                    return stakeholder?.tickerSymbol;
                  }}
                  label='Stakeholder'
                />
              </Labeled>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
