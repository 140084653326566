import { isEmpty } from 'lodash';
import { FilterButton, TopToolbar, useListContext } from 'react-admin';
import { User } from 'src/models';

import { SyncAccountsStatusButton } from '../Accounts/SyncAcountsStatusButton/SyncAccountsStatusButton';

export const UserListActions = () => {
  const { data } = useListContext<User>();

  const accountIds = data?.reduce((acc: string[], aUser) => {
    const ids = aUser.accounts.filter(anAccount => Boolean(anAccount.accountId)).map(anAccount => anAccount.id);

    if (!isEmpty(ids)) {
      acc = acc.concat(ids);
    }

    return acc;
  }, []);

  return (
    <TopToolbar>
      <FilterButton />
      <SyncAccountsStatusButton ids={accountIds} />
    </TopToolbar>
  );
};
