import { BaseListResponseDto, IdParamDto, PaginationQueryParams } from './common.dtos';

export enum FileExportTypeDto {
  OfferOrder = 'OfferOrder',
}

export enum FileExportStatusDto {
  Error = 'Error',
  Canceled = 'Canceled',
  Completed = 'Completed',
  PendingBalance = 'PendingBalance',
  Pending = 'Pending',
}

export interface FileExportDto {
  id: string;
  name: string;
  type: FileExportTypeDto;
  status: FileExportStatusDto;
  offer?: {
    id: string;
    name: string;
  };
  error?: string;
  externalId?: string;
  createdBy: string;
  createdAt: string;
  updatedAt?: string;
}

export interface GetFileExportListQueryParamsDto extends PaginationQueryParams {
  name?: string;
  status?: FileExportStatusDto;
  offerId?: string;
}

export interface GetFileExportListDto {
  queryParams: GetFileExportListQueryParamsDto;
}

export interface GetFileExportListResponseDto extends BaseListResponseDto<FileExportDto[]> {}

export interface GetFileExportDto {
  params: IdParamDto;
}

export interface DownloadFileExportDto {
  params: IdParamDto;
}

export interface DownloadFileExportResponseDto {
  url: string;
}

export interface CancelFileExportDto {
  params: IdParamDto;
}

export interface DeleteFileExportDto {
  params: IdParamDto;
}
