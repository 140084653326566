import { BaseListResponseDto, IdParamDto, PaginationQueryParams } from './common.dtos';
import { OfferOrderStatusDto } from './offerOrders.dtos';

export enum OfferOversubscriptionTypeDto {
  Percentage = 'Percentage',
}

export enum OfferOversubscriptionStatusDto {
  Pending = 'Pending',
  UnderReview = 'UnderReview',
  Canceled = 'Canceled',
  Applying = 'Applying',
  Error = 'Error',
  Completed = 'Completed',
}

export interface OfferOversubscriptionDto {
  id: string;
  type: OfferOversubscriptionTypeDto;
  status: OfferOversubscriptionStatusDto;
  offer: {
    id: string;
    name: string;
  };
  percentage: number;
  receivedFunds?: number;
  oversubscribedFunds?: number;
  error?: string;
  createdAt: string;
  updatedAt?: string;
}

export interface CreateOfferOversubscriptionBodyDto {
  type: OfferOversubscriptionTypeDto;
  offerId: string;
  percentage: number;
}

export interface CreateOfferOversubscriptionDto {
  body: CreateOfferOversubscriptionBodyDto;
}

export interface GetOfferOversubscriptionListQueryParamsDto extends PaginationQueryParams {}

export interface GetOfferOversubscriptionListDto {
  queryParams: GetOfferOversubscriptionListQueryParamsDto;
}

export interface GetOfferOversubscriptionListResponseDto extends BaseListResponseDto<OfferOversubscriptionDto[]> {}

export interface GetOfferOversubscriptionDto {
  params: IdParamDto;
}

export interface ApplyOfferOversubscriptionDto {
  params: IdParamDto;
}

export interface CancelOfferOversusbscriptonDto {
  params: IdParamDto;
}

// Orders
export interface OfferOversubscriptionDraftOrderDto {
  id: string;
  quantity: number;
  totalCost: number;
  actual: {
    id: string;
    quantity: number;
    totalCost: number;
    accountId: number;
    price: number;
    status: OfferOrderStatusDto;
    createdAt: string;
  };
  previous?: {
    quantity: number;
    totalCost: number;
    price: number;
  };
  oversubscription: {
    id: string;
    status: OfferOversubscriptionStatusDto;
  };
  offer: {
    id: string;
    name: string;
  };
  createdAt: string;
  updatedAt?: string;
}

export interface BaseOversubscriptionDraftOrdersParamsDto extends IdParamDto {
  orderId: string;
}

export interface GetOfferOversubscriptionDraftOrderListQueryParamsDto extends PaginationQueryParams {}

export interface GetOfferOversubscriptionDraftOrderListDto {
  params: IdParamDto;
  queryParams: GetOfferOversubscriptionDraftOrderListQueryParamsDto;
}

export interface GetOfferOversubscriptionDraftOrderListResponseDto
  extends BaseListResponseDto<OfferOversubscriptionDraftOrderDto[]> {}

export interface GetOfferOversubscriptionDraftOrderDto {
  params: BaseOversubscriptionDraftOrdersParamsDto;
}

export interface UpdateOfferOversubscriptionDraftOrderBodyDto {
  quantity: number;
}

export interface UpdateOfferOversubscriptionDraftOrderDto {
  params: BaseOversubscriptionDraftOrdersParamsDto;
  body: UpdateOfferOversubscriptionDraftOrderBodyDto;
}
