import { CircularProgress } from '@mui/material';
import { useGetList, useRecordContext } from 'react-admin';
import { Col, Row } from 'react-grid-system';
import StatusField from 'src/components/Fields/StatusField';
import { AccountHolderUpdateRequestDto } from 'src/dtos';
import { Account } from 'src/models';

import { UpdateRequestActions } from './UpdateRequestActions';

interface AccountHolderUpdateRequestListProps {
  accountHolderId: string;
  isPrimary: boolean;
}

export const AccountHolderUpdateRequestList = ({ accountHolderId, isPrimary }: AccountHolderUpdateRequestListProps) => {
  const record = useRecordContext<Account>();
  const { id } = record;

  const { data, isLoading, error } = useGetList<AccountHolderUpdateRequestDto>('update-requests', {
    filter: { accountId: id, accountHolderId },
  });

  if (isLoading)
    return (
      <div className='alignAllCenter'>
        <CircularProgress />
      </div>
    );

  if (error) return <div>Unknown Error Occurred(error:{error.message})</div>;

  return (
    <div className='transactionsContent'>
      <Row>
        <Col sm={12}>
          <h2 className='title'>
            {record.type.isJoint
              ? isPrimary
                ? 'Primary Account Holder Update Requests'
                : 'Joint Account Holder Update Requests'
              : 'Account Holder Update Requests'}
          </h2>
        </Col>
      </Row>
      {data?.length === 0 && <>No update request was found</>}
      {data?.map(element => (
        <Row key={element.id} className='updateRequestItem'>
          <Col sm={12}>
            <Row>
              <Col sm={12} className='sectionTitle'>
                {element.type}
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <div>
                  <span className='description'>Original value: </span>
                  <span className='keyOriginalValue'>{JSON.stringify(element.original)}</span>
                </div>

                <div>
                  <span className='description'>Updated value: </span>
                  <span className='keyNewValue'>{JSON.stringify(element.updated)}</span>
                </div>
              </Col>
              <Col sm={6}>
                <Row>
                  <Col md={12} className={'alignAllCenter'}>
                    <StatusField
                      status={element.status}
                      success={['Complete', 'Approved']}
                      warning={['PendingConfirm', 'PendingFirmReview']}
                      fail={'Rejected'}
                    />
                  </Col>
                  <Col md={12} style={{ paddingTop: 20 }}>
                    <UpdateRequestActions
                      accountId={id}
                      accountHolderId={accountHolderId}
                      id={element.id}
                      status={element.status}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </div>
  );
};
