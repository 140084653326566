import { CardActions } from '@mui/material';
import {
  ListButton,
  Show,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  useRecordContext,
  useShowController,
} from 'react-admin';
import { Account } from 'src/models';

import { AccountInformationShow } from './AccountInformation/AccountInformationShow';
import { DisclosureShow } from './Disclosure/DisclosureShow';
import { FinancialInformationShow } from './FinancialInformation/FinancialInformationShow';
import { HistoryShow } from './History/HistoryShow';
import { InvestigationShow } from './Investigation/InvestigationShow';
import { MailingAddressShow } from './MailingAddress/MailingAddressShow';
import { PhysicalAddressShow } from './PhysicalAddress/PhysicalAddressShow';
import { SuitabilityInformationShow } from './SuitabilityInformation/SuitabilityInformationShow';
import { SummaryShow } from './Summary/SummaryShow';
import { TrustedContactShow } from './TrustedContact/TrustedContactShow';
import { UpdateRequestsShow } from './UpdateRequests/UpdateRequestsShow';

const Title = () => {
  const record = useRecordContext<Account>();

  return (
    <span>
      Account: <strong>{record.id}</strong>
      {record.accountId && `, ${record.accountId}`}
      {`, ${record.type.label}`}
    </span>
  );
};

const Actions = () => {
  return (
    <CardActions>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'flex-start',
        }}>
        <ListButton resource='users' />
      </div>
    </CardActions>
  );
};

export const AccountShow = () => {
  const { record } = useShowController<Account>();

  return (
    <Show emptyWhileLoading title={<Title />} actions={<Actions />}>
      <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant='scrollable' />}>
        <Tab label='Account' sx={{ overflow: 'auto' }}>
          <AccountInformationShow />
        </Tab>
        {record?.accountId && (
          <Tab label='Investigations'>
            <InvestigationShow />
          </Tab>
        )}
        {(record?.primaryAccountHolder?.physicalAddress || record?.secondaryAccountHolder?.physicalAddress) && (
          <Tab label='Physical Address'>
            <PhysicalAddressShow />
          </Tab>
        )}
        {(record?.primaryAccountHolder?.mailingAddress || record?.secondaryAccountHolder?.mailingAddress) && (
          <Tab label='Mailing Address'>
            <MailingAddressShow />
          </Tab>
        )}
        {(record?.primaryAccountHolder?.trustedContact || record?.jointAccountType?.isTenantsInCommon) && (
          <Tab label='Users/Trusted Contact'>
            <TrustedContactShow />
          </Tab>
        )}
        {record?.primaryAccountHolder?.suitabilityInformation && (
          <Tab label='Suitability'>
            <SuitabilityInformationShow />
          </Tab>
        )}
        {(record?.primaryAccountHolder?.financialInformation ||
          record?.secondaryAccountHolder?.financialInformation) && (
          <Tab label='Financial'>
            <FinancialInformationShow />
          </Tab>
        )}
        {(record?.primaryAccountHolder?.disclosure || record?.secondaryAccountHolder?.disclosure) && (
          <Tab label='Disclosures'>
            <DisclosureShow />
          </Tab>
        )}
        {record?.accountId && (
          <Tab label='Summary'>
            <SummaryShow accountId={record.accountId} />
          </Tab>
        )}
        {record?.accountId && (
          <Tab label='History'>
            <HistoryShow accountId={record?.accountId} accountNumber={record?.accountNumber} />
          </Tab>
        )}
        {record?.accountId && (
          <Tab label='Update Requests'>
            <UpdateRequestsShow />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};
