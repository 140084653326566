import { OfferOrderFileExportColumnLabelDto, OfferOrderFileExportColumnTypeDto } from 'src/dtos';
import { DragDropElementTree } from 'src/lib';

export const modelFields: DragDropElementTree[] = [
  {
    id: OfferOrderFileExportColumnTypeDto.Id,
    name: OfferOrderFileExportColumnLabelDto.Id,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.PrimaryAccountHolderFirstName,
    name: OfferOrderFileExportColumnLabelDto.PrimaryAccountHolderFirstName,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.PrimaryAccountHolderMiddleName,
    name: OfferOrderFileExportColumnLabelDto.PrimaryAccountHolderMiddleName,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.PrimaryAccountHolderLastName,
    name: OfferOrderFileExportColumnLabelDto.PrimaryAccountHolderLastName,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.PrimaryAccountHolderSuffix,
    name: OfferOrderFileExportColumnLabelDto.PrimaryAccountHolderSuffix,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.PrimaryAccountHolderEmail,
    name: OfferOrderFileExportColumnLabelDto.PrimaryAccountHolderEmail,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.PrimaryAccountHolderPhoneNumber,
    name: OfferOrderFileExportColumnLabelDto.PrimaryAccountHolderPhoneNumber,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.PrimaryAccountHolderSsid,
    name: OfferOrderFileExportColumnLabelDto.PrimaryAccountHolderSsid,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.PrimaryAccountHolderAddressLine1,
    name: OfferOrderFileExportColumnLabelDto.PrimaryAccountHolderAddressLine1,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.PrimaryAccountHolderAddressLine2,
    name: OfferOrderFileExportColumnLabelDto.PrimaryAccountHolderAddressLine2,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.PrimaryAccountHolderAddressCity,
    name: OfferOrderFileExportColumnLabelDto.PrimaryAccountHolderAddressCity,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.PrimaryAccountHolderAddressState,
    name: OfferOrderFileExportColumnLabelDto.PrimaryAccountHolderAddressState,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.PrimaryAccountHolderAddressPostalCode,
    name: OfferOrderFileExportColumnLabelDto.PrimaryAccountHolderAddressPostalCode,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.SecondaryAccountHolderFirstName,
    name: OfferOrderFileExportColumnLabelDto.SecondaryAccountHolderFirstName,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.SecondaryAccountHolderMiddleName,
    name: OfferOrderFileExportColumnLabelDto.SecondaryAccountHolderMiddleName,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.SecondaryAccountHolderLastName,
    name: OfferOrderFileExportColumnLabelDto.SecondaryAccountHolderLastName,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.SecondaryAccountHolderSuffix,
    name: OfferOrderFileExportColumnLabelDto.SecondaryAccountHolderSuffix,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.SecondaryAccountHolderSsid,
    name: OfferOrderFileExportColumnLabelDto.SecondaryAccountHolderSsid,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.Quantity,
    name: OfferOrderFileExportColumnLabelDto.Quantity,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.Price,
    name: OfferOrderFileExportColumnLabelDto.Price,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.TotalCost,
    name: OfferOrderFileExportColumnLabelDto.TotalCost,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.CreatedAt,
    name: OfferOrderFileExportColumnLabelDto.CreatedAt,
    isSelected: true,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.OfferName,
    name: OfferOrderFileExportColumnLabelDto.OfferName,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.AccountNumber,
    name: OfferOrderFileExportColumnLabelDto.AccountNumber,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.CashBalance,
    name: OfferOrderFileExportColumnLabelDto.CashBalance,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.CashAvailable,
    name: OfferOrderFileExportColumnLabelDto.CashAvailable,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.IsAccreditedInvestor,
    name: OfferOrderFileExportColumnLabelDto.IsAccreditedInvestor,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.SecurityDeliveryOption,
    name: OfferOrderFileExportColumnLabelDto.SecurityDeliveryOption,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.PaymentType,
    name: OfferOrderFileExportColumnLabelDto.PaymentType,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.Status,
    name: OfferOrderFileExportColumnLabelDto.Status,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.OfferType,
    name: OfferOrderFileExportColumnLabelDto.OfferType,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.OfferStatus,
    name: OfferOrderFileExportColumnLabelDto.OfferStatus,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.OfferIndustry,
    name: OfferOrderFileExportColumnLabelDto.OfferIndustry,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.OfferSector,
    name: OfferOrderFileExportColumnLabelDto.OfferSector,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.OfferCategory,
    name: OfferOrderFileExportColumnLabelDto.OfferCategory,
    isSelected: false,
    children: [],
  },
  {
    id: OfferOrderFileExportColumnTypeDto.OfferSecurityType,
    name: OfferOrderFileExportColumnLabelDto.OfferSecurityType,
    isSelected: false,
    children: [],
  },
];
