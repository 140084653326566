import { FileExportUrlConstant } from 'src/constants';
import {
  FileExportDto,
  GetFileExportDto,
  CancelFileExportDto,
  DownloadFileExportDto,
  DeleteFileExportDto,
  GetFileExportListDto,
  DownloadFileExportResponseDto,
  GetFileExportListResponseDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class FileExportApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async list({ queryParams }: GetFileExportListDto): Promise<GetFileExportListResponseDto> {
    const url = FileExportUrlConstant.LIST;

    return httpRequest<GetFileExportListResponseDto>({
      method: 'GET',
      url,
      queryParams,
      authToken: this._configService.authToken,
    });
  }

  async retrieve({ params }: GetFileExportDto): Promise<FileExportDto> {
    const url = replacePlaceholders(FileExportUrlConstant.RETRIEVE, params);

    return httpRequest<FileExportDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async cancel({ params }: CancelFileExportDto): Promise<FileExportDto> {
    const url = replacePlaceholders(FileExportUrlConstant.CANCEL, params);

    return httpRequest<FileExportDto>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  async download({ params }: DownloadFileExportDto): Promise<Blob> {
    const url = replacePlaceholders(FileExportUrlConstant.DOWNLOAD, params);

    const preLinkResponse = await httpRequest<DownloadFileExportResponseDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });

    return await httpRequest<Blob>({
      method: 'GET',
      url: preLinkResponse.url,
      responseType: 'blob',
    });
  }

  async delete({ params }: DeleteFileExportDto): Promise<void> {
    const url = replacePlaceholders(FileExportUrlConstant.DELETE, params);

    return httpRequest<void>({
      method: 'DELETE',
      url,
      authToken: this._configService.authToken,
    });
  }
}
