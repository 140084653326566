import { OfferOversubscriptionUrlConstant } from 'src/constants';
import {
  GetOfferOversubscriptionListDto,
  GetOfferOversubscriptionListResponseDto,
  GetOfferOversubscriptionDto,
  OfferOversubscriptionDto,
  CancelOfferOversusbscriptonDto,
  ApplyOfferOversubscriptionDto,
  OfferOversubscriptionDraftOrderDto,
  GetOfferOversubscriptionDraftOrderDto,
  GetOfferOversubscriptionDraftOrderListDto,
  GetOfferOversubscriptionDraftOrderListResponseDto,
  CreateOfferOversubscriptionDto,
  UpdateOfferOversubscriptionDraftOrderDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class OfferOversubscriptionApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async create({ body }: CreateOfferOversubscriptionDto): Promise<OfferOversubscriptionDto> {
    const url = OfferOversubscriptionUrlConstant.CREATE;

    return httpRequest<OfferOversubscriptionDto>({
      method: 'POST',
      url,
      authToken: this._configService.authToken,
      body,
    });
  }

  async list({ queryParams }: GetOfferOversubscriptionListDto): Promise<GetOfferOversubscriptionListResponseDto> {
    const url = OfferOversubscriptionUrlConstant.LIST;

    return httpRequest<GetOfferOversubscriptionListResponseDto>({
      method: 'GET',
      url,
      queryParams,
      authToken: this._configService.authToken,
    });
  }

  async retrieve({ params }: GetOfferOversubscriptionDto): Promise<OfferOversubscriptionDto> {
    const url = replacePlaceholders(OfferOversubscriptionUrlConstant.RETRIEVE, params);

    return httpRequest<OfferOversubscriptionDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async cancel({ params }: CancelOfferOversusbscriptonDto): Promise<OfferOversubscriptionDto> {
    const url = replacePlaceholders(OfferOversubscriptionUrlConstant.CANCEL, params);

    return httpRequest<OfferOversubscriptionDto>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  async apply({ params }: ApplyOfferOversubscriptionDto): Promise<OfferOversubscriptionDto> {
    const url = replacePlaceholders(OfferOversubscriptionUrlConstant.APPLY, params);

    return await httpRequest<OfferOversubscriptionDto>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  async listDraftOrders({
    params,
    queryParams,
  }: GetOfferOversubscriptionDraftOrderListDto): Promise<GetOfferOversubscriptionDraftOrderListResponseDto> {
    const url = replacePlaceholders(OfferOversubscriptionUrlConstant.LIST_DRAFT_ORDERS, params);

    return httpRequest<GetOfferOversubscriptionDraftOrderListResponseDto>({
      method: 'GET',
      url,
      queryParams,
      authToken: this._configService.authToken,
    });
  }

  async retrieveDraftOrder({
    params,
  }: GetOfferOversubscriptionDraftOrderDto): Promise<OfferOversubscriptionDraftOrderDto> {
    const url = replacePlaceholders(OfferOversubscriptionUrlConstant.RETRIEVE_DRAFT_ORDER, params);

    return httpRequest<OfferOversubscriptionDraftOrderDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async updateDraftorder({
    params,
    body,
  }: UpdateOfferOversubscriptionDraftOrderDto): Promise<OfferOversubscriptionDraftOrderDto> {
    const url = replacePlaceholders(OfferOversubscriptionUrlConstant.UPDATE_DRAFT_ORDER, params);

    return httpRequest<OfferOversubscriptionDraftOrderDto>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
      body,
    });
  }
}
