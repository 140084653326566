import {
  CreateParams,
  CreateResult,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { getClientApi } from 'src/data-communication';
import { CreateOfferOversubscriptionBodyDto } from 'src/dtos';
import { mapOfferOversubscriptionDraftOrderDtoToModel, mapOfferOversubscriptionDtoToModel } from 'src/mappers';
import { OfferOversubscription, OfferOversubscriptionDraftOrder } from 'src/models';

const clientApi = getClientApi();

export const createOfferOversubscription = async (
  params: CreateParams<CreateOfferOversubscriptionBodyDto>,
): Promise<CreateResult<OfferOversubscription>> => {
  const result = await clientApi.offerOversubscription.create({
    body: params.data,
  });

  return {
    data: mapOfferOversubscriptionDtoToModel(result),
  };
};

export const getOfferOversubscriptionList = async (
  params: GetListParams,
): Promise<GetListResult<OfferOversubscription>> => {
  const result = await clientApi.offerOversubscription.list({
    queryParams: {
      skip: params.pagination.perPage * (params.pagination.page - 1),
      take: params.pagination.perPage,
    },
  });

  return {
    data: result.data.map(mapOfferOversubscriptionDtoToModel),
    total: result.total,
  };
};

export const getOfferOversubscription = async (params: GetOneParams): Promise<GetOneResult<OfferOversubscription>> => {
  const result = await clientApi.offerOversubscription.retrieve({
    params,
  });

  return { data: mapOfferOversubscriptionDtoToModel(result) };
};

export const applyOfferOversubscription = async (
  params: UpdateParams,
): Promise<UpdateResult<OfferOversubscription>> => {
  const result = await clientApi.offerOversubscription.apply({
    params: {
      id: params.id.toString(),
    },
  });

  return { data: mapOfferOversubscriptionDtoToModel(result) };
};

export const cancelOfferOversubscription = async (
  params: UpdateParams,
): Promise<UpdateResult<OfferOversubscription>> => {
  const result = await clientApi.offerOversubscription.cancel({
    params: {
      id: params.id.toString(),
    },
  });

  return { data: mapOfferOversubscriptionDtoToModel(result) };
};

export const getOfferOversubscriptionDraftOrderList = async (
  params: GetListParams,
): Promise<GetListResult<OfferOversubscriptionDraftOrder>> => {
  const result = await clientApi.offerOversubscription.listDraftOrders({
    params: {
      id: params.filter?.id,
    },
    queryParams: {
      skip: params.pagination.perPage * (params.pagination.page - 1),
      take: params.pagination.perPage,
    },
  });

  return {
    data: result.data.map(mapOfferOversubscriptionDraftOrderDtoToModel),
    total: result.total,
  };
};

export const getOfferOversubscriptionDraftOrder = async (
  params: GetOneParams,
): Promise<GetOneResult<OfferOversubscriptionDraftOrder>> => {
  const result = await clientApi.offerOversubscription.retrieveDraftOrder({
    params: {
      id: params.id,
      orderId: params.meta.orderId,
    },
  });

  return {
    data: mapOfferOversubscriptionDraftOrderDtoToModel(result),
  };
};

export const updateOfferOversubscriptionDraftOrder = async (
  params: UpdateParams,
): Promise<UpdateResult<OfferOversubscriptionDraftOrder>> => {
  const result = await clientApi.offerOversubscription.updateDraftorder({
    params: {
      id: params.id.toString(),
      orderId: params.meta.orderId,
    },
    body: {
      quantity: params.data.quantity,
    },
  });

  return {
    data: mapOfferOversubscriptionDraftOrderDtoToModel(result),
  };
};
