import { includes } from 'lodash';
import { FunctionField, Labeled, TextField, useRecordContext } from 'react-admin';
import { Row, Col } from 'react-grid-system';
import { RetirementAccountTypeDto } from 'src/dtos';
import { StatusTag } from 'src/lib';
import { Account } from 'src/models';
import { getAccountStatusTagPalette } from 'src/utils';

import { SyncAccountsStatusButton } from '../SyncAcountsStatusButton/SyncAccountsStatusButton';

export const AccountDetails = () => {
  const record = useRecordContext<Account>();
  const { id } = record;

  if (!record.accountId) {
    return null;
  }

  return (
    <div className='showContent'>
      <Row>
        <Col sm={12}>
          <h2 className='title'>Account Information</h2>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Labeled>
            <TextField source='accountId' label='Account Id' />
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled>
            <TextField source='accountNumber' label='Account Number' />
          </Labeled>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled>
            <TextField source='type.label' label='Account Type' />
          </Labeled>
        </Col>
        {record.type.isJoint && (
          <Col md={6}>
            <Labeled>
              <TextField source='jointAccountType.label' label='Joint Account Type' />
            </Labeled>
          </Col>
        )}
        {record.type.isRetirement && (
          <Col md={6}>
            <Labeled>
              <TextField source='retirementAccountType.label' label='Retirement Account Type' />
            </Labeled>
          </Col>
        )}
      </Row>
      {record.type.isJoint && record.jointAccountType?.isCommunity && (
        <Row>
          <Col md={6}>
            <Labeled>
              <TextField source='jointCommunityPropertyState.label' label='Community Property State' />
            </Labeled>
          </Col>
        </Row>
      )}
      {record.type.isRetirement && record.retirementAccountType?.isSimple && (
        <Row>
          <Col md={6}>
            <Labeled>
              <TextField source='simpleIraPlanName' label='Simple IRA Plan Name' />
            </Labeled>
          </Col>
        </Row>
      )}
      {record.type.isRetirement &&
        includes(
          [RetirementAccountTypeDto.Beneficiary, RetirementAccountTypeDto.RothBeneficiary],
          record.retirementAccountType?.value,
        ) && (
          <Row>
            <Col md={6}>
              <Labeled>
                <TextField source='beneficiaryIraDecedentName' label='Beneficiary Ira Decedent Name' />
              </Labeled>
            </Col>
          </Row>
        )}
      <Row>
        <Col md={6}>
          <Labeled>
            <FunctionField
              label='Account Status (My IPO Server)'
              render={(account?: Account) => {
                return (
                  <>
                    <StatusTag
                      value={account?.status?.label ?? 'Not Submitted'}
                      style={getAccountStatusTagPalette(account?.status?.value ?? 'NotSubmitted')}
                    />
                    {Boolean(account?.accountId) && <SyncAccountsStatusButton ids={[id]} label={'Account Status'} />}
                  </>
                );
              }}
            />
          </Labeled>
        </Col>
      </Row>
    </div>
  );
};
