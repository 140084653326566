import { FileExportStatusDto, FileExportTypeDto } from 'src/dtos';

export interface DownloadFileExportResponse {
  id: string;
  file: { url: string; type: 'csv' };
}

export enum FileExportTypeLabel {
  OfferOrder = 'Offer Order',
}

export class FileExportType {
  private _value: FileExportTypeDto;
  private _label: FileExportTypeLabel;

  constructor(type: FileExportTypeDto) {
    this._value = type;
    this._label = this.findLabel(type);
  }

  private findLabel(type: FileExportTypeDto): FileExportTypeLabel {
    switch (type) {
      default:
        return FileExportTypeLabel.OfferOrder;
    }
  }

  get value(): FileExportTypeDto {
    return this._value;
  }

  get label(): FileExportTypeLabel {
    return this._label;
  }

  get isOfferOrder(): boolean {
    return this._value === FileExportTypeDto.OfferOrder;
  }
}

export enum FileExportStatusLabel {
  Error = 'Error',
  Canceled = 'Canceled',
  Completed = 'Completed',
  PendingBalance = 'Pending Balance',
  Pending = 'Pending',
}

export class FileExportStatus {
  private _value: FileExportStatusDto;
  private _label: FileExportStatusLabel;

  constructor(type: FileExportStatusDto) {
    this._value = type;
    this._label = this.findLabel(type);
  }

  private findLabel(type: FileExportStatusDto): FileExportStatusLabel {
    switch (type) {
      case FileExportStatusDto.Error:
        return FileExportStatusLabel.Error;
      case FileExportStatusDto.Canceled:
        return FileExportStatusLabel.Canceled;
      case FileExportStatusDto.Completed:
        return FileExportStatusLabel.Completed;
      case FileExportStatusDto.Pending:
        return FileExportStatusLabel.Pending;
      case FileExportStatusDto.PendingBalance:
        return FileExportStatusLabel.PendingBalance;
    }
  }

  get value(): FileExportStatusDto {
    return this._value;
  }

  get label(): FileExportStatusLabel {
    return this._label;
  }

  get isPendingBalance(): boolean {
    return this._value === FileExportStatusDto.PendingBalance;
  }

  get isPending(): boolean {
    return this._value === FileExportStatusDto.Pending;
  }

  get isCompleted(): boolean {
    return this._value === FileExportStatusDto.Completed;
  }
}

export interface FileExport {
  id: string;
  name: string;
  type: FileExportType;
  status: FileExportStatus;
  offer?: {
    id: string;
    name: string;
  };
  error?: string;
  externalId?: string;
  createdBy: string;
  createdAt: string;
  updatedAt?: string;
}
