import React, { useEffect, useState } from 'react';

import { CheckCircle, Cancel, Visibility } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Confirm, useDelete, useNotify, useRefresh, useUpdate } from 'react-admin';
import {
  TradingBlockApplicationUploadedDocumentDto,
  TradingBlockInvestigationUploadedDocumentDto,
  TradingBlockUploadedDocumentStateDto,
} from 'src/dtos';

import Colors from '../../../../constants/colors';
import Styles from '../../../../constants/styles';

export interface DocumentActionsDropdownProps {
  accountId?: number;
  element: (TradingBlockInvestigationUploadedDocumentDto | TradingBlockApplicationUploadedDocumentDto) & {
    investigationType: 'application' | 'investigation';
  };
  anchorElement: HTMLElement | null;
  onClose: () => void;
}

const DocumentActionsDropdown = ({
  element,
  accountId,
  anchorElement = null,
  onClose,
}: DocumentActionsDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(anchorElement);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [activeType, setActiveType] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const notify = useNotify();
  const refresh = useRefresh();
  const [update] = useUpdate();
  const [del] = useDelete();

  useEffect(() => {
    setAnchorEl(anchorElement);
    setIsOpen(true);
  }, [anchorElement]);

  useEffect(() => {
    setActiveType(element.investigationType);
  }, [element]);

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
    onClose();
  };

  const approveHandle = () => {
    setConfirmOpen(true);
    handleClose();
    setActiveItem('update_' + activeType + '-document');
  };

  const deleteHandle = () => {
    setConfirmOpen(true);
    handleClose();
    setActiveItem('delete_' + activeType + '-document');
  };

  //NOTE: Must ask client if he really doesn't need this
  const viewHandle = () => {
    handleClose();
  };

  const confirmAction = () => {
    switch (activeItem) {
      case 'update_investigation-document':
        actionEntity('investigation-document', { miscDocumentId: element.miscDocumentId, accountId }, 'update');
        break;
      case 'delete_investigation-document':
        actionEntity(
          'investigation-document',
          { meta: { miscDocumentId: element.miscDocumentId, accountId }, id: accountId },
          'delete',
        );
        break;
      case 'update_application-document':
        actionEntity('application-document', { miscDocumentId: element.miscDocumentId, accountId }, 'update');
        break;
      case 'delete_application-document':
        actionEntity(
          'application-document',
          { meta: { miscDocumentId: element.miscDocumentId, accountId }, id: accountId },
          'delete',
        );
        break;

      default:
        break;
    }
  };

  const actionEntity = (actionType: string, data: any, method = 'update') => {
    setBtnDisabled(true);
    const payload: any = {
      onError: (error: any) => {
        notify(error.message ?? 'An uncaught error occurred!', { type: 'error' });
        handleClose();
      },
      onSuccess: () => {
        handleClose();
        getNotificationMessage(actionType, method);
        refresh();
      },
      onSettled: () => {
        setBtnDisabled(false);
        setConfirmOpen(false);
      },
    };

    if (method === 'update') {
      let id;
      if (data.miscDocumentId) id = data.miscDocumentId;
      if (data.documentId) id = data.documentId;
      update(actionType, { id, data }, payload);
    } else {
      del(actionType, { id: data.id, ...data }, payload);
    }
  };

  const getNotificationMessage = (type: string, method = 'update') => {
    switch (type) {
      case 'investigation-document':
      case 'application-document':
        return method === 'update'
          ? notify('Document submitted successfully', { type: 'success' })
          : notify('Document deleted successfully', { type: 'success' });

      default:
        break;
    }
  };

  const getConfirmModalContent = () => {
    switch (activeItem) {
      case 'update_investigation-document':
        return {
          header: 'Submit Investigation Document',
          body: 'Are you sure you want to submit the investigation document?',
        };
      case 'delete_investigation-document':
        return {
          header: 'Delete Investigation Document',
          body: 'Are you sure you want to delete the investigation document?',
        };
      case 'update_application-document':
        return {
          header: 'Submit Application Document',
          body: 'Are you sure you want to submit the application document?',
        };
      case 'delete_application-document':
        return {
          header: 'Delete Application Document',
          body: 'Are you sure you want to delete the application document?',
        };
      default:
        return {
          header: 'Confirm',
          body: 'Are you sure you want to do the following action?',
        };
    }
  };

  const getItems = (status?: TradingBlockUploadedDocumentStateDto) => {
    switch (status) {
      case 'PendingReview':
        return (
          <div>
            <MenuItem style={Styles.achRelationship.menuItem} onClick={() => approveHandle()}>
              <CheckCircle style={{ color: Colors.SUCCESS }} />
              <span style={{ color: Colors.SUCCESS_DARK, paddingLeft: 5 }}>SUBMIT DOC.</span>
            </MenuItem>
            <MenuItem style={Styles.achRelationship.menuItem} onClick={() => deleteHandle()}>
              <Cancel style={{ color: Colors.DANGER }} />
              <span style={{ color: Colors.DANGER_DARK, paddingLeft: 5 }}>DELETE DOC.</span>
            </MenuItem>
          </div>
        );
      case 'Rejected':
        return (
          <div>
            <MenuItem style={{ ...Styles.achRelationship.menuItem, opacity: 0.6 }}>
              <Cancel style={{ color: Colors.DANGER }} />
              <span style={{ color: Colors.DANGER_DARK, paddingLeft: 5 }}>Document deleted</span>
            </MenuItem>
            <MenuItem style={{ ...Styles.achRelationship.menuItem, display: 'none' }} onClick={() => viewHandle()}>
              <Visibility style={{ color: Colors.INFO }} />
              <span style={{ color: Colors.INFO_DARK, paddingLeft: 5 }}>VIEW DOC.</span>
            </MenuItem>
          </div>
        );
      case 'Submitted':
        return (
          <div>
            <MenuItem style={Styles.achRelationship.menuItem} onClick={() => deleteHandle()}>
              <Cancel style={{ color: Colors.DANGER }} />
              <span style={{ color: Colors.DANGER_DARK, paddingLeft: 5 }}>DELETE DOC.</span>
            </MenuItem>
          </div>
        );

      default:
        return <></>;
    }
  };
  if (element) {
    return (
      <>
        {anchorEl && (
          <Menu
            id='investigations-menu'
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}>
            {getItems(element.state)}
          </Menu>
        )}

        <Confirm
          isOpen={confirmOpen}
          loading={btnDisabled}
          title={getConfirmModalContent()?.header}
          content={getConfirmModalContent()?.body}
          onConfirm={confirmAction}
          onClose={() => setConfirmOpen(false)}
        />
      </>
    );
  } else return <></>;
};
export default DocumentActionsDropdown;
