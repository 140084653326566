import {
  List,
  Datagrid,
  TextField,
  DateField,
  EmailField,
  BooleanField,
  useRecordContext,
  FunctionField,
  ShowButton,
} from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { StatusTag } from 'src/lib';
import { Account, User } from 'src/models';
import { getAccountStatusTagPalette } from 'src/utils';

import { UserListFilters } from '../../constants/choices/user.choices';
import { PhoneField } from '../Fields';
import { UserListActions } from './UserListActions';

const defaultSort = { field: 'createdAt', order: 'DESC' };

const AccountList = () => {
  const record = useRecordContext<User>();

  return (
    <Datagrid
      bulkActionButtons={false}
      isRowExpandable={() => false}
      data={record.accounts}
      rowClick='show'
      resource={DataProviderResource.Accounts}>
      <TextField source='accountId' label='Account Id' />
      <TextField source='accountNumber' label='Account Number' />
      <TextField source='type.label' label='Account Type' />
      <FunctionField
        label='Account status'
        render={(account?: Account) => {
          if (account?.status) {
            return <StatusTag value={account.status.label} style={getAccountStatusTagPalette(account.status.value)} />;
          }

          return null;
        }}
      />
      <ShowButton label='Show' resource={DataProviderResource.Accounts} />
    </Datagrid>
  );
};

export const UserList = () => (
  <List title='Users' filters={UserListFilters} sort={defaultSort} actions={<UserListActions />}>
    <Datagrid
      rowClick='expand'
      bulkActionButtons={false}
      expand={<AccountList />}
      isRowExpandable={(record: User) => (record?.accounts?.length ?? 0) >= 0}>
      <TextField source='id' label='User id' />
      <TextField source='firstName' />
      <TextField source='lastName' />
      <EmailField source='email' />
      <PhoneField source='phoneNumber' />
      <BooleanField source='isClosed' />
      <BooleanField source='isLocked' />
      <DateField source='createdAt' label='Created at' />
      <ShowButton label='Show' />
    </Datagrid>
  </List>
);
