import { useState } from 'react';

import { KeyboardArrowDown } from '@mui/icons-material';
import { CardActions } from '@mui/material';
import {
  Button,
  ListButton,
  Loading,
  Show,
  ShowController,
  Tab,
  TabbedShowLayout,
  TabbedShowLayoutTabs,
  useRecordContext,
} from 'react-admin';
import { User } from 'src/models';

import Styles from '../../constants/styles';
import { UserActions } from './actions/UserActions';
import { UserInformationShow } from './UserInformationShow';

const Title = () => {
  const { firstName, lastName, email } = useRecordContext<User>();

  return (
    <span>
      User:{' '}
      <strong>
        {firstName} {lastName}
      </strong>{' '}
      ({email})
    </span>
  );
};

const Actions = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <CardActions>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'flex-start',
        }}>
        <ListButton />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Button
          aria-haspopup='listbox'
          aria-controls='lock-menu'
          onClick={e => {
            handleClickListItem(e);
          }}
          style={Styles.user.moreActionsButton}>
          <>
            USER ACTIONS
            <KeyboardArrowDown fontSize='small' />
          </>
        </Button>
        <UserActions anchorElement={anchorEl} onClose={handleClose} />
      </div>
    </CardActions>
  );
};

export const UserShow = (props: User) => {
  return (
    <ShowController {...props}>
      {controllerProps => {
        if (controllerProps.isLoading) {
          return <Loading />;
        } else
          return (
            <Show {...props} {...controllerProps} title={<Title {...controllerProps.record} />} actions={<Actions />}>
              <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant='scrollable' />}>
                <Tab label='User Information' sx={{ overflow: 'auto' }}>
                  <UserInformationShow />
                </Tab>
              </TabbedShowLayout>
            </Show>
          );
      }}
    </ShowController>
  );
};
