import { Grid } from '@mui/material';
import { Datagrid, DateField, FunctionField, List, NumberField, TextField, useRecordContext } from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { StatusTag } from 'src/lib';
import { OfferOversubscription, OfferOversubscriptionDraftOrder } from 'src/models';
import { getOfferOrderStatusTagPalette } from 'src/utils';

import { UpdateOversubscriptionDraftOrderButton } from './UpdateOversubscriptionDraftOrderButton';

export const OfferOversubscriptionDraftOrderList = () => {
  const context = useRecordContext<OfferOversubscription>();

  return (
    <List
      title=' '
      sort={{ field: 'createdAt', order: 'DESC' }}
      resource={DataProviderResource.OfferOversubscriptionDraftOrder}
      exporter={false}
      filter={{ id: context.id }}
      emptyWhileLoading>
      <Datagrid bulkActionButtons={false}>
        <TextField source='id' label='Id' sortable={false} />
        <TextField source='offer.name' label='Offer' sortable={false} />
        <TextField source='actual.accountId' label='Account Id' sortable={false} />
        <FunctionField
          label='Status'
          render={(record?: OfferOversubscriptionDraftOrder) =>
            record ? (
              <StatusTag
                value={record.actual.status.label}
                style={getOfferOrderStatusTagPalette(record.actual.status.value)}
              />
            ) : null
          }
          sortable={false}
        />
        <NumberField
          source={context.status.isCompleted ? 'previous.quantity' : 'actual.quantity'}
          label={context.status.isCompleted ? 'Previous Quantity' : 'Quantity'}
          sortable={false}
        />
        <FunctionField
          label={context.status.isCompleted ? 'Quantity' : 'Draft Quantity'}
          render={(record?: OfferOversubscriptionDraftOrder) => {
            if (!record) {
              return null;
            }

            return (
              <Grid container>
                <Grid item md={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <NumberField label='Quantity' record={record} source={'quantity'} sx={{ marginRight: '1rem' }} />
                  <UpdateOversubscriptionDraftOrderButton record={record} oversubscription={context} />
                </Grid>
              </Grid>
            );
          }}
        />
        <NumberField
          source={context.status.isCompleted ? 'previous.totalCost' : 'actual.totalCost'}
          label={context.status.isCompleted ? 'Previous Total Cost' : 'Total Cost'}
          options={{ style: 'currency', currency: 'USD' }}
          sortable={false}
        />
        <NumberField
          source={'totalCost'}
          label={context.status.isCompleted ? 'Total Cost' : 'Draft Total Cost'}
          options={{ style: 'currency', currency: 'USD' }}
          sortable={false}
        />
        <DateField source='actual.createdAt' label='Created at' sortable={false} />
      </Datagrid>
    </List>
  );
};
