import * as React from 'react';

import { Chip, Grid, Tooltip } from '@mui/material';
import { isEmpty } from 'lodash';
import { FunctionField, NumberField, ReferenceField, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';
import { OfferOrderPaymentTypeDto } from 'src/dtos';
import { OfferOrder, OfferOrderDetails } from 'src/models';
import { ColorConstant } from 'src/styles';
import { getOfferOrderPaymentStatusColor, getOfferOrderStatusDtoTagStyle } from 'src/utils';

import { GridBreak } from '../../../../lib/GridBreak/GridBreak';
import { OfferOrderRefundList } from '../OfferOrderRefundList';

export const OfferOrderDetailsShow = () => {
  const record = useRecordContext<OfferOrder>();

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>Offer Order</h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <SimpleShowLayout>
          <ReferenceField label='Offer name' source='offerId' reference='offers' sortable={false} link={'show'}>
            <TextField source='name' fullWidth />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <SimpleShowLayout>
          <ReferenceField label='Category' source='offerId' reference='offers' sortable={false} link={false}>
            <ReferenceField source='categoryId' reference='offer-options' sortable={false} link={false}>
              <TextField source='name' fullWidth />
            </ReferenceField>
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <GridBreak />
      <Grid item xs={6} sm={6} md={6}>
        <SimpleShowLayout>
          <ReferenceField label='User' source='userId' reference='users' sortable={false}>
            <FunctionField label='User' render={(record: any) => `${record.firstName} ${record.lastName}`} />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <SimpleShowLayout>
          <TextField label='Account Id' source='accountId' />
        </SimpleShowLayout>
      </Grid>
      <GridBreak />
      <Grid item xs={6} sm={6} md={6}>
        <SimpleShowLayout>
          <NumberField label='Quantity' source='quantity' />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <SimpleShowLayout>
          <NumberField label='Total cost' source='totalCost' options={{ style: 'currency', currency: 'USD' }} />
        </SimpleShowLayout>
      </Grid>
      {record.conditional && (
        <Grid item xs={6} sm={6} md={6}>
          <SimpleShowLayout>
            <NumberField
              label='Total requested investment'
              source='conditional.totalInvestment'
              options={{ style: 'currency', currency: 'USD' }}
            />
          </SimpleShowLayout>
        </Grid>
      )}
      <Grid item xs={2}>
        <SimpleShowLayout>
          <FunctionField
            label='Status'
            render={(record?: OfferOrder) => {
              if (!record) {
                return null;
              }

              return <div style={getOfferOrderStatusDtoTagStyle(record.status.value)}> {record.status.label}</div>;
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <GridBreak />
      <Grid item xs={6} sm={6} md={6}>
        <SimpleShowLayout>
          <FunctionField
            label='Payment type'
            render={(record?: OfferOrder) => {
              if (!record) {
                return null;
              }

              if (record.paymentType.isCard || !isEmpty(record.refunds)) {
                return (
                  <div>
                    <span style={{ marginRight: '4px' }}>{record.paymentType.label}</span>
                    <Chip
                      size='small'
                      label={record.paymentStatus.label}
                      color={getOfferOrderPaymentStatusColor(record.paymentStatus.value)}
                    />
                  </div>
                );
              }

              return `${record.paymentType.label} ${
                record.retirementAccountFundingType?.value ? `(${record.retirementAccountFundingType.label})` : ''
              }`;
            }}
          />
        </SimpleShowLayout>
      </Grid>
      {record.isExternal && (
        <Grid item xs={6} sm={6} md={6}>
          <SimpleShowLayout>
            <FunctionField
              label='Security Delivery Information'
              render={(record?: OfferOrderDetails) => {
                if (
                  record?.externalDetails?.securityDeliveryOption &&
                  record?.externalDetails?.securityDeliveryOption.tooltip
                ) {
                  const { label, tooltip } = record.externalDetails.securityDeliveryOption;
                  const startIndex = label.indexOf('<tooltip>');
                  const endIndex = label.indexOf('</tooltip>');
                  const beforeTooltip = label.substring(0, startIndex);
                  const betweenTooltip = label.substring(startIndex + '<tooltip>'.length, endIndex);
                  const afterTooltip = label.substring(endIndex + '</tooltip>'.length);

                  return (
                    <div>
                      {beforeTooltip}{' '}
                      <Tooltip title={tooltip}>
                        <span style={{ textDecoration: 'underline', color: ColorConstant.BRAND6 }}>
                          {betweenTooltip}
                        </span>
                      </Tooltip>{' '}
                      {afterTooltip}
                    </div>
                  );
                }

                return <>{record?.externalDetails?.securityDeliveryOption?.label ?? 'NA'}</>;
              }}
            />
          </SimpleShowLayout>
        </Grid>
      )}
      <GridBreak />
      {record.paymentType.value === OfferOrderPaymentTypeDto.Card && (
        <>
          <Grid item xs={6} sm={6} md={6}>
            <SimpleShowLayout>
              <TextField label='Card payment intent id' source='cardPayment.intentId' />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <SimpleShowLayout>
              <TextField label='Is card payment confirmed?' source='cardPayment.isConfirmed' />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <SimpleShowLayout>
              <TextField label='Cardholder (billing name)' source='cardPayment.cardholder' />
            </SimpleShowLayout>
          </Grid>
        </>
      )}
      {(record.paymentType.isCard || !isEmpty(record.refunds)) && (
        <Grid item xs={6} sm={6} md={6}>
          <SimpleShowLayout>
            <FunctionField
              label='Payment Status'
              render={(record?: OfferOrder) => {
                if (!record) {
                  return null;
                }

                <Chip
                  size='small'
                  label={record.paymentStatus.label}
                  color={getOfferOrderPaymentStatusColor(record.paymentStatus.value)}
                />;
              }}
            />
          </SimpleShowLayout>
        </Grid>
      )}
      <Grid item xs={6} sm={6} md={6}>
        <SimpleShowLayout>
          <NumberField
            label='Total Amount Refunded'
            source='amountRefunded'
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={12} md={10} lg={10}>
        <SimpleShowLayout>
          <h2> Refunds</h2>
          <OfferOrderRefundList />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};
export default OfferOrderDetailsShow;
