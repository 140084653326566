import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  List,
  ReferenceInput,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
} from 'react-admin';
import { FileExportStatusDto } from 'src/dtos';
import { StatusTag } from 'src/lib';
import { FileExport } from 'src/models';
import { getFileExportStatusTagPalette } from 'src/utils';

import { CancelFileExportButton } from './CancelFileExportButton';
import { DeleteFileExportButton } from './DeleteFileExportButton';
import { DownloadFileExportButton } from './DownloadFileExportButton';

const statusOptions = Object.values(FileExportStatusDto)?.map((key: string) => ({ id: key, name: key }));

export const filterList = [
  <TextInput key='name' label='File Name' source='name' alwaysOn />,
  <ReferenceInput
    key='offerId'
    label='Offer Name'
    source='offerId'
    reference='offers'
    sort={{ field: 'name', order: 'ASC' }}>
    <AutocompleteInput
      label='Offer Name'
      optionText='name'
      fullWidth
      filterToQuery={searchText => ({ name: searchText })}
    />
  </ReferenceInput>,
  <SelectInput
    key='status'
    label='Status'
    source='status'
    fullWidth
    choices={statusOptions}
    optionText={(record: { id: FileExportStatusDto; name: FileExportStatusDto }) => (
      <StatusTag value={record.name} style={getFileExportStatusTagPalette(record.name)} />
    )}
  />,
];

export const FileExportList = () => {
  return (
    <List
      title='File Exports'
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={false}
      filters={filterList}
      emptyWhileLoading>
      <Datagrid bulkActionButtons={false}>
        <TextField source='id' label='Id' sortable={false} />
        <FunctionField
          label='File Name'
          render={(record?: FileExport) => {
            if (!record) {
              return null;
            }

            return record.name.concat(record.offer ? ` (${record.offer.name})` : '');
          }}
          sortable={false}
        />
        <FunctionField
          label='Type'
          render={(record?: FileExport) => (record ? record.type.label : null)}
          sortable={false}
        />
        <FunctionField
          label='Status'
          render={(record?: FileExport) =>
            record ? (
              <StatusTag value={record.status.label} style={getFileExportStatusTagPalette(record.status.value)} />
            ) : null
          }
          sortable={false}
        />
        <FunctionField
          label='Download'
          render={(record?: FileExport) => {
            if (!record) {
              return null;
            }

            return <DownloadFileExportButton record={record} />;
          }}
          sortable={false}
        />
        <FunctionField
          label='Cancel'
          render={(record?: FileExport) => {
            if (!record) {
              return null;
            }

            return <CancelFileExportButton record={record} />;
          }}
          sortable={false}
        />
        <FunctionField
          label='Delete'
          render={(record?: FileExport) => {
            if (!record) {
              return null;
            }

            return <DeleteFileExportButton record={record} />;
          }}
          sortable={false}
        />
        <DateField source='createdAt' label='Created at' showTime sortable={false} />
        <ShowButton label='Show' />
      </Datagrid>
    </List>
  );
};
