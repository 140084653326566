import { AccountBalanceUrlConstant } from 'src/constants';
import {
  AccountBalanceDto,
  GetAccountBalanceDto,
  GetAccountBalanceListDto,
  GetAccountBalanceListResponseDto,
  GetAccountWithdrawalFundsBalanceDto,
  GetAccountWithdrawalFundsBalanceResponseDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class BalanceApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async retrieve({ params }: GetAccountBalanceDto): Promise<AccountBalanceDto> {
    const { accountId } = params;

    const url = replacePlaceholders(AccountBalanceUrlConstant.RETRIEVE, { accountId });

    return httpRequest<AccountBalanceDto>({ method: 'GET', url, authToken: this._configService.authToken });
  }

  async list({ body }: GetAccountBalanceListDto): Promise<GetAccountBalanceListResponseDto> {
    return httpRequest<GetAccountBalanceListResponseDto>({
      method: 'POST',
      url: AccountBalanceUrlConstant.LIST,
      authToken: this._configService.authToken,
      body,
    });
  }

  async retrieveWithdrawalFunds({
    params,
  }: GetAccountWithdrawalFundsBalanceDto): Promise<GetAccountWithdrawalFundsBalanceResponseDto> {
    const { accountId } = params;

    const url = replacePlaceholders(AccountBalanceUrlConstant.RETRIEVE_WITHDRAWAL_FUNDS, { accountId });

    return httpRequest<GetAccountWithdrawalFundsBalanceResponseDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }
}
