import { useState } from 'react';

import ReduceCapacityIcon from '@mui/icons-material/ReduceCapacity';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { Button as ActionButton, useDataProvider, useNotify, useRecordContext } from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { OfferDto, OfferOversubscriptionTypeDto } from 'src/dtos';
import { OfferOversubscription } from 'src/models';
import * as yup from 'yup';

interface CreateOfferOversubscriptionFormValues {
  type: string;
  percentage: string;
}

const validationSchema = yup.object().shape({
  percentage: yup.number().positive().required('Percentage is required'),
  type: yup.string().oneOf(Object.values(OfferOversubscriptionTypeDto)).required('Reduction Type is required'),
});

export const CreateOfferOversubscriptionButton = () => {
  const context = useRecordContext<OfferDto>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dataProvider = useDataProvider();
  const notify = useNotify();

  const onCreate = (values: CreateOfferOversubscriptionFormValues) => {
    setIsLoading(true);

    dataProvider
      .create<OfferOversubscription>(DataProviderResource.OfferOversubscription, {
        data: {
          offerId: context.id,
          type: values.type,
          percentage: values.percentage,
        },
      })
      .then(() => {
        notify(`Offer oversubscription was successfully created.`, {
          type: 'success',
          autoHideDuration: 7500,
        });
        _onClose();
      })
      .catch(error => {
        notify(error.message, {
          type: 'error',
          autoHideDuration: 7500,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik<CreateOfferOversubscriptionFormValues>({
    initialValues: {
      type: OfferOversubscriptionTypeDto.Percentage,
      percentage: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: onCreate,
  });

  const resetModal = () => {
    formik.resetForm();
  };

  const _onClose = () => {
    setIsOpen(false);
    resetModal();
  };

  return (
    <>
      <ActionButton
        label='Create Oversubscription'
        onClick={() => {
          setIsOpen(true);
        }}>
        <ReduceCapacityIcon />
      </ActionButton>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)} fullWidth>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <DialogTitle>Create Oversubscription</DialogTitle>
          <DialogContent>
            <Grid container direction='column' spacing={2}>
              <Grid item xs={6} md={6}>
                <Typography variant='h6' color='textSecondary'>
                  Offer: {context.name}
                </Typography>
              </Grid>
              <Grid item xs={6} md={6}>
                <FormControl margin='dense' variant='standard' fullWidth sx={{ width: '50%' }}>
                  <InputLabel id='type'>Reduction Type</InputLabel>
                  <Select
                    name='type'
                    labelId='type-label'
                    id='type'
                    disabled={isLoading}
                    error={formik.touched.type && !!formik.errors.type}
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}>
                    {Object.values(OfferOversubscriptionTypeDto).map(status => (
                      <MenuItem key={status} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.errors.type && (
                    <Typography variant='caption' color='error'>
                      {formik.errors.type}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  name='percentage'
                  label='Percentage %'
                  variant='standard'
                  margin='dense'
                  type='number'
                  disabled={isLoading}
                  error={Boolean(formik.errors.percentage)}
                  helperText={formik.errors.percentage}
                  value={formik.values.percentage}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  sx={{ width: '25%' }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12} container direction='row' justifyContent='flex-end' spacing={2}>
              <Grid item>
                <Button variant='outlined' color='inherit' size='medium' onClick={_onClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant='contained' color='primary' size='medium' disabled={isLoading} type='submit'>
                  Create
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
