import { UserUrlConstant } from 'src/constants';
import {
  ApproveUserDto,
  CloseUserDto,
  DisableUserMfaDto,
  EnableUserMfaDto,
  GetUserDto,
  GetUserListDto,
  GetUserListResponseDto,
  LockUserDto,
  LoginUserDto,
  LoginUserResponseDto,
  UnlockUserDto,
  UpdateUserPostcloseOffersDto,
  UserDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class UsersApi {
  private _configService: ConfigService;

  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  login({ body }: LoginUserDto): Promise<LoginUserResponseDto> {
    return httpRequest<LoginUserResponseDto>({
      method: 'POST',
      url: UserUrlConstant.LOGIN,
      body,
      authToken: this._configService.authToken,
    });
  }

  list({ queryParams }: GetUserListDto): Promise<GetUserListResponseDto> {
    return httpRequest<GetUserListResponseDto>({
      method: 'GET',
      url: UserUrlConstant.LIST,
      authToken: this._configService.authToken,
      queryParams,
    });
  }

  retrieve({ params }: GetUserDto): Promise<UserDto> {
    const url = replacePlaceholders(UserUrlConstant.RETRIEVE, params);

    return httpRequest<UserDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  lock({ params }: LockUserDto): Promise<void> {
    const url = replacePlaceholders(UserUrlConstant.LOCK, params);

    return httpRequest<void>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  unlock({ params }: UnlockUserDto): Promise<void> {
    const url = replacePlaceholders(UserUrlConstant.UNLOCK, params);

    return httpRequest<void>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  close({ params }: CloseUserDto): Promise<void> {
    const url = replacePlaceholders(UserUrlConstant.CLOSE, params);

    return httpRequest<void>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  approve({ params }: ApproveUserDto): Promise<void> {
    const url = replacePlaceholders(UserUrlConstant.APPROVE, params);

    return httpRequest<void>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  enableMfa({ queryParams }: EnableUserMfaDto): Promise<void> {
    return httpRequest<void>({
      method: 'PUT',
      url: UserUrlConstant.ENABLE_MFA,
      queryParams,
      authToken: this._configService.authToken,
    });
  }

  disableMfa({ queryParams }: DisableUserMfaDto): Promise<void> {
    return httpRequest<void>({
      method: 'PUT',
      url: UserUrlConstant.DISABLE_MFA,
      queryParams,
      authToken: this._configService.authToken,
    });
  }

  updatePostcloseOffers({ params, body }: UpdateUserPostcloseOffersDto): Promise<UserDto> {
    const url = replacePlaceholders(UserUrlConstant.UPDATE_POSTCLOSE_OFFERS, params);

    return httpRequest<UserDto>({
      method: 'PUT',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }
}
