import { Fragment, useState } from 'react';

import { Grid } from '@mui/material';
import { BooleanField, NumberField, TextField, WithRecord, FunctionField } from 'react-admin';
import { OfferOrderStatusDto, OfferOrderStatusAggregateDto } from 'src/dtos';

import Styles from '../../../../constants/styles';
import { OfferOrderStatusDtoTag } from '../../../Fields/OfferOrderStatusDtoTag';

interface AccumulatedInvestmentFieldProps {
  offerOrderAggregate: OfferOrderStatusAggregateDto[];
}

const AccumulatedInvestmentField = ({ offerOrderAggregate }: AccumulatedInvestmentFieldProps) => {
  const [summationOfFunnel, setSummationOfFunnel] = useState<number>(0);

  const calculateFunnelSummation = (formData: any) => {
    setSummationOfFunnel(
      formData._ordersSummation
        ?.filter((item: any) => item?._includeInSummation)
        ?.reduce((acc: number, item: any) => acc + (item?.sumRaisedInvestment || 0), 0),
    );
  };

  const createOfferOrderStatusList = (record: any) => {
    const isNumber = !isNaN(record.ordersSummation);

    const selectedStatus = isNumber ? [] : record.ordersSummation?.split(',') || [];
    record._ordersSummation = Object.keys(OfferOrderStatusDto).map((key: string) => {
      const statusData = offerOrderAggregate?.find((item: OfferOrderStatusAggregateDto) => key === item.status);
      const isSelectedStatus = selectedStatus?.includes(key) || false;
      if (statusData)
        return {
          status: key,
          totalOfferOrders: statusData.total,
          sumRaisedInvestment: statusData.totalAmount,
          _includeInSummation: isSelectedStatus,
        };

      return {
        status: key,
        totalOfferOrders: 0,
        sumRaisedInvestment: 0,
        _includeInSummation: isSelectedStatus,
      };
    });

    if (isNumber) {
      record._ordersSummation.custom = record.ordersSummation;
      record._ordersSummation.useCustom = !record.ordersSummation || record.ordersSummation === '' ? undefined : true;

      return setSummationOfFunnel(Number(record._ordersSummation.custom));
    }

    return calculateFunnelSummation(record);
  };

  const offerOrderStatusData = (item: any, index: any) => {
    return (
      <Grid container direction='row' spacing={2} key={item.status}>
        <Grid item xs={3}>
          <FunctionField
            render={(record: any) => {
              return <OfferOrderStatusDtoTag value={record._ordersSummation[index].status} />;
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField source={`_ordersSummation[${index}].totalOfferOrders`} />
        </Grid>
        <Grid item xs={3}>
          <NumberField
            label='Sum of Investments'
            source={`_ordersSummation[${index}].sumRaisedInvestment`}
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </Grid>
        <Grid item xs={3}>
          <BooleanField
            source={`_ordersSummation[${index}]._includeInSummation`}
            label='Include In Summation'
            fullWidth
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <h3>Accumulated Investments</h3>
      </Grid>
      <Grid item xs={12} style={Styles.offer.closePannelWrapper}>
        <Grid item xs={12}>
          <WithRecord
            render={record => {
              createOfferOrderStatusList(record);

              return record._ordersSummation?.map(offerOrderStatusData);
            }}
          />
        </Grid>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={9}>
            <FunctionField
              render={(record: any) => {
                if (record._ordersSummation?.custom && !isNaN(record._ordersSummation.custom))
                  return `Custom Accumulated Value: ${Number(record._ordersSummation?.custom)?.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}`;
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <BooleanField source='_ordersSummation.useCustom' label='Override with Custom Accumulated Value' />
          </Grid>
          <Grid item xs={6}>
            <h4>Summation that will be displayed in Funnel:</h4>
          </Grid>
          <Grid item xs={6}>
            <h4>{summationOfFunnel?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h4>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AccumulatedInvestmentField;
