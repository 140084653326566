import DraggableArea from './DraggableArea';
import DroppableArea from './DroppableArea';
import { DragDropElementTree } from './FileExportSelector';

interface DraggableElementGroupProps {
  item: DragDropElementTree;
  indexedElement: number;
  droppableParentAreaId?: string;
  onChangeValue: (itemId: string) => void;
}

export const DraggableElementGroup = ({
  item,
  indexedElement,
  droppableParentAreaId,
  onChangeValue,
}: DraggableElementGroupProps) => {
  return (
    <DraggableArea item={item} indexedElement={indexedElement}>
      <DroppableArea
        droppableAreaId={(droppableParentAreaId ? `${droppableParentAreaId}.` : '') + `${item.id}`}
        items={item.children}
        onChangeValue={onChangeValue}
      />
    </DraggableArea>
  );
};
export default DraggableElementGroup;
