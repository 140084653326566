import { GetManyParams, GetOneParams, GetOneResult } from 'react-admin';
import { getClientApi } from 'src/data-communication';
import { mapAccountBalanceDtoToModel } from 'src/mappers';
import { GetAccountWithdrawalFundsBalanceResponse } from 'src/models';

export const getAccountBalance = async (params: GetOneParams) => {
  const { id } = params;

  const response = await getClientApi().balance.retrieve({ params: { accountId: id } });

  return {
    data: mapAccountBalanceDtoToModel(response),
  };
};

export const getAccountBalanceList = async (params: GetManyParams) => {
  const { ids } = params;

  const response = await getClientApi().balance.list({
    body: { accountIds: ids.map(anId => Number(anId)) },
  });

  return {
    data: response.data.map(anAccountDto => mapAccountBalanceDtoToModel(anAccountDto)),
    total: response.total,
  };
};

export const getAccountWithdrawalFundsBalance = async (
  params: GetOneParams,
): Promise<GetOneResult<GetAccountWithdrawalFundsBalanceResponse>> => {
  const { id } = params;

  const response = await getClientApi().balance.retrieveWithdrawalFunds({ params: { accountId: id } });

  return {
    data: {
      id,
      availableForWithdrawal: response.availableFundsForWithdrawal,
      accountId: id,
    },
  };
};
