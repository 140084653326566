import { EmailField, FunctionField, Labeled, TextField } from 'react-admin';
import { Row, Col } from 'react-grid-system';
import { User } from 'src/models';

import { PhoneField } from '../Fields';
import StatusField from '../Fields/StatusField';

export const UserInformationShow = () => {
  return (
    <div className='showContent'>
      <Row>
        <Col sm={12}>
          <h2 className='title'>User Information</h2>
        </Col>
      </Row>
      <Row gutterWidth={10}>
        <Col md={1}>
          <Labeled label='First name'>
            <TextField source='firstName' />
          </Labeled>
        </Col>
        <Col md={2}>
          <Labeled label='Middle Initial or Middle Name'>
            <TextField source='middlename' />
          </Labeled>
        </Col>
        <Col md={1}>
          <Labeled label='Last name'>
            <TextField source='lastName' />
          </Labeled>
        </Col>
        <Col md={1}>
          <Labeled label='Suffix'>
            <TextField source='suffix' />
          </Labeled>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled label='Email'>
            <EmailField source='email' />
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled label='Phone'>
            <PhoneField source='phoneNumber' />
          </Labeled>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled label='Sign up status'>
            {<StatusField source='signUpStatus' success='done' warning={['addressStep', 'securityStep']} />}
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled label='MFA status'>
            <FunctionField render={(user?: User) => <span>{user?.isMfaEnabled ? 'Enabled' : 'Disabled'}</span>} />
          </Labeled>
        </Col>
      </Row>
    </div>
  );
};
