import { PhoneNumberDto } from 'src/dtos';

interface PhonesFieldProps {
  value: PhoneNumberDto[];
}

export const PhonesField = ({ value }: PhonesFieldProps) => {
  return (
    <>
      {value.map(aPhone => (
        <div key={aPhone.type}>
          +1 {aPhone.phoneNumber} | {aPhone.type}
        </div>
      ))}
    </>
  );
};
