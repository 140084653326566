import { Grid } from '@mui/material';
import { SimpleShowLayout, NumberField, BooleanField, Loading, useGetOne, useRecordContext } from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { OfferOptionTypeDto, OfferCategoryNameDto } from 'src/dtos';
import { OfferOrderAggregate } from 'src/models';
import { getCurrencyFormatter } from 'src/utils';

import { GridBreak } from '../../../../lib/GridBreak/GridBreak';

export const PriceShow = () => {
  const record = useRecordContext();

  const { data: offerOrderAggregate, isLoading } = useGetOne<OfferOrderAggregate>(
    DataProviderResource.AggregateOfferOrders,
    {
      id: record.id.toString(),
    },
  );

  const { data: category } = useGetOne('offer-options', {
    id: record.categoryId,
    meta: { type: OfferOptionTypeDto.CATEGORY },
  });

  if (isLoading) return <Loading />;

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>Price & Limits Information </h2>
          <h4>(Funds Indicated{getCurrencyFormatter().format(offerOrderAggregate?.indicatedFunds ?? 0)}) </h4>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField
            label='Minimum Raise Amount'
            source='minTotalRaiseCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField
            label='Maximum Raise Amount'
            source='maxTotalRaiseCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField
            label='Minimum Investment Currency'
            source='minimumInvestCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField
            label='Maximum Investment Currency'
            source='maximumInvestCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField
            label='Minimum Investment Increment'
            source='minimumInvestIncCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField label='Minimum Invest Share Increment Qty' source='minimumInvestShareIncrQty' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField label='Minimum Invest Share Qty' source='minimumInvestShareQty' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField label='Maximum Invest Share Qty' source='maximumInvestShareQty' fullWidth />
        </SimpleShowLayout>
      </Grid>

      {category?.name !== OfferCategoryNameDto.Conditional && (
        <Grid item xs={12} sm={6}>
          <SimpleShowLayout>
            <NumberField
              label='Price Per Share'
              source='pricePerShare'
              fullWidth
              options={{ style: 'currency', currency: 'USD' }}
            />
          </SimpleShowLayout>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <BooleanField label='Allow restricted persons' source='allowRestrictedPersons' fullWidth />
        </SimpleShowLayout>
      </Grid>
      {category?.name === OfferCategoryNameDto.Conditional && (
        <>
          <GridBreak />
          <Grid item xs={12}>
            <h3>Conditional Offer Details</h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SimpleShowLayout>
              <NumberField
                label='Price Per Share'
                source='pricePerShare'
                fullWidth
                options={{ style: 'currency', currency: 'USD' }}
              />
            </SimpleShowLayout>
          </Grid>
          <GridBreak />
          <Grid item xs={12} sm={6}>
            <SimpleShowLayout>
              <NumberField
                label='Minimum Share Price'
                source='minSharePrice'
                options={{ style: 'currency', currency: 'USD' }}
                fullWidth
              />
            </SimpleShowLayout>
          </Grid>
          <GridBreak />
          <Grid item xs={12} sm={6}>
            <SimpleShowLayout>
              <NumberField
                label='Maximum Share Price'
                source='maxSharePrice'
                options={{ style: 'currency', currency: 'USD' }}
                fullWidth
              />
            </SimpleShowLayout>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default PriceShow;
