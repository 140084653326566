import { AccountDto } from './accounts.dtos';
import { BaseListResponseDto, PaginationQueryParams, SortDirectionQueryParams } from './common.dtos';

export enum SignUpStatusDto {
  addressStep = 'addressStep',
  securityStep = 'securityStep',
  done = 'done',
}

export interface UserBaseDto {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  email: string;
  phoneNumber: string;
  signUpStatus: SignUpStatusDto;
  isMfaEnabled: boolean;
  isMigrated: boolean;
  isLocked: boolean;
  isClosed: boolean;
  isApproved: boolean;
  address?: {
    state: string;
    country: string;
  };
  accounts: AccountDto[];
  createdAt?: Date;
  updatedAt?: Date;
  isApprovedAt?: Date;
  createdBy?: string;
  updatedBy?: string;
  isApprovedBy?: string;
  postcloseOffers?: string[];
}

export interface UserListDto {
  userId: string | null;
  accountId?: number | null;
  accountNumber?: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber?: string | null;
  accountStatus?: string | null;
  isLocked: boolean | null;
  isClosed: boolean | null;
  isApproved: boolean | null;
  userCreatedAt: Date | null;
}

export interface UserDto extends UserBaseDto {
  postcloseOffers: string[];
}

export interface LoginUserBodyDto {
  email: string;
  password: string;
}
export interface LoginUserDto {
  body: LoginUserBodyDto;
}
export interface LoginUserWithChallengeResponseDto {
  session: string;
  phoneNumber: string;
}

// NOTE: when react-admin starts supporting mfa then LoginUserResponseDto by updated to include session
export type LoginUserResponseDto = {
  user: UserDto;
  accessToken: string;
  refreshToken: string;
  idToken: string;
  tbToken: string;
};

export interface BaseUserParamsDto {
  id: string;
}

export interface BaseUserQueryParamsDto {
  id: string;
}

export enum GetUserListSortByQueryParamTypeDto {
  UserId = 'userId',
  AccountNumber = 'accountNumber',
  AccountId = 'accountId',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  AccountStatus = 'accountStatus',
  UserCreatedAt = 'userCreatedAt',
  PhoneNumber = 'phoneNumber',
  IsLocked = 'isLocked',
  IsClosed = 'isClosed',
  IsApproved = 'isApproved',
}

export interface GetUserListFilterByQueryParamDto {
  accountNumber?: number;
  accountId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  accountStatus?: string;
  phoneNumber?: string;
  ids?: string[];
}

export interface GetUserListQueryParamsDto extends Partial<PaginationQueryParams>, Partial<SortDirectionQueryParams> {
  sortBy?: GetUserListSortByQueryParamTypeDto;
  filterBy?: GetUserListFilterByQueryParamDto;
  unmaskTaxId?: boolean;
}

export interface GetUserListDto {
  queryParams?: GetUserListQueryParamsDto;
}

export interface GetUserDto {
  params: BaseUserParamsDto;
}

export interface GetUserListResponseDto extends BaseListResponseDto<UserDto[]> {}

export interface LockUserDto {
  params: BaseUserParamsDto;
}

export interface UnlockUserDto {
  params: BaseUserParamsDto;
}

export interface CloseUserDto {
  params: BaseUserParamsDto;
}

export interface ApproveUserDto {
  params: BaseUserParamsDto;
}

export interface EnableUserMfaDto {
  queryParams: BaseUserQueryParamsDto;
}

export interface DisableUserMfaDto {
  queryParams: BaseUserQueryParamsDto;
}
export interface UpdateUserPostcloseOffersBodyDto {
  offerIds: string[];
}
export interface UpdateUserPostcloseOffersDto {
  params: BaseUserParamsDto;
  body: UpdateUserPostcloseOffersBodyDto;
}
