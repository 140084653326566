import { useState } from 'react';

import { Download } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { Button, useDataProvider, useNotify } from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { DownloadFileExportResponse, FileExport } from 'src/models';

interface DownloadFileExportButtonProps {
  record: FileExport;
}

export const DownloadFileExportButton = ({ record }: DownloadFileExportButtonProps) => {
  const [file, setFile] = useState<{ url: string; type: 'csv' } | null>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const openSaveFileDialog = ({ url, type }: { url: string; type: 'csv' }) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = `${record.name}.${type}`; // Specify the desired filename
    downloadLink.style.display = 'none';

    // Append the link to the document and trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up by removing the link
    document.body.removeChild(downloadLink);
  };

  const onDownload = async () => {
    try {
      setIsDownloading(true);

      if (!file) {
        const response = await dataProvider.getOne<DownloadFileExportResponse>(
          DataProviderResource.DownloadFileExport,
          {
            id: record.id,
          },
        );
        setFile(response.data.file);
        openSaveFileDialog(response.data.file);

        return;
      }
      openSaveFileDialog(file);
    } catch (error: any) {
      notify(`${JSON.stringify(error?.message ?? error)}`, {
        type: 'error',
      });
    } finally {
      setIsDownloading(false);
    }
  };

  if (isDownloading) {
    return (
      <IconButton>
        <CircularProgress color='inherit' size={12} />
      </IconButton>
    );
  }

  return (
    <Button
      label='Download'
      onClick={onDownload}
      startIcon={<Download />}
      disabled={isDownloading || !record.status.isCompleted}
    />
  );
};
