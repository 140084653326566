import { List, Datagrid, TextField, DateField, TextInput, ShowButton, FunctionField, SelectInput } from 'react-admin';

import ACHRelationship from '.';
import { states } from '../../constants/choices/ach.relationships.choices';
import StatusField from '../Fields/StatusField';

const defaultSort = { field: 'Date', order: 'DESC' };
const relationshipFilters = [
  <TextInput key='search' label='Account Information' placeholder='Ac. Number | Title | Name' source='query' />,
  <SelectInput key='state' label='Relationship Status' source='status' choices={states} />,
];

const ACHRelationshipList = () => {
  return (
    <>
      <List title='ACH Relationships' filters={relationshipFilters} sort={defaultSort}>
        <Datagrid expandSingle rowClick='expand' bulkActionButtons={false} expand={<ACHRelationship.Actions />}>
          <TextField source='id' sortable={false} />
          <TextField source='accountName' sortBy='AccountName' />
          <TextField source='accountNumber' sortBy='AccountNumber' />
          <TextField source='accountTitle' sortBy='AccountTitle' />
          <TextField source='bankAccount' sortable={false} />
          <TextField source='bankName' sortable={false} />
          <FunctionField
            sortable={false}
            label={'Status'}
            render={(record?: { status: any }) => (
              <StatusField
                status={record?.status}
                success={['Approved', 'FundsPosted', 'SentToBank', 'Complete']}
                warning={[
                  'PendingRepCreateApproval',
                  'PendingFirmCreateApproval',
                  'Pending',
                  'FundsHeld',
                  'PendingPrinting',
                  'Postponed',
                  'Requested',
                ]}
                fail={[
                  'Unknown',
                  'Rejected',
                  'Canceled',
                  'Cancelled',
                  'Void',
                  'Returned',
                  'StopPayment',
                  'CreateRejected',
                  'FailedAtBank',
                ]}
              />
            )}
          />

          <DateField source='date' sortBy='Date' />
          <ShowButton label='Show' />
        </Datagrid>
      </List>
    </>
  );
};

export default ACHRelationshipList;
