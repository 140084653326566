import { Datagrid, TextField, NumberField, FunctionField, DateField, List } from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { OfferOrderStatusDto } from 'src/dtos/offerOrders.dtos';
import { StatusTag } from 'src/lib';
import { getOfferOrderStatusTagColor } from 'src/utils/colors.utils';

import { SortDirectionTypeDto } from '../../../dtos/common.dtos';
import { OfferOrder } from '../../../models/offerOrders.models';

export interface AccountOfferOrderListProps {
  accountId: number;
}

export const AccountOfferOrderList = ({ accountId }: AccountOfferOrderListProps) => {
  return (
    <List
      actions={false}
      exporter={false}
      disableSyncWithLocation
      resource={DataProviderResource.OfferOrders}
      title=' '
      empty={false}
      filter={{ accountId }}
      sort={{ field: 'createdAt', order: SortDirectionTypeDto.Desc }}>
      <Datagrid bulkActionButtons={false} rowClick={'show'}>
        <FunctionField
          label='Status'
          render={(record?: OfferOrder) => {
            if (!record) {
              return null;
            }

            return <StatusTag value={record.status.label} style={getOfferOrderStatusTagColor(record.status.label)} />;
          }}
        />
        <FunctionField label='Type' render={() => 'Offer Order(s)'} />
        <TextField source='offerName' label='Offer' />
        <FunctionField
          label='Share Quantity'
          render={(record?: OfferOrder) => {
            if (!record) {
              return null;
            }

            if (
              Boolean(record.conditional) &&
              ![OfferOrderStatusDto.Approved, OfferOrderStatusDto.Complete].includes(record.status.value)
            ) {
              return null;
            }

            return record.quantity;
          }}
        />
        <FunctionField
          label='Share Price/Price Range'
          render={(record?: OfferOrder) => {
            if (!record) {
              return null;
            }

            if (
              Boolean(record.conditional) &&
              ![OfferOrderStatusDto.Approved, OfferOrderStatusDto.Complete].includes(record.status.value)
            ) {
              return (
                <div>
                  <NumberField source='conditional.minSharePrice' options={{ style: 'currency', currency: 'USD' }} /> -
                  <NumberField source='conditional.maxSharePrice' options={{ style: 'currency', currency: 'USD' }} />
                </div>
              );
            }

            return <NumberField source='price' options={{ style: 'currency', currency: 'USD' }} />;
          }}
        />
        <FunctionField
          label='Investment Amount'
          render={(record?: OfferOrder) => {
            if (!record) {
              return null;
            }

            if (
              Boolean(record.conditional) &&
              ![OfferOrderStatusDto.Approved, OfferOrderStatusDto.Complete].includes(record.status.value)
            ) {
              return (
                <NumberField source='conditional.totalInvestment' options={{ style: 'currency', currency: 'USD' }} />
              );
            }

            return (
              <NumberField
                source='totalCost'
                label='Investment amount'
                options={{ style: 'currency', currency: 'USD' }}
              />
            );
          }}
        />
        <DateField source='createdAt' label='Date of creation' showTime />
        <FunctionField
          label='Payment Type'
          sortable={false}
          render={(record?: OfferOrder) => {
            if (!record) {
              return null;
            }

            return `${record.paymentType.label} ${
              record.retirementAccountFundingType?.value ? `(${record.retirementAccountFundingType.label})` : ''
            }`;
          }}
        />
      </Datagrid>
    </List>
  );
};
