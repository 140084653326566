import { useState, useEffect } from 'react';

import { CircularProgress, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Form, SaveButton, useNotify, useRefresh, useUpdate, CheckboxGroupInput, useGetList } from 'react-admin';
import { DataProviderResource, QueryParamConstant } from 'src/constants';
import { OfferDto } from 'src/dtos';
import { User } from 'src/models';

interface InvestPostClosingOffersModalProps {
  user: User;
  openModal?: boolean;
  modalClosed?: () => void;
}

export const InvestPostClosingOffersModal = ({
  user,
  openModal = false,
  modalClosed = function () {},
}: InvestPostClosingOffersModalProps) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [update, { error }] = useUpdate();

  const [open, setOpen] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const {
    data,
    isLoading: offersLoading,
    error: offerError,
  }: { data: any; isLoading: boolean; error: any } = useGetList<OfferDto>('offers', {
    filter: { status: 'Closed', visibleToEndUsers: true },
    pagination: { perPage: QueryParamConstant.SKIP_PAGINATION, page: QueryParamConstant.SKIP_PAGINATION },
  });

  const defaultCheckedValues = data
    ?.filter((anOffer: OfferDto) => user?.postcloseOffers?.includes(anOffer.id))
    .map((anOffer: OfferDto) => anOffer.id);

  const handleClose = () => {
    setOpen(false);
    modalClosed();
  };

  const handleSubmit = (dataToUpdate: any) => {
    setBtnDisabled(true);
    update(
      DataProviderResource.UpdateUserPostcloseOffers,
      { id: user?.id, data: { offerIds: dataToUpdate.postcloseOffers } },
      {
        mutationMode: 'pessimistic',
        onSuccess: () => {
          handleClose();
          notify('Offer(s) was successfully added to user postclose offer invest list', {
            type: 'success',
          });
          setBtnDisabled(false);
        },
        onSettled: () => {
          refresh();
        },
      },
    );

    if (error) return error;
  };

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  if (offersLoading) {
    return <CircularProgress />;
  }

  if (offerError) {
    notify(JSON.stringify(offerError), { type: 'error' });

    return <p> Unknown error occurred while fetching offers list</p>;
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Postclose Offer Investment</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <CheckboxGroupInput
              source='postcloseOffers'
              label='Closed Offer List'
              choices={data}
              row={false}
              defaultValue={defaultCheckedValues}
            />
            <DialogActions>
              <Button variant='outlined' color='inherit' onClick={handleClose}>
                Cancel
              </Button>
              <SaveButton disabled={btnDisabled} label={btnDisabled ? 'Loading' : 'Save'} />
            </DialogActions>
          </div>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
