import { Labeled, TextField, useRecordContext } from 'react-admin';
import { Row, Col } from 'react-grid-system';
import { Account } from 'src/models';

export const Subtitle = ({ isPrimary }: { isPrimary: boolean }) => {
  const record = useRecordContext<Account>();

  const isSame = () => {
    if (isPrimary) {
      return record.primaryAccountHolder?.mailingAddress?.sameAsPhysical;
    }

    return record.secondaryAccountHolder?.mailingAddress?.sameAsPhysical;
  };

  return isSame() ? <small className='subtitle'>Is same with Physical Address</small> : null;
};

export const MailingAddressShow = () => {
  const record = useRecordContext<Account>();

  return (
    <>
      <div className='showContent'>
        <Row>
          <Col sm={12}>
            <h2 className='title'>
              {record.type.isJoint ? 'Primary Account Holder Address' : 'Account Holder Address'}
            </h2>
            <Subtitle isPrimary />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled label='Street Address (Line 1)'>
              <TextField source='primaryAccountHolder.mailingAddress.address1' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled label='Street Address (Line 2)'>
              <TextField source='primaryAccountHolder.mailingAddress.address2' />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled label='City'>
              <TextField source='primaryAccountHolder.mailingAddress.city' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled label='State'>
              <TextField source='primaryAccountHolder.mailingAddress.state.label' />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled label='Country'>
              <TextField source='primaryAccountHolder.mailingAddress.country.label' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled label='Postal Code'>
              <TextField source='primaryAccountHolder.mailingAddress.postalCode' />
            </Labeled>
          </Col>
        </Row>
      </div>
      {record.type.isJoint && (
        <div className='showContent'>
          <Row>
            <Col sm={12}>
              <h2 className='title'>Joint Account Holder Address</h2>
              <Subtitle isPrimary={false} />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Street Address (Line 1)'>
                <TextField source='primaryAccountHolder.mailingAddress.address1' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Street Address (Line 2)'>
                <TextField source='primaryAccountHolder.mailingAddress.address2' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='City'>
                <TextField source='primaryAccountHolder.mailingAddress.city' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='State'>
                <TextField source='primaryAccountHolder.mailingAddress.state.label' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Country'>
                <TextField source='primaryAccountHolder.mailingAddress.country.label' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Postal Code'>
                <TextField source='primaryAccountHolder.mailingAddress.postalCode' />
              </Labeled>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
