import { useEffect, useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import {
  Button as ActionButton,
  NumberField,
  SimpleShowLayout,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { OfferOversubscription, OfferOversubscriptionDraftOrder } from 'src/models';
import * as yup from 'yup';

interface UpdateOfferOversubscriptionDraftOrderFormValues {
  quantity: string;
}

const validationSchema = yup.object().shape({
  quantity: yup.number().positive().required('Draft Quantity is required'),
});

interface UpdateOversubscriptionDraftOrderButtonProps {
  record: OfferOversubscriptionDraftOrder;
  oversubscription: OfferOversubscription;
}

export const UpdateOversubscriptionDraftOrderButton = ({
  record,
  oversubscription,
}: UpdateOversubscriptionDraftOrderButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [draftTotal, setDraftTotal] = useState<{ receivedFunds: number; totalCost: number } | null>(null);
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const onUpdate = (values: UpdateOfferOversubscriptionDraftOrderFormValues) => {
    setIsLoading(true);

    dataProvider
      .update<OfferOversubscriptionDraftOrder>(DataProviderResource.OfferOversubscriptionDraftOrder, {
        id: record.oversubscription.id,
        previousData: record,
        meta: {
          orderId: record.id,
        },
        data: {
          quantity: values.quantity,
        },
      })
      .then(() => {
        notify(`Offer oversubscription draft order was successfully updated.`, {
          type: 'success',
          autoHideDuration: 7500,
        });
        _onClose();
        refresh();
      })
      .catch(error => {
        notify(error.message, {
          type: 'error',
          autoHideDuration: 7500,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik<UpdateOfferOversubscriptionDraftOrderFormValues>({
    initialValues: {
      quantity: record.quantity.toString(),
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: onUpdate,
  });

  const resetModal = () => {
    formik.resetForm();
  };

  const _onClose = () => {
    setIsOpen(false);
    resetModal();
  };

  useEffect(() => {
    if (oversubscription.receivedFunds) {
      const newDraftTotalCost = Number(formik.values.quantity) * record.actual.price;
      const currentDraftTotalCost = record.totalCost;
      const newDraftReceivedFundsTotal = oversubscription.receivedFunds + (newDraftTotalCost - currentDraftTotalCost);
      setDraftTotal({ receivedFunds: newDraftReceivedFundsTotal, totalCost: newDraftTotalCost });
    }
  }, [oversubscription?.receivedFunds, formik.values.quantity]);

  return (
    <>
      <ActionButton
        startIcon={<EditIcon />}
        onClick={() => setIsOpen(true)}
        size='small'
        label='Edit'
        disabled={!record.oversubscription.status.isUnderReview}
      />
      <Dialog open={isOpen} onClose={_onClose} fullWidth>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <DialogTitle>Update Draft Order</DialogTitle>
          <DialogContent>
            <Grid container direction='column' spacing={2}>
              <Grid item xs={6} md={6}>
                <TextField
                  label='Quantity'
                  variant='standard'
                  margin='dense'
                  type='number'
                  disabled
                  value={record.quantity}
                  fullWidth
                  sx={{ width: '30%' }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  name='quantity'
                  label='Draft Quantity'
                  variant='standard'
                  margin='dense'
                  type='number'
                  disabled={isLoading}
                  error={Boolean(formik.errors.quantity)}
                  helperText={formik.errors.quantity}
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  fullWidth
                  sx={{ width: '30%' }}
                />
              </Grid>
              <Grid container direction='column' spacing={1} sx={{ marginTop: '1rem' }}>
                <Grid item xs={12}>
                  <SimpleShowLayout>
                    <NumberField
                      record={draftTotal}
                      source='totalCost'
                      label='Draft Total Cost'
                      options={{ style: 'currency', currency: 'USD' }}
                    />
                  </SimpleShowLayout>
                </Grid>
                <Grid item xs={12}>
                  <SimpleShowLayout>
                    <NumberField
                      record={draftTotal}
                      source='receivedFunds'
                      label='Total Funds Received'
                      options={{ style: 'currency', currency: 'USD' }}
                    />
                  </SimpleShowLayout>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item xs={12} container direction='row' justifyContent='flex-end' spacing={2}>
              <Grid item>
                <Button variant='outlined' color='inherit' size='medium' onClick={_onClose}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant='contained' color='primary' size='medium' disabled={isLoading} type='submit'>
                  Save
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
