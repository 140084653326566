import { EmailField, FunctionField, Labeled, TextField, useRecordContext } from 'react-admin';
import { Row, Col } from 'react-grid-system';
import { PhonesField } from 'src/lib';
import { Account } from 'src/models';

export const TrustedContactShow = () => {
  const record = useRecordContext<Account>();

  return (
    <>
      {record.type.isJoint && record.jointAccountType?.isTenantsInCommon && (
        <div className='showContent'>
          <Row>
            <Col sm={12}>
              <h2 className='title'>Tenants in Common</h2>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled>
                <FunctionField
                  render={(record?: Account) => `${record?.primaryAccountHolder?.jointTenantsInCommonInterestPercent}%`}
                  label={`Primary Account Holder (${record.primaryAccountHolder?.firstName} ${record.primaryAccountHolder?.lastName})`}
                />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled>
                <FunctionField
                  render={(record?: Account) =>
                    `${record?.secondaryAccountHolder?.jointTenantsInCommonInterestPercent}%`
                  }
                  label={`Joint Account Holder (${record.secondaryAccountHolder?.firstName} ${record.secondaryAccountHolder?.lastName})`}
                />
              </Labeled>
            </Col>
          </Row>
        </div>
      )}
      <div className='showContent'>
        <Row>
          <Col sm={12}>
            <h2 className='title'>Trusted Contact</h2>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.trustedContact.firstName' label='First Name' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.trustedContact.lastName' label='Last Name' />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled>
              <EmailField source='primaryAccountHolder.trustedContact.email' label='Email' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled>
              <FunctionField
                render={(record?: Account) => {
                  return <PhonesField value={record?.primaryAccountHolder?.trustedContact?.phones ?? []} />;
                }}
                label='Phones'
              />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.trustedContact.address1' label='Street Address (Line 1)' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.trustedContact.address2' label='Street Address (Line 2)' />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.trustedContact.city' label='City' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.trustedContact.state.label' label='State' />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.trustedContact.country.label' label='Country' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.trustedContact.postalCode' label='Postal Code' />
            </Labeled>
          </Col>
        </Row>
      </div>
    </>
  );
};
