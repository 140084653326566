export const countryCodes = [
  {
    name: 'United States',
    dialCode: '+1',
    code: 'USA',
    isCodeAvailable: true,
    phoneMask: '(999) 999-9999',
  },
  // A
  {
    name: 'Afghanistan',
    dialCode: '+93',
    code: 'AFG',
    isCodeAvailable: true,
    phoneMask: '99-999-9999',
  },
  {
    name: 'Albania',
    dialCode: '+355',
    code: 'ALB',
    isCodeAvailable: true,
    phoneMask: '(99) 99-99-999',
  },
  {
    name: 'Algeria',
    dialCode: '+213',
    code: 'DZA',
    isCodeAvailable: true,
    phoneMask: '(9) 99-99-99-99',
  },
  {
    name: 'Andorra',
    dialCode: '+376',
    code: 'AND',
    isCodeAvailable: true,
    phoneMask: '999-999',
  },
  {
    name: 'Angola',
    dialCode: '+244',
    code: 'AGO',
    isCodeAvailable: true,
    phoneMask: '(999) 999-999',
  },
  {
    name: 'Anguilla',
    dialCode: '+1-264',
    code: 'AIA',
    isCodeAvailable: true,
    phoneMask: '999-9999',
  },
  {
    name: 'Antigua and Barbuda',
    dialCode: '+1-268',
    code: 'ATG',
    isCodeAvailable: true,
    phoneMask: '999-9999',
  },
  {
    name: 'Argentina',
    dialCode: '+54',
    code: 'ARG',
    isCodeAvailable: true,
    phoneMask: '(999) 999-9999',
  },
  {
    name: 'Armenia',
    dialCode: '+374',
    code: 'ARM',
    isCodeAvailable: true,
    phoneMask: '99-999-999',
  },
  {
    name: 'Aruba',
    dialCode: '+297',
    code: 'ABW',
    isCodeAvailable: true,
    phoneMask: '999-9999',
  },
];
