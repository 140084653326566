import { Grid } from '@mui/material';
import { FunctionField, SimpleShowLayout } from 'react-admin';
import { Col, Row } from 'react-grid-system';
import { OfferOrderPaymentTypeDto } from 'src/dtos';
import { OfferOrderPaymentsAggregate } from 'src/models';
import { getCurrencyFormatter } from 'src/utils';

interface OfferOrderPaymentAggregateProps {
  title: string;
  paymentType: OfferOrderPaymentTypeDto | 'NA';
  value?: OfferOrderPaymentsAggregate;
}

export const OfferOrderPaymentAggregateField = ({ title, paymentType, value }: OfferOrderPaymentAggregateProps) => {
  return (
    <Grid item xs={12} sm={12} md={12}>
      <SimpleShowLayout>
        <FunctionField
          label={title}
          record={value}
          render={(record?: OfferOrderPaymentsAggregate) => {
            const cardPayment = record?.paymentSummary?.find(item => item.paymentType === paymentType);

            return (
              <Row>
                <Col xs={3} sm={3} md={3}>
                  Total Payments: {cardPayment?.total ?? 0}
                </Col>
                <Col xs={3} sm={3} md={3}>
                  Total Amount: {getCurrencyFormatter().format(cardPayment?.totalAmount ?? 0)}
                </Col>
                <Col xs={3} sm={3} md={3}>
                  Total Investors: {cardPayment?.investors ?? 0}
                </Col>
              </Row>
            );
          }}
        />
      </SimpleShowLayout>
    </Grid>
  );
};
