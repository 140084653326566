import * as React from 'react';
import { useState } from 'react';

import _, { isNull } from 'lodash';
import { FormTab, Loading, SaveButton, TabbedForm, Toolbar, useRecordContext } from 'react-admin';
import { type FieldValues } from 'react-hook-form';
import { validateOfferExternalDetails, validateOfferOrderStatusUpdateEmailFields } from 'src/validators';

import { OfferCategoryNameDto } from '../../dtos/offers.dtos';
import AssetsCreate from './components/Assets/AssetsCreate';
import AssetsEdit from './components/Assets/AssetsEdit';
import DescriptionCreateEdit from './components/Description/DescriptionCreateEdit';
import DisclaimerCreateEdit from './components/Disclaimer/DisclaimerCreateEdit';
import DocumentsCreate from './components/Documents/DocumentsCreate';
import DocumentsEdit from './components/Documents/DocumentsEdit';
import { OfferEmailsCreateOrEdit } from './components/Emails/OfferEmailsCreateOrEdit';
import { ExternalOfferCreateOrEdit } from './components/External/ExternalOfferCreateOrEdit';
import InfoCreateEdit from './components/Info/InfoCreateEdit';
import { PaymentsCreateEdit } from './components/Payments/PaymentsCreateEdit';
import PriceCreateEdit from './components/Price&Limits/PriceCreateEdit';

interface TabbedCreateEditFormProps {
  isEditMode?: boolean;
}

const OfferEditToolbar = (props: any) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const validateOfferCreation = (values: any): FieldValues => {
  let errors: FieldValues = {};

  const errorsPriceLimits = validateOfferPriceAndLimitsData(values);
  const errorsPriceLimitsMinMax = validatePriceLimitsMinMax(values);
  const errorsPriceLimitsDependency = validatePriceLimitsDependency(values);
  const errorsInfo = validateInfoData(values);
  const emailFieldListErrors = validateOfferOrderStatusUpdateEmailFields(values);
  const externalDetailsErros = validateOfferExternalDetails(values);

  errors = {
    ...errorsPriceLimits,
    ...errorsPriceLimitsMinMax,
    ...errorsPriceLimitsDependency,
    ...errorsInfo,
    ...emailFieldListErrors,
    ...externalDetailsErros,
  };

  return errors;
};

const validatePriceLimitsMinMax = (values: FieldValues): FieldValues => {
  const errors: FieldValues = {};

  if (values.minTotalRaiseCurrencyAmt > 999999999 || values.minTotalRaiseCurrencyAmt < 0)
    errors.minTotalRaiseCurrencyAmt = 'Minimum raise amount is required to be between 0 and 999,999,999';
  if (values.maxTotalRaiseCurrencyAmt > 9999999900 || values.maxTotalRaiseCurrencyAmt < 0)
    errors.maxTotalRaiseCurrencyAmt = 'Maximum raise amount is required to be between 0 and 9,999,999,900';
  if (values.minimumInvestIncCurrencyAmt > 999999999 || values.minimumInvestIncCurrencyAmt < 0)
    errors.minimumInvestIncCurrencyAmt = 'Minimum Investment Increment is required to be between 0 and 999,999,999';
  if (values.minimumInvestCurrencyAmt > 999999999 || values.minimumInvestCurrencyAmt < 0)
    errors.minimumInvestCurrencyAmt = 'Minimum Investment Currency is required to be between 0 and 999,999,999';
  if (
    !isNaN(values.maximumInvestCurrencyAmt) &&
    (values.maximumInvestCurrencyAmt > 999999999 || values.maximumInvestCurrencyAmt < 0)
  )
    errors.maximumInvestCurrencyAmt = 'Maximum Investment Currency is required to be between 0 and 999,999,999';
  if (values.minimumInvestShareQty > 999999999 || values.minimumInvestShareQty < 0)
    errors.minimumInvestShareQty = 'Minimum Invest Share Qty is required to be between 0 and 999,999,999';
  if (values.maximumInvestShareQty > 999999999 || values.maximumInvestShareQty < 0)
    errors.maximumInvestShareQty = 'Maximum Invest Share Qty is required to be between 0 and 999,999,999';
  if (values.minimumInvestShareIncrQty > 999999999 || values.minimumInvestShareIncrQty < 0)
    errors.minimumInvestShareIncrQty =
      'Minimum Invest Share Increment Qty is required to be between 0 and 999,999,999 ';
  if (values.pricePerShare > 999999999 || values.pricePerShare < 0)
    errors.pricePerShare = 'Price Per Share is required to be between 0 and 999,999,999';

  return errors;
};

const validatePriceLimitsDependency = (values: FieldValues): FieldValues => {
  const errors: FieldValues = {};

  if (
    !isNaN(values.maxTotalRaiseCurrencyAmt) &&
    values.maxTotalRaiseCurrencyAmt !== null &&
    values.maxTotalRaiseCurrencyAmt !== '' &&
    values.minTotalRaiseCurrencyAmt > values.maxTotalRaiseCurrencyAmt
  ) {
    errors.minTotalRaiseCurrencyAmt = 'Minimum value should be less than the maximum value';
    errors.maxTotalRaiseCurrencyAmt = 'Maximum value should be greater than the minimum value';
  }

  if (
    !isNaN(values.maximumInvestCurrencyAmt) &&
    values.maximumInvestCurrencyAmt !== null &&
    values.maximumInvestCurrencyAmt !== '' &&
    values.minimumInvestCurrencyAmt > values.maximumInvestCurrencyAmt
  ) {
    errors.minimumInvestCurrencyAmt = 'Minimum value should be less than the maximum value';
    errors.maximumInvestCurrencyAmt = 'Maximum value should be greater than the minimum value';
  }

  if (
    !isNaN(values.maximumInvestShareQty) &&
    values.maximumInvestShareQty !== null &&
    values.maximumInvestShareQty !== '' &&
    values.minimumInvestShareQty > values.maximumInvestShareQty
  ) {
    errors.minimumInvestShareQty = 'Minimum value should be less than the maximum value';
    errors.maximumInvestShareQty = 'Maximum value should be greater than the minimum value';
  }

  if (
    values._categoryName === OfferCategoryNameDto.Conditional &&
    !isNaN(values.minSharePrice) &&
    !isNaN(values.maxSharePrice) &&
    values.minSharePrice !== null &&
    values.maxSharePrice !== null &&
    values.minSharePrice >= values.maxSharePrice
  ) {
    errors.minSharePrice = 'Minimum share price must be less than the maximum share price';
  }

  return errors;
};

const validateOfferPriceAndLimitsData = (values: FieldValues): FieldValues => {
  const errors: FieldValues = {};

  if (isNaN(values.minTotalRaiseCurrencyAmt) || isNull(values.minTotalRaiseCurrencyAmt))
    errors.minTotalRaiseCurrencyAmt = 'Minimum raise amount is required';
  if (isNaN(values.maxTotalRaiseCurrencyAmt) || isNull(values.maxTotalRaiseCurrencyAmt))
    errors.maxTotalRaiseCurrencyAmt = 'Maximum raise amount is required';
  if (isNaN(values.minimumInvestIncCurrencyAmt) || isNull(values.minimumInvestIncCurrencyAmt))
    errors.minimumInvestIncCurrencyAmt = 'Minimum Investment Increment is required';
  if (isNaN(values.minimumInvestCurrencyAmt) || isNull(values.minimumInvestCurrencyAmt))
    errors.minimumInvestCurrencyAmt = 'Minimum Investment Currency is required';
  if (isNaN(values.minimumInvestShareQty) || isNull(values.minimumInvestShareQty))
    errors.minimumInvestShareQty = 'Minimum Invest Share Qty is required';
  if (isNaN(values.maximumInvestShareQty) || isNull(values.maximumInvestShareQty))
    errors.maximumInvestShareQty = 'Maximum Invest Share Qty is required';
  if (isNaN(values.minimumInvestShareIncrQty) || isNull(values.minimumInvestShareIncrQty))
    errors.minimumInvestShareIncrQty = 'Minimum Invest Share Increment Qty is required';
  if (isNaN(values.pricePerShare) || isNull(values.pricePerShare)) errors.pricePerShare = 'Price Per Share is required';

  if (
    values._categoryName === OfferCategoryNameDto.Conditional &&
    (isNaN(values.minSharePrice) || isNull(values.minSharePrice))
  ) {
    errors.minSharePrice = 'Mininum share price is required';
  }

  if (
    values._categoryName === OfferCategoryNameDto.Conditional &&
    (values.minSharePrice < 0.01 || values.minSharePrice > 999999999)
  ) {
    errors.minSharePrice = 'Minimum share price is required to be between 0.01 and 999,999,999';
  }

  if (
    values._categoryName === OfferCategoryNameDto.Conditional &&
    (isNaN(values.maxSharePrice) || isNull(values.maxSharePrice))
  ) {
    errors.maxSharePrice = 'Maximum share price is required';
  }

  if (
    values._categoryName === OfferCategoryNameDto.Conditional &&
    (values.maxSharePrice <= 0.01 || values.maxSharePrice > 999999999)
  ) {
    errors.maxSharePrice = 'Maximum share price is required to be between 0.01 and 999,999,999';
  }

  return errors;
};

const validateInfoData = (values: FieldValues): FieldValues => {
  const errors: FieldValues = {};

  if (values?.shortUrlRoute && values.shortUrlRoute !== '' && !/^[a-z0-9_]*$/.test(values.shortUrlRoute))
    errors.shortUrlRoute = 'Can only contain lowercase letters, numbers and underscores';
  if (!values.name || _.isEmpty(values.name)) errors.name = 'Offer Name is required';
  if (!values.startDate) errors.startDate = 'Offer Start Date is required';
  if (!values.sector || _.isEmpty(values.sector)) errors.sector = 'Offer Sector is required';
  if (!values.yield || _.isEmpty(values.yield)) errors.yield = 'Offer Yield is required';
  if (!values.yieldDescription || _.isEmpty(values.yieldDescription))
    errors.yieldDescription = 'Offer Yield Description is required';
  if (values.sortOrder === null || values.sortOrder === '' || isNaN(values.sortOrder))
    errors.sortOrder = 'Offer Sort Order is required';
  if (!values.statusId || _.isEmpty(values.statusId)) errors.statusId = 'Offer Status is required';
  if (!values.offerTypeId || _.isEmpty(values.offerTypeId)) errors.offerTypeId = 'Offer Type is required';
  if (!values.industryId || _.isEmpty(values.industryId)) errors.industryId = 'Offer Industry is required';
  if (!values.categoryId || _.isEmpty(values.categoryId)) errors.categoryId = 'Offer Category is required';
  if (!values.securityTypeId || _.isEmpty(values.securityTypeId))
    errors.securityTypeId = 'Offer Security Type is required';
  if (!values.exitDescription || _.isEmpty(values.exitDescription))
    errors.exitDescription = 'Offer Exit Description is required';
  if (!values.description || _.isEmpty(values.description)) errors.description = 'Offer Description is required';
  if (!values.disclaimer || _.isEmpty(values.disclaimer)) errors.disclaimer = 'Offer Disclaimer is required';

  if (values._categoryName === 'Coming Soon' && values.investmentRanges?.length < 1)
    errors.investmentRanges = 'Investment Range should have at least one range when offer is in Coming Soon Category';
  if (values._categoryName === 'Coming Soon' && !values.anticipatedStartDate)
    errors.anticipatedStartDate = 'Anticipated Start Date is required';

  errors._documents_temp = [];
  if (values._documents_temp && values._documents_temp.length > 0)
    values._documents_temp?.map((doc: any, index: number) => {
      errors._documents_temp[index] = {};
      if (doc.type === '') errors._documents_temp[index] = { type: 'Document Type is required' };
    });

  errors._assets_temp = [];
  if (values._assets_temp && values._assets_temp.length > 0)
    values._assets_temp?.map((asset: any, index: number) => {
      errors._assets_temp[index] = {};
      if (asset.isVideo && asset.url === '') errors._assets_temp[index] = { url: 'Video URL is required' };
      if (!asset.previewImageUrl || asset.previewImageUrl === '')
        errors._assets_temp[index] = { previewImageUrl: 'Image asset is required' };
    });

  return errors;
};

export const TabbedCreateEditForm = ({ isEditMode = false }: TabbedCreateEditFormProps) => {
  const record = useRecordContext();

  const [categoryName, setCategoryName] = useState<string>();

  const setCategorySelected = (categoryName: string) => {
    setCategoryName(categoryName);
  };

  if (!record && isEditMode) return <Loading />;

  // NOTE: Use sanitizeEmptyValues prop for TabbedForm to remove empty values
  return (
    <TabbedForm
      toolbar={<OfferEditToolbar />}
      validate={(values: FieldValues) => validateOfferCreation({ ...values, _categoryName: categoryName })}
      mode='onBlur'
      reValidateMode='onChange'
      sx={{
        '.RaRichTextInput-editorContent, .ra-input-logpreviewImageUrloUrl': {
          height: 'auto',
          minHeight: '200px',
          maxHeight: '500px',
          '& .ProseMirror': { height: '100%' },
        },
      }}>
      <FormTab label='Info'>
        <InfoCreateEdit isEditMode={isEditMode} onCategoryChange={setCategorySelected} record={record} />
      </FormTab>
      <FormTab label='External'>
        <ExternalOfferCreateOrEdit isEditMode={isEditMode} />
      </FormTab>
      <FormTab label='Assets'>
        {!isEditMode && <AssetsCreate />}
        {isEditMode && <AssetsEdit />}
      </FormTab>
      <FormTab label='Price & Limits'>
        <PriceCreateEdit isEditMode={isEditMode} categoryName={categoryName} />
      </FormTab>
      <FormTab label='Payments'>
        <PaymentsCreateEdit isEditMode={isEditMode} />
      </FormTab>
      <FormTab label='Description'>
        <DescriptionCreateEdit isEditMode={isEditMode} />
      </FormTab>
      <FormTab label='Disclaimer'>
        <DisclaimerCreateEdit />
      </FormTab>
      <FormTab label='Documents'>
        {!isEditMode && <DocumentsCreate />}
        {isEditMode && <DocumentsEdit />}
      </FormTab>
      <FormTab label='Emails'>
        <OfferEmailsCreateOrEdit isEditMode={isEditMode} />
      </FormTab>
    </TabbedForm>
  );
};

export default TabbedCreateEditForm;
