import { useState } from 'react';

import { DateField, EmailField, Labeled, TextField, useRecordContext } from 'react-admin';
import { Row, Col } from 'react-grid-system';
import { FileViewerModal } from 'src/lib';
import { Account } from 'src/models';

import { PhoneField } from '../../Fields';
import { AccountAgreement } from './AccountAgreement';
import { AccountDetails } from './AccountDetails';

export const AccountInformationShow = () => {
  const record = useRecordContext<Account>();
  const { id } = record;

  const [viewDocument, setViewDocument] = useState<{ url: string; type: 'pdf' } | null>(null);

  const onView = (document: { url: string; type: 'pdf' }) => {
    setViewDocument(document);
  };

  const onFileViewerModalClose = () => {
    setViewDocument(null);
  };

  return (
    <>
      <AccountDetails />
      <div className='showContent'>
        <Row>
          <Col sm={12}>
            <h2 className='title'>{record.type.isJoint ? 'Primary Account Holder' : 'Personal Information'}</h2>
          </Col>
        </Row>
        <Row gutterWidth={10}>
          <Col md={2}>
            <Labeled>
              <TextField source='primaryAccountHolder.firstName' label='First Name' />
            </Labeled>
          </Col>
          <Col md={1}>
            <Labeled>
              <TextField source='primaryAccountHolder.middleName' label='Middle Name' />
            </Labeled>
          </Col>
          <Col md={2}>
            <Labeled>
              <TextField source='primaryAccountHolder.lastName' label='Last name' />
            </Labeled>
          </Col>
          <Col md={1}>
            <Labeled>
              <TextField source='primaryAccountHolder.suffix' label='Suffix' />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled>
              <EmailField source='primaryAccountHolder.email' label='Email' />
            </Labeled>
          </Col>

          <Col md={6}>
            <Labeled>
              <PhoneField source='primaryAccountHolder.phoneNumber' label='Phone' />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.maskedSsid' label='Ssid' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled>
              <DateField
                source='primaryAccountHolder.dateOfBirth'
                label='Date of Birth'
                options={{ timeZone: 'UTC' }}
              />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.maritalStatus.label' label='Marital Status' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.numberOfDependents' label='Number of Dependents' />
            </Labeled>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.birthCountry.label' label='Birth Country' />
            </Labeled>
          </Col>
          <Col md={6}>
            <Labeled>
              <TextField source='primaryAccountHolder.citizenshipCountry.label' label='Citizenship Country' />
            </Labeled>
          </Col>
        </Row>
      </div>
      {record.type.isJoint && (
        <div className='showContent'>
          <Row>
            <Col sm={12}>
              <h2 className='title'>Joint Account Holder</h2>
            </Col>
          </Row>
          <Row gutterWidth={10}>
            <Col md={2}>
              <Labeled>
                <TextField source='secondaryAccountHolder.firstName' label='First Name' />
              </Labeled>
            </Col>
            <Col md={1}>
              <Labeled>
                <TextField source='secondaryAccountHolder.middleName' label='Middle Name' />
              </Labeled>
            </Col>
            <Col md={2}>
              <Labeled>
                <TextField source='secondaryAccountHolder.lastName' label='Last name' />
              </Labeled>
            </Col>
            <Col md={1}>
              <Labeled>
                <TextField source='secondaryAccountHolder.suffix' label='Suffix' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled>
                <EmailField source='secondaryAccountHolder.email' label='Email' />
              </Labeled>
            </Col>

            <Col md={6}>
              <Labeled>
                <PhoneField source='secondaryAccountHolder.phoneNumber' label='Phone' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled>
                <TextField source='secondaryAccountHolder.maskedSsid' label='Ssid' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled>
                <DateField
                  source='secondaryAccountHolder.dateOfBirth'
                  label='Date of Birth'
                  options={{ timeZone: 'UTC' }}
                />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled>
                <TextField source='secondaryAccountHolder.maritalStatus.label' label='Marital Status' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled>
                <TextField source='secondaryAccountHolder.numberOfDependents' label='Number of Dependents' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled>
                <TextField source='secondaryAccountHolder.birthCountry.label' label='Birth Country' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled>
                <TextField source='secondaryAccountHolder.citizenshipCountry.label' label='Citizenship Country' />
              </Labeled>
            </Col>
          </Row>
        </div>
      )}
      {record.accountId && (
        <>
          <Col md={12} className='hoverItem'>
            <Row>
              <AccountAgreement id={id} onView={onView} />
            </Row>
          </Col>
          {viewDocument && record.accountId && <FileViewerModal onClose={onFileViewerModalClose} file={viewDocument} />}
        </>
      )}
    </>
  );
};
