import { OfferOrderUrlConstant } from 'src/constants';
import {
  CreateOfferOrderDto,
  OfferOrderDto,
  OfferOrderDetailsDto,
  UpdateOfferOrderDto,
  DeleteOfferOrderDto,
  CreateOfferOrderCardPaymentIntentDto,
  CreateOfferOrderCardPaymentIntentResponseDto,
  UpdateOfferOrderCardPaymentIntentDto,
  UpdateOfferOrderCardPaymentIntentResponseDto,
  OfferOrderCardPaymentDetailsDto,
  CreateOfferOrderCardPaymentRefundDto,
  GetOfferOrderCardPaymentListDto,
  GetOfferOrderCardPaymentListResponseDto,
  GetOfferOrderCardPaymentDetailsDto,
  GetOfferOrderListDto,
  GetOfferOrderDetailsDto,
  UpdateOfferOrderListStatusDto,
  GetOfferOrderListResponseDto,
  CreateOfferOrderFileExportDto,
} from 'src/dtos';
import { FileExportDto } from 'src/dtos/fileExport.dto';
import { ConfigService } from 'src/services/ConfigService';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class OfferOrdersApi {
  private _configService: ConfigService;

  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async create({ params, body }: CreateOfferOrderDto): Promise<OfferOrderDetailsDto> {
    const url = replacePlaceholders(OfferOrderUrlConstant.CREATE, params);

    return httpRequest<OfferOrderDetailsDto>({
      method: 'POST',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async list({ queryParams }: GetOfferOrderListDto): Promise<GetOfferOrderListResponseDto> {
    return httpRequest<GetOfferOrderListResponseDto>({
      method: 'GET',
      url: OfferOrderUrlConstant.LIST,
      authToken: this._configService.authToken,
      queryParams,
    });
  }

  async retrieve({ params }: GetOfferOrderDetailsDto): Promise<OfferOrderDetailsDto> {
    const url = replacePlaceholders(OfferOrderUrlConstant.RETRIEVE, params);

    return httpRequest<OfferOrderDetailsDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async updateListStatus({ body }: UpdateOfferOrderListStatusDto): Promise<OfferOrderDto> {
    return httpRequest<OfferOrderDetailsDto>({
      method: 'PUT',
      url: OfferOrderUrlConstant.UPDATE_LIST_STATUS,
      body,
      authToken: this._configService.authToken,
    });
  }

  async update({ params, body }: UpdateOfferOrderDto): Promise<OfferOrderDetailsDto> {
    const url = replacePlaceholders(OfferOrderUrlConstant.UPDATE, params);

    return httpRequest<OfferOrderDetailsDto>({
      method: 'PATCH',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async delete({ params }: DeleteOfferOrderDto): Promise<void> {
    const url = replacePlaceholders(OfferOrderUrlConstant.DELETE, params);

    return httpRequest<void>({
      method: 'DELETE',
      url,
      authToken: this._configService.authToken,
    });
  }

  async createCardPaymentIntent({
    body,
  }: CreateOfferOrderCardPaymentIntentDto): Promise<CreateOfferOrderCardPaymentIntentResponseDto> {
    return httpRequest<CreateOfferOrderCardPaymentIntentResponseDto>({
      method: 'POST',
      url: OfferOrderUrlConstant.CREATE_CARD_PAYMENT_INTENT,
      body,
      authToken: this._configService.authToken,
    });
  }

  async updateCardPaymentIntent({
    params,
    body,
  }: UpdateOfferOrderCardPaymentIntentDto): Promise<UpdateOfferOrderCardPaymentIntentResponseDto> {
    const url = replacePlaceholders(OfferOrderUrlConstant.UPDATE_CARD_PAYMENT_INTENT, params);

    return httpRequest<UpdateOfferOrderCardPaymentIntentResponseDto>({
      method: 'PUT',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async createRefund({ body, params }: CreateOfferOrderCardPaymentRefundDto): Promise<OfferOrderDetailsDto> {
    const url = replacePlaceholders(OfferOrderUrlConstant.CREATE_CARD_PAYMENT_REFUND, params);

    return httpRequest<OfferOrderDetailsDto>({
      method: 'POST',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async listCardPayments({
    queryParams,
  }: GetOfferOrderCardPaymentListDto): Promise<GetOfferOrderCardPaymentListResponseDto> {
    return httpRequest<GetOfferOrderCardPaymentListResponseDto>({
      method: 'GET',
      url: OfferOrderUrlConstant.LIST_CARD_PAYMENTS,
      queryParams,
      authToken: this._configService.authToken,
    });
  }

  async retrieveCardPayment({ params }: GetOfferOrderCardPaymentDetailsDto): Promise<OfferOrderCardPaymentDetailsDto> {
    const url = replacePlaceholders(OfferOrderUrlConstant.RETRIEVE_CARD_PAYMENT, params);

    return httpRequest<OfferOrderCardPaymentDetailsDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async createFileExport({ body }: CreateOfferOrderFileExportDto): Promise<FileExportDto> {
    return httpRequest<FileExportDto>({
      method: 'POST',
      url: OfferOrderUrlConstant.CREATE_FILE_EXPORT,
      body,
      authToken: this._configService.authToken,
    });
  }
}
